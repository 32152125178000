import React, { Component } from "react";
import wiserContext from "../providers/wiserContext";
import api from "../api/AxiosAPI";
import Auth from "../api/Auth";
import { history } from "./history";
import { User, defaultUser } from "../data-models/user";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Menu, Button } from "semantic-ui-react";
import { Guia, defaultGuia } from "../data-models/guia";
import LoadingOverlay from "react-loading-overlay-ts";
import { timeStamp } from "console";
import logoAzul from "../assets/logo_azul.png";
import ninoWiser from "../assets/ninos_vector_001.png";
import ninaWiser from "../assets/ninos_vector_002.png";
import { isBrowser } from "react-device-detect";

class PortalUsuario extends Component {
  static contextType = wiserContext;
  state = {
    currentUser: defaultUser,
    loading: false,
    guiasActuales: [] as Guia[],
    guiasPasadas: [] as Guia[],
    guias: [defaultGuia],
    // entrenamientos: [],
  };

  componentDidMount() {
    this.getUserData();
  }

  filterGuias = (guias: Guia[]) => {
    var today = new Date();
    today.setHours(0, 0, 0, 0);

    let todayTimestamp = today.getTime();

    const filterBeforeToday: Guia[] = guias.filter(
      (e) => new Date(e.fechaProgramada).getTime() < todayTimestamp
    );

    const filterToday: Guia[] = guias.filter(
      (e) => new Date(e.fechaProgramada).getTime() >= todayTimestamp
    );

    let todayDay = today.getDate();
    let todayMonth = today.getMonth();

    const todayGuias: Guia[] = [];

    for (let e in filterToday) {
      let guia = filterToday[e];
      let guiaDay = new Date(guia.fechaProgramada).getDate();
      let guiaMonth = new Date(guia.fechaProgramada).getMonth();
      if (guiaDay === todayDay && guiaMonth === todayMonth) {
        todayGuias.push(guia);
      }
    }

    this.setState({ guiasActuales: todayGuias });
    this.setState({ guiasPasadas: filterBeforeToday });

    console.log("guias actuales");
    console.log(todayGuias);

    console.log("guias pasadas");
    console.log(filterBeforeToday);
  };

  getUserData() {
    console.log("get user data");
    const { currentUserEmail } = this.context;
    this.setState({ loading: true });
    api({
      method: "get",

      url: "usuarios/GetUser?email=" + currentUserEmail,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        if (response.data[0]) {
          this.context.setCurrentUser(response.data);
          this.setState({ currentUser: response.data[0] });
        }
        let grupo = response.data[0].grupo;

        if (grupo === "pedagogo") {
          history.push("/pedagogo");
        }
        // this.evaluateGrupo(grupo);

        this.getUserGuias(currentUserEmail);
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  }

  getUserGuias = (selectedUsuarioEmail: string) => {
    console.log("getting user guias");
    let fetchGuias: Guia[] = [];
    this.setState({ loading: true });

    api({
      method: "get",
      url: "guias/FilterGuiasPendientes?usuario=" + selectedUsuarioEmail,
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        this.setState({ loading: false });

        console.log("guias");
        console.log(response.data);

        for (var i in response.data) {
          let guia: Guia = response.data[i];
          fetchGuias.push(guia);
        }

        this.setState({ guias: fetchGuias });
        this.filterGuias(fetchGuias);
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  evaluateGrupo = (grupo: string) => {
    switch (grupo) {
      case "pedagogo":
        history.push("/pedagogo");
        break;
      default:
        break;
    }
  };

  selectGuia = (guia: Guia) => {
    console.log(guia);
    this.context.setselectedGuia(guia);
    history.push("/gimnasio");
  };

  timeConverter = (timestamp: number) => {
    var current_date = new Date(timestamp);

    var formatted_date =
      current_date.getDate() +
      "/" +
      current_date.toLocaleString("default", { month: "long" }) +
      "/" +
      current_date.getFullYear();

    return formatted_date;
  };

  logout = () => {
    history.push("/");
    Auth.logout();
  };

  generateWiserKids = () => {
    let body;

    if (isBrowser) {
      body = (
        <div style={{ position: "absolute", width: "100%" }}>
          <div style={{ float: "right" }}>
            <img width={200} src={ninoWiser} alt="nino" />
          </div>
          <div style={{ float: "right", position: "relative", top: 50 }}>
            <img width={200} src={ninaWiser} alt="nino" />
          </div>
        </div>
      );
    }

    return body;
  };

  generateBody = () => {
    const { currentUser, guiasActuales, guiasPasadas, guias } = this.state;
    let body;

    const { container, text, logoContainer } = styles;

    if (guias) {
      if (guias.length > 0) {
        body = (
          <div style={container}>
            <h2 style={text}>Hola {currentUser.nombre}</h2>
            <div style={logoContainer}>
              <img width={200} src={logoAzul} alt="Logo" />
            </div>
            <h2 style={text}>Asignaciones Actuales</h2>
            <Grid style={{ marginTop: 20 }} fluid>
              {guiasActuales.map((guia: Guia, index) => (
                <Row key={guia.id}>
                  <Col xs={6} md={1}>
                    <div style={{ marginTop: 10, marginLeft: 20 }}>
                      <h2 style={text}> {index + 1}</h2>
                    </div>
                  </Col>
                  <Col xs={6} md={4}>
                    <div style={{ marginTop: 10, marginLeft: 20 }}>
                      <Button
                        style={{ backgroundColor: "#a1dd4b", color: "#017894" }}
                        onClick={() => this.selectGuia(guia)}
                      >
                        {guia.titulo}
                      </Button>
                    </div>
                  </Col>
                  <Col xs={6} md={2}>
                    <div style={{ padding: 10 }}>
                      <h4 style={text}>
                        {this.timeConverter(
                          new Date(guia.fechaProgramada).getTime()
                        )}
                      </h4>
                    </div>
                  </Col>
                </Row>
              ))}
            </Grid>
            <h2 style={text}>Asignaciones Pendientes</h2>
            <Grid style={{ marginTop: 20 }} fluid>
              {guiasPasadas.map((guia: Guia, index) => (
                <Row key={guia.id + "-" + index}>
                  <Col xs={6} md={1}>
                    <div style={{ marginTop: 10, marginLeft: 20 }}>
                      <h2 style={text}> {index + 1}</h2>
                    </div>
                  </Col>
                  <Col xs={6} md={4}>
                    <div style={{ marginTop: 10, marginLeft: 20 }}>
                      <Button
                        style={{ backgroundColor: "#fc714e", color: "white" }}
                        onClick={() => this.selectGuia(guia)}
                      >
                        {guia.titulo}
                      </Button>
                    </div>
                  </Col>
                  <Col xs={6} md={2}>
                    <div style={{ padding: 10 }}>
                      <h4 style={text}>
                        {this.timeConverter(
                          new Date(guia.fechaProgramada).getTime()
                        )}
                      </h4>
                    </div>
                  </Col>
                </Row>
              ))}
            </Grid>
            <Button
              style={{
                marginTop: 20,
                backgroundColor: "#fc714e",
                color: "white",
              }}
              onClick={() => this.logout()}
            >
              Salir
            </Button>
            <br />
            <Button
              style={{
                marginTop: 20,
                backgroundColor: "#fc714e",
                color: "white",
              }}
              onClick={() => {
                localStorage.clear();
                alert("Cookies eliminados");
                console.log("Cookies eliminados");
              }}
            >
              Borrar cookies
            </Button>
          </div>
        );
      } else {
        body = (
          <div style={container}>
            <h2 style={text}>Hola {currentUser.nombre}</h2>
            <h2 style={text}>No hay asignaciones pendientes</h2>
            <Button
              style={{ marginTop: 20, backgroundColor: "#fc714e" }}
              onClick={() => this.logout()}
            >
              Salir
            </Button>
          </div>
        );
      }
    } else {
      body = (
        <div>
          <Button
            style={{ marginTop: 20, backgroundColor: "#fc714e" }}
            onClick={() => this.logout()}
          >
            Salir
          </Button>
        </div>
      );
    }

    return body;
  };

  render() {
    const { loading } = this.state;
    return (
      <LoadingOverlay active={loading} spinner text="Cargando...">
        {this.generateBody()}
      </LoadingOverlay>
    );
  }
}

const styles = {
  container: {
    backgroundColor: "#017894",
    padding: 20,
    height: "100vh",
  },
  text: {
    color: "white",
  },
  formContainer: {
    marginTop: 20,
  },
  logoContainer: {
    position: "absolute",
    right: 5,
    top: 5,
  } as React.CSSProperties,
};

export default PortalUsuario;

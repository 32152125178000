import React, { Component } from 'react';
import { paises } from '../constants/paises';
import { User, defaultUser } from '../data-models/user';
import api from '../api/AxiosAPI';
import Auth from '../api/Auth';
import Select from 'react-select';
import wiserContext from '../providers/wiserContext';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Grid, Row, Col } from 'react-flexbox-grid';

interface IProps {
}

interface IState {
    pedagogos: User[],
    selectedPedagogo: string,
    filteredUsers: User[],
    selectedPais: string,
    loading: boolean,
}

class ReportesMasivos extends Component<IProps, IState> {
    static contextType = wiserContext;
    constructor(props: IProps) {

        super(props);
        this.state = {
            pedagogos: [],
            filteredUsers: [],
            selectedPais: "SV",
            loading: false,
            selectedPedagogo: ""
        };
    }

    componentDidMount() {
        this.getPedagogos();
    }

    getPedagogos = () => {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: '/usuarios/FilterUsersByGrupo?grupo=pedagogo',
            headers: { Authorization: Auth.getToken() }
        }).then((response) => {
            console.log('response');
            console.log(response);
            this.setState({ loading: false });
            if (response.data[0]) {
                this.setState({ pedagogos: response.data })
            }
        }).catch((error) => {
            this.setState({ loading: false });
            console.error('ERROR');
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    };

    filterUsersByPedagogoPais = () => {
        const { selectedPedagogo, selectedPais } = this.state;
        this.setState({ loading: true });
        api({
            method: 'get',
            url: '/usuarios/FilterUsersByPedagogoPais?pedagogo_asignado=' + selectedPedagogo + '&pais=' + selectedPais,
            headers: { Authorization: Auth.getToken() }
        }).then((response) => {
            console.log('response');
            console.log(response);
            this.setState({ loading: false });
            if (response.data[0]) {
                this.setState({ filteredUsers: response.data })
            }
        }).catch((error) => {
            this.setState({ loading: false });
            console.error('ERROR');
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    }

    render() {
        const {
            loading,
            selectedPedagogo,
            pedagogos,
            filteredUsers
        } = this.state;
        return (
            <LoadingOverlay
                active={loading}
                spinner
                text='Cargando...'
            >
                <div><h2>Reportes Masivos</h2></div>

                <div style={{ marginTop: 20 }}>
                    <form className="ui form">
                        <div className="four fields">
                            <div className="field">
                                <label>Pedagogo asignado</label>
                                <select
                                    onChange={e => this.setState({ selectedPedagogo: e.target.value })}
                                    className="ui dropdown" >
                                    {pedagogos.map(pedagogo => (
                                        <option key={pedagogo.id} value={pedagogo.nombre + " " + pedagogo.apellido}>{pedagogo.nombre + " " + pedagogo.apellido}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="field">
                                <label>Pais</label>
                                <select
                                    onChange={e => this.setState({ selectedPais: e.target.value })}
                                    className="ui dropdown" >
                                    {paises.map(pais => (
                                        <option key={pais.code} value={pais.code}>{pais.text}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <Grid style={{ marginTop: 20, marginBottom: 20 }} fluid>
                            {filteredUsers.map(user => (
                                <Row key={user.id}>
                                    <Col xs={6} md={2}>
                                        {user.nombre}
                                    </Col>
                                    <Col xs={6} md={2}>
                                        {user.apellido}
                                    </Col>
                                    <Col xs={6} md={3}>
                                        {user.email}
                                    </Col>
                                    <Col xs={6} md={2}>
                                        {user.grupo}
                                    </Col>
                                </Row>
                            ))}
                        </Grid>
                        <input
                            className="ui button"
                            type="button"
                            onClick={() => this.filterUsersByPedagogoPais()}
                            value="Guardar"
                        />
                    </form>
                </div>




            </LoadingOverlay>
        );
    }
}

export default ReportesMasivos;
import { Ejercicio } from '../data-models/ejercicio';

export const generateProcedimientoGridMultiplicacion = (ej: Ejercicio) => {
    // for grid:
    // x = solution.length
    // y = (segundo termino).length
    let secondTerm = ej.columnas[1];
    let secondTermLength = secondTerm.toString().split("").length;
    let solucion = ej.solucion;
    let solucionLength = solucion.toString().split("").length;
    let solucionGrid: string[][] = [];
    if (secondTermLength > 1) {
        for (let i = 0; i < secondTermLength; i++) {
            let row: string[] = [];
            for (let a = 0; a < solucionLength; a++) {
                row.push('');
            }
            solucionGrid.push(row);
        }
    }
    return solucionGrid;
}

export const generateTargetProcedimientoGridMultiplicacion = (ej: Ejercicio) => {
    let multiplicando = ej.columnas[0];
    let multiplicador = ej.columnas[1];
    let multiplicadorSplit = multiplicador.toString().split("");
    let multiplicadorLength = multiplicadorSplit.length;
    let multiplicandoLength = multiplicando.toString().split("").length;
    let targetProcedimientoGrid: string[][] = [];

    for (let i = multiplicadorLength; i > 0; i--) {
        let row: string[] = [];
        let difference = multiplicandoLength - multiplicadorLength;
        let multiplicadorDigit = parseInt(multiplicadorSplit[i - 1]);
        let rowResult = multiplicadorDigit * multiplicando;
        let rowResultSplit = rowResult.toString().split("");
        let rowResultSplitLength = rowResultSplit.length;

        for (let a = 0; a < rowResultSplitLength; a++) {
            row.push(rowResultSplit[a]);
        }
        targetProcedimientoGrid.push(row);
    }

    return targetProcedimientoGrid;

}
import React, { Component } from "react";
import api from "../api/AxiosAPI";
import Auth from "../api/Auth";
import { Menu, Button } from "semantic-ui-react";
import { history } from "./history";
import { User } from "../data-models/user";
import { Nivel } from "../data-models/nivel";
import { Grid, Row, Col } from "react-flexbox-grid";
import AgregarItem from "./AgregarItem";
import wiserContext from "../providers/wiserContext";
import LoadingOverlay from "react-loading-overlay-ts";

interface IProps {}

interface IState {
  status_message: string;
  selectedScreen: string;
  showAddItem: boolean;
  niveles: Nivel[];
  newNivelDescripcion: string;
  loading: boolean;
}

class Niveles extends Component<IProps, IState> {
  static contextType = wiserContext;
  constructor(props: IProps) {
    super(props);
    this.state = {
      status_message: "",
      selectedScreen: "",
      showAddItem: false,
      niveles: [],
      newNivelDescripcion: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.getAllNiveles();
  }

  getAllNiveles() {
    console.log("getting niveles");
    let fetchNiveles: Nivel[];
    fetchNiveles = [];
    this.setState({ loading: true });
    api({
      method: "get",
      url: "niveles/GetAllNiveles",
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log("response data");
        console.log(response.data);
        for (var i in response.data) {
          let nivel = response.data[i];
          fetchNiveles.push({
            id: nivel.id,
            nombre: nivel.nombre,
            descripcion: nivel.descripcion,
          });
        }
        this.setState({
          niveles: fetchNiveles,
          status_message: "Data about all niveles received successfully.",
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  }

  addNivel = (nivel: Nivel) => {
    const { nombre, descripcion } = nivel;
    console.log("nivel por agregar");
    console.log(nivel);
    this.setState({ loading: true });
    api({
      method: "post",
      url: "niveles/SaveNivel?nombre=" + nombre,
      data: { descripcion: descripcion },
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log("nivel saved successfully");
        this.setState({
          status_message: "Data about the user saved successfully.",
        });
        this.getAllNiveles();
        this.setState({ showAddItem: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  addNewNivel = () => {
    const { newNivelDescripcion } = this.state;
    let newNivel: Nivel;
    newNivel = {
      nombre: this.generateNombre(newNivelDescripcion),
      descripcion: newNivelDescripcion,
    };
    this.addNivel(newNivel);
  };

  generateNombre = (descripcion: string) => {
    let nombre = descripcion.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    nombre = nombre.toLowerCase().replace(/ /g, "_");
    return nombre;
  };

  deleteNivel(nivel: Nivel) {
    const { id, nombre, descripcion } = nivel;
    this.setState({ loading: true });
    api({
      method: "delete",
      url: "/niveles/DeleteNivel?nombre=" + nombre,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        this.setState({
          status_message: "Data about the nivel deleted successfully.",
        });
        this.getAllNiveles();
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  }

  generateAgregarItem = () => {
    const { showAddItem } = this.state;
    let a;
    if (showAddItem) {
      a = (
        <Grid style={{ marginTop: 20 }} fluid>
          <Row>
            <Col xs={6} md={3}>
              <form className="ui form">
                <div className="one fields">
                  <div className="field">
                    <input
                      onChange={(e) =>
                        this.setState({ newNivelDescripcion: e.target.value })
                      }
                      type="text"
                      placeholder="descripcion"
                    ></input>
                  </div>
                </div>
              </form>
            </Col>
            <Col xs={6} md={3}>
              <Button
                className="ui primary button"
                onClick={() => this.addNewNivel()}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Grid>
      );
    }

    return a;
  };

  render() {
    const { niveles, loading } = this.state;
    const agregarItem = this.generateAgregarItem();
    return (
      <LoadingOverlay active={loading} spinner text="Cargando...">
        <div>
          <Button
            onClick={() =>
              this.setState({ showAddItem: !this.state.showAddItem })
            }
            className="ui primary button"
          >
            Agregar nivel
          </Button>
          {agregarItem}
          <Grid style={{ marginTop: 20 }} fluid>
            {niveles.map((nivel) => (
              <Row key={nivel.id}>
                <Col xs={6} md={7}>
                  {nivel.descripcion}
                </Col>
                <Col xs={6} md={3}>
                  <Button
                    className="ui red"
                    onClick={() => this.deleteNivel(nivel)}
                  >
                    Eliminar
                  </Button>
                </Col>
              </Row>
            ))}
          </Grid>
        </div>
      </LoadingOverlay>
    );
  }
}

export default Niveles;

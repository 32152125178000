import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import api from '../api/AxiosAPI';
import Auth from '../api/Auth';

const SemaforoParams = () => {

    const [loading, setLoading] = useState(false);

    const [mensajeAmarillo, setMensajeAmarillo] = useState("");
    const [mensajeRojo, setMensajeRojo] = useState("");
    const [mensajeVerde, setMensajeVerde] = useState("");

    const [desviacionEstandarAlto, setDesviacionEstandarAlto] = useState("");
    const [desviacionEstandarBajo, setDesviacionEstandarBajo] = useState("");

    const [porcentajeErroresAlto, setPorcentajeErroresAlto] = useState("");
    const [porcentajeErroresBajo, setPorcentajeErroresBajo] = useState("");

    const [tiempoResolucionAlto, setTiempoResolucionAlto] = useState("");
    const [tiempoResolucionBajo, setTiempoResolucionBajo] = useState("");


    useEffect(() => {
        getAllSemaforoParams();
    }, []);

    const getAllSemaforoParams = () => {
        console.log('getting semaforo params');
        setLoading(true);
        api({
            method: 'get',
            url: '/semaforo/GetSemaforoParams',
            headers: { Authorization: Auth.getToken() }
        }).then((response) => {
            setLoading(false);
            console.log('response data');
            console.log(response.data);
            let params = response.data[0];

            const {
                desviacion_estandar_alto,
                desviacion_estandar_bajo,
                porcentaje_errores_alto,
                porcentaje_errores_bajo,
                tiempo_resolucion_alto,
                tiempo_resolucion_bajo,
                verde_mensaje,
                rojo_mensaje,
                amarillo_mensaje,
            } = params;

            setMensajeAmarillo(amarillo_mensaje);
            setMensajeRojo(rojo_mensaje);
            setMensajeVerde(verde_mensaje);
            setDesviacionEstandarAlto(desviacion_estandar_alto);
            setDesviacionEstandarBajo(desviacion_estandar_bajo);
            setPorcentajeErroresAlto(porcentaje_errores_alto);
            setPorcentajeErroresBajo(porcentaje_errores_bajo);
            setTiempoResolucionAlto(tiempo_resolucion_alto);
            setTiempoResolucionBajo(tiempo_resolucion_bajo);

        }).catch((error) => {
            setLoading(false);
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    };

    const saveSemaforoParams = (params: any) => {
        setLoading(true);
        api({
            method: 'put',
            url: '/semaforo/SaveSemaforoParams?nombre=params',
            data: params,
            headers: { Authorization: Auth.getToken() }
        }).then((response) => {
            setLoading(false);
            console.log('semaforo params saved successfully')
            alert("Cambios guardados exitosamente");
            console.log(response);
            getAllSemaforoParams();
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    }

    const submit = () => {
        let params = {
            "verde_mensaje": mensajeVerde,
            "rojo_mensaje": mensajeRojo,
            "amarillo_mensaje": mensajeAmarillo,
            "tiempo_resolucion_alto": tiempoResolucionAlto,
            "tiempo_resolucion_bajo": tiempoResolucionBajo,
            "porcentaje_errores_alto": porcentajeErroresAlto,
            "porcentaje_errores_bajo": porcentajeErroresBajo,
            "desviacion_estandar_alto": desviacionEstandarAlto,
            "desviacion_estandar_bajo": desviacionEstandarBajo,
        }

        saveSemaforoParams(params);
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Cargando...'
        >
            <div>
                <form className="ui form">
                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                        <h2>Tiempo de resolucion de un ejercicio en segundos</h2>
                    </div>
                    <div className="four fields">
                        <div className="field">
                            <label>Alto</label>
                            <input
                                onChange={e => setTiempoResolucionAlto(e.target.value)}
                                type="text"
                                value={tiempoResolucionAlto}
                                placeholder="Tiempo">
                            </input>
                        </div>
                        <div className="field">
                            <label>Bajo</label>
                            <input
                                onChange={e => setTiempoResolucionBajo(e.target.value)}
                                type="text"
                                value={tiempoResolucionBajo}
                                placeholder="Tiempo">
                            </input>
                        </div>
                    </div>
                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                        <h2>Porcentaje de Errores</h2>
                    </div>
                    <div className="four fields">
                        <div className="field">
                            <label>Alto</label>
                            <input
                                onChange={e => setPorcentajeErroresAlto(e.target.value)}
                                type="text"
                                value={porcentajeErroresAlto}
                                placeholder="Tiempo">
                            </input>
                        </div>
                        <div className="field">
                            <label>Bajo</label>
                            <input
                                onChange={e => setPorcentajeErroresBajo(e.target.value)}
                                type="text"
                                value={porcentajeErroresBajo}
                                placeholder="Tiempo">
                            </input>
                        </div>
                    </div>
                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                        <h2>Desviacion estandar del tiempo de resolucion</h2>
                    </div>
                    <div className="four fields">
                        <div className="field">
                            <label>Alto</label>
                            <input
                                onChange={e => setDesviacionEstandarAlto(e.target.value)}
                                type="text"
                                value={desviacionEstandarAlto}
                                placeholder="Tiempo">
                            </input>
                        </div>
                        <div className="field">
                            <label>Bajo</label>
                            <input
                                onChange={e => setDesviacionEstandarBajo(e.target.value)}
                                type="text"
                                value={desviacionEstandarBajo}
                                placeholder="Tiempo">
                            </input>
                        </div>
                    </div>
                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                        <h2>Mensajes para el usuario</h2>
                    </div>
                    <div className="one field">
                        <div className="field">
                            <label>Verde</label>
                            <input
                                onChange={e => setMensajeVerde(e.target.value)}
                                type="text"
                                value={mensajeVerde}
                                placeholder="Tiempo">
                            </input>
                        </div>
                    </div>
                    <div className="one field">
                        <div className="field">
                            <label>Amarillo</label>
                            <input
                                onChange={e => setMensajeAmarillo(e.target.value)}
                                type="text"
                                value={mensajeAmarillo}
                                placeholder="Tiempo">
                            </input>
                        </div>
                    </div>
                    <div className="one field">
                        <div className="field">
                            <label>Rojo</label>
                            <input
                                onChange={e => setMensajeRojo(e.target.value)}
                                type="text"
                                value={mensajeRojo}
                                placeholder="Tiempo">
                            </input>
                        </div>
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <input
                            className="ui primary button"
                            type="button"
                            onClick={() => submit()}
                            value="Guardar"
                        />
                    </div>
                </form>
            </div>
        </LoadingOverlay>
    );
};

export default SemaforoParams;
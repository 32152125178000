import React, { Component } from "react";
import api from "../api/AxiosAPI";
import Auth from "../api/Auth";
import { history } from "./history";
import logoAzul from "../assets/logo_azul.png";
import wiserContext from "../providers/wiserContext";
import LoadingOverlay from "react-loading-overlay-ts";

class Home extends Component {
  static contextType = wiserContext;
  state = {
    loading: false,
  };
  componentDidMount() {
    this.getUserData();
  }

  evaluateGrupo = (grupo: string) => {
    switch (grupo) {
      case "pedagogo":
        history.push("/pedagogo");
        break;
      default:
        history.push("/usuario");
        break;
    }
  };

  getUserData() {
    console.log("get user data");
    const { currentUserEmail } = this.context;
    this.setState({ loading: true });
    console.log("email");
    console.log(currentUserEmail);
    api({
      method: "get",

      url: "usuarios/GetUser?email=" + currentUserEmail,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        console.log("response");
        console.log(response);
        this.setState({ loading: false });
        if (response.data.length > 0) {
          console.log(response.data[0]);
          this.context.setCurrentUser(response.data[0]);
          let grupo = response.data[0].grupo;
          this.evaluateGrupo(grupo);
        } else {
          alert("Usuario no encontrado");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  }

  render() {
    const { loading } = this.state;
    return (
      <LoadingOverlay active={loading} spinner text="Cargando...">
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#017894",
            height: "100vh",
          }}
        >
          <img width={300} src={logoAzul} alt="Logo" />
        </div>
      </LoadingOverlay>
    );
  }
}

export default Home;

import { Ejercicio } from '../data-models/ejercicio';

export const cleanProcedimientoGrid = (grid: string[][]) => {
    //remove all non-numbers
    let cleanGrid: string[][] = [];
    if (!grid) {
        return [];
    }
    for (let i = 0; i < grid.length; i++) {
        let row = grid[i];
        let newRow: string[] = [];
        for (let a = 0; a < row.length; a++) {
            let item = row[a];
            if (item !== '') {
                newRow.push(item);
            }
        }
        cleanGrid.push(newRow);
    }
    return cleanGrid;
}

export const compareGrids = (procedimientoGrid: string[][], targetGrid: string[][]) => {
    //if both grids have the same values, return true. Else, return false;
    let match = true;
    if (procedimientoGrid.length !== targetGrid.length) {
        return false;
    }

    for (let i = 0; i < procedimientoGrid.length; i++) {
        let currentProcedimientoGridRow = procedimientoGrid[i];
        let currentTargetGridRow = targetGrid[i];
        if (currentProcedimientoGridRow.length === 0) {
            match = false;
            break;
        }
        for (let a = 0; a < currentProcedimientoGridRow.length; a++) {
            let currentProcedimientoGridRowItem = currentProcedimientoGridRow[a];
            let currentTargetGridRowItem = currentTargetGridRow[a];
            if (currentProcedimientoGridRowItem !== currentTargetGridRowItem) {
                match = false;
                break;
            }
        }
    }
    return match;
}
import React, { Component } from 'react';
import api from '../api/AxiosAPI';
import Auth from '../api/Auth';
import { Menu, Button } from 'semantic-ui-react'
import { history } from './history';
import { User } from '../data-models/user';
import { Unidad } from '../data-models/unidad';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AgregarItem from './AgregarItem';
import LoadingOverlay from 'react-loading-overlay-ts';

interface IProps {
}

interface IState {
    status_message: string,
    selectedScreen: string,
    showAddItem: boolean,
    unidades: Unidad[],
    newUnidadDescripcion: string,
    loading: boolean
}

class Unidades extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            status_message: '',
            selectedScreen: '',
            showAddItem: false,
            unidades: [],
            newUnidadDescripcion: '',
            loading: false
        };
    }

    componentDidMount() {
        this.getAllUnidades();
    }

    getAllUnidades() {
        console.log('getting unidades');
        let fetchUnidades: Unidad[];
        fetchUnidades = [];
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'unidades/GetAllUnidades',
            headers: {
                Authorization: Auth.getToken(),
            }
        }).then((response) => {
            this.setState({ loading: false });
            console.log('response data');
            console.log(response.data);
            for (var i in response.data) {
                let unidad = response.data[i];
                fetchUnidades.push({
                    id: unidad.id,
                    nombre: unidad.nombre,
                    descripcion: unidad.descripcion,
                })
            }
            this.setState({
                unidades: fetchUnidades,
                status_message: 'Data about all unidades received successfully.'
            })
        }).catch((error) => {
            this.setState({ loading: false });
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    };

    addUnidad = (unidad: Unidad) => {
        const { nombre, descripcion } = unidad;
        console.log('unidad por agregar');
        console.log(unidad);
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'unidades/SaveUnidad?nombre=' + nombre,
            data: { descripcion: descripcion },
            headers: { Authorization: Auth.getToken() }
        }).then((response) => {
            console.log('unidad saved successfully')
            this.setState({ loading: false });
            this.setState({ status_message: 'Data about the user saved successfully.' });
            this.getAllUnidades();
            this.setState({ showAddItem: false });
        }).catch((error) => {
            this.setState({ loading: false });
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    }

    addNewUnidad = () => {
        const { newUnidadDescripcion } = this.state;
        let newUnidad: Unidad;
        newUnidad = {
            nombre: this.generateNombre(newUnidadDescripcion),
            descripcion: newUnidadDescripcion
        }
        this.addUnidad(newUnidad);
    }

    generateNombre = (descripcion: string) => {
        let nombre = descripcion.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        nombre = nombre.toLowerCase().replace(/ /g, "_");
        return nombre;
    }

    deleteUnidad(unidad: Unidad) {
        const { id, nombre, descripcion } = unidad;
        this.setState({ loading: true });
        api({
            method: 'delete',
            url: '/unidades/DeleteUnidad?nombre=' + nombre,
            headers: { Authorization: Auth.getToken() }
        }).then((response) => {
            this.setState({ loading: false });
            this.setState({
                status_message: 'Data about the unidad deleted successfully.'
            });
            this.getAllUnidades();
        }).catch((error) => {
            this.setState({ loading: false });
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    }

    generateAgregarItem = () => {
        const { showAddItem } = this.state;
        let a;
        if (showAddItem) {
            a = (
                <Grid style={{ marginTop: 20 }} fluid>
                    <Row>
                        <Col xs={6} md={3}>
                            <form className="ui form">
                                <div className="one fields">
                                    <div className="field">
                                        <input
                                            onChange={e => this.setState({ newUnidadDescripcion: e.target.value })}
                                            type="text"
                                            placeholder="descripcion">
                                        </input>
                                    </div>
                                </div>
                            </form>
                        </Col>
                        <Col xs={6} md={3}>
                            <Button className="ui primary button" onClick={() => this.addNewUnidad()}>Guardar</Button>
                        </Col>
                    </Row>
                </Grid>
            );
        }

        return a;
    }

    render() {
        const { unidades, loading } = this.state;
        const agregarItem = this.generateAgregarItem();
        return (
            <LoadingOverlay
                active={loading}
                spinner
                text='Cargando...'
            >
                <div>
                    <Button onClick={() => this.setState({ showAddItem: !this.state.showAddItem })} className="ui primary button">
                        Agregar Unidad
                    </Button>
                    {agregarItem}
                    <Grid style={{ marginTop: 20 }} fluid>
                        {unidades.map(unidad => (
                            <Row key={unidad.id}>
                                <Col xs={6} md={7}>
                                    {unidad.descripcion}
                                </Col>
                                {/* <Col xs={6} md={2}>
                                <Button onClick={() => console.log('detalles')}>Modificar</Button>
                            </Col> */}
                                <Col xs={6} md={3}>
                                    <Button className="ui red" onClick={() => this.deleteUnidad(unidad)}>Eliminar</Button>
                                </Col>
                            </Row>
                        ))}
                    </Grid>
                </div>
            </LoadingOverlay>

        );
    }
}

export default Unidades;
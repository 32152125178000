import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { User } from "../../data-models/user";
import { GuiaGrupoPorCodigo, Guia } from "../../data-models/guia";
import { Asignatura } from "../../data-models/asignatura";
import { Nivel } from "../../data-models/nivel";
import { Subnivel } from "../../data-models/subnivel";
import { Ejercicio } from "../../data-models/ejercicio";
import Celda from "./Celda";

interface IProps {
  selectedUsuario: User;
  guiasAgrupadas: GuiaGrupoPorCodigo[];
  indexGuia: number;
  asignaturas: Asignatura[];
  niveles: Nivel[];
  subniveles: Subnivel[];
  updateGuias: any;
  deleteGuia: any;
  dia1: Date;
  dia2: Date;
  dia3: Date;
  dia4: Date;
  dia5: Date;
  dia6: Date;
  dia7: Date;
  dia8: Date;
  dia9: Date;
  dia10: Date;
}

interface IState {
  dia1: Date;
  dia2: Date;
  dia3: Date;
  dia4: Date;
  dia5: Date;
  dia6: Date;
  dia7: Date;
  dia8: Date;
  dia9: Date;
  dia10: Date;
  codigoAsignatura: string;
  codigoNivel: string;
  codigoSubnivel: string;
  codigoOperador: string;
  codigoGuia: string;
}

class Scheduler extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {
      dia1,
      dia2,
      dia3,
      dia4,
      dia5,
      dia6,
      dia7,
      dia8,
      dia9,
      dia10,
      asignaturas,
      niveles,
      subniveles,
      guiasAgrupadas,
      indexGuia,
    } = props;

    const guia = guiasAgrupadas[indexGuia];

    this.state = {
      dia1: dia1,
      dia2: dia2,
      dia3: dia3,
      dia4: dia4,
      dia5: dia5,
      dia6: dia6,
      dia7: dia7,
      dia8: dia8,
      dia9: dia9,
      dia10: dia10,
      codigoAsignatura: "M",
      codigoNivel: "A",
      codigoSubnivel: "B",
      codigoOperador: "S",
      codigoGuia: guia.codigo,
    };
  }

  componentDidMount() {
    //set default data
    // this.setDefaultValues();
  }

  getDateMonthFromTimestamp = (dateTimestamp: number) => {
    var newDate = new Date(dateTimestamp);
    newDate.setHours(0, 0, 0, 0);
    let date = newDate.getDate().toString();
    let month = newDate.getMonth().toString();
    let resultado = date + month;
    return resultado;
  };

  randomInteger = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  generarNumerosSinResiduo = (
    primerTerminoDesde: number,
    primerTerminoHasta: number,
    segundoTerminoDesde: number,
    segundoTerminoHasta: number
  ) => {
    let term1;
    let term2;
    let residuoCero = false;
    let result: number[] = [];
    while (!residuoCero) {
      term1 = this.randomInteger(primerTerminoDesde, primerTerminoHasta);
      term2 = this.randomInteger(segundoTerminoDesde, segundoTerminoHasta);
      if (term1 % term2 === 0) {
        residuoCero = true;
      }
    }
    result.push(term1);
    result.push(term2);

    return result;
  };

  selectAsignatura = (value: string) => {
    // this.setState({ asignatura: value });

    let code = value[0];

    this.setState({ codigoAsignatura: code });

    let type = "asignatura";

    this.generateCodigo(code, type);

    const { guiasAgrupadas, indexGuia, updateGuias } = this.props;

    let updatedGuias = guiasAgrupadas;

    let currentGuia = guiasAgrupadas[indexGuia];

    currentGuia.asignatura = value;

    updatedGuias[indexGuia] = currentGuia;

    updateGuias(updatedGuias);
  };

  selectNivel = (value: string) => {
    // this.setState({ nivel: value });

    let code = value[0];

    this.setState({ codigoNivel: code });

    let type = "nivel";

    this.generateCodigo(code, type);

    const { guiasAgrupadas, indexGuia, updateGuias } = this.props;

    let updatedGuias = guiasAgrupadas;

    let currentGuia = guiasAgrupadas[indexGuia];

    currentGuia.nivel = value;

    updatedGuias[indexGuia] = currentGuia;

    updateGuias(updatedGuias);
  };

  selectOperador = (value: string) => {
    // this.setState({ operador: value });

    let code = value[0]; //primera letra del operador

    this.setState({ codigoOperador: code });

    let type = "operador";

    this.generateCodigo(code, type);

    const { guiasAgrupadas, indexGuia, updateGuias } = this.props;

    let updatedGuias = guiasAgrupadas;

    let currentGuia = guiasAgrupadas[indexGuia];

    currentGuia.operador = value;

    updatedGuias[indexGuia] = currentGuia;

    updateGuias(updatedGuias);
  };

  selectSubnivel = (value: string) => {
    // this.setState({ subnivel: value });

    let code = value[0];

    this.setState({ codigoSubnivel: code });

    let type = "subnivel";

    this.generateCodigo(code, type);

    const { guiasAgrupadas, indexGuia, updateGuias } = this.props;

    let updatedGuias = guiasAgrupadas;

    let currentGuia = guiasAgrupadas[indexGuia];

    currentGuia.subnivel = value;

    updatedGuias[indexGuia] = currentGuia;

    updateGuias(updatedGuias);
  };

  getCellColor = (indexGuia: number, currentDay: Date) => {
    const { guiasAgrupadas } = this.props;

    let buttonColor;
    let white = "#A9A9A9";
    let red = "red";

    let currentGrupo = guiasAgrupadas[indexGuia];

    var query: Guia[] = currentGrupo.guias.filter(
      (el: Guia) =>
        this.getDateMonthFromTimestamp(
          new Date(el.fechaProgramada).getTime()
        ) === this.getDateMonthFromTimestamp(currentDay.getTime())
    );

    if (query.length === 0) {
      buttonColor = white;
    } else {
      buttonColor = red;
    }

    return buttonColor;
  };

  generarGuia = (fechaProgramada: Date, codigo: string) => {
    const { guiasAgrupadas, indexGuia } = this.props;

    let nuevosEjercicios: Ejercicio[] = [];

    const grupo = guiasAgrupadas[indexGuia];

    const { selectedUsuario } = this.props;

    const {
      primerTerminoDesde,
      primerTerminoHasta,
      segundoTerminoDesde,
      segundoTerminoHasta,
      cantidadEjercicios,
      operador,
      nivel,
      subnivel,
      asignatura,
    } = grupo;

    //genera ejercicios para entrenamiento
    //keyword
    for (let i = 0; i < parseInt(cantidadEjercicios); i++) {
      let term1 = this.randomInteger(
        parseInt(primerTerminoDesde),
        parseInt(primerTerminoHasta)
      );
      let term2 = this.randomInteger(
        parseInt(segundoTerminoDesde),
        parseInt(segundoTerminoHasta)
      );
      let solucion: number;
      let operadorSigno: string;
      let residuo: number;

      switch (operador) {
        case "suma":
          solucion = term1 + term2;
          residuo = 0;
          operadorSigno = "+";
          break;
        case "resta":
          solucion = term1 - term2;
          residuo = 0;
          operadorSigno = "-";
          break;
        case "multiplicacion":
          if (subnivel == "basico") {
            let pth = parseInt(primerTerminoHasta);
            term1 = i + 1 - Math.floor(i / pth) * pth;
          }
          solucion = term1 * term2;
          residuo = 0;
          operadorSigno = "*";
          break;
        case "division":
          if (subnivel == "basico") {
            let terms = this.generarNumerosSinResiduo(
              parseInt(primerTerminoDesde),
              parseInt(primerTerminoHasta),
              parseInt(segundoTerminoDesde),
              parseInt(segundoTerminoHasta)
            );
            term1 = terms[0];
            term2 = terms[1];
          }
          solucion = Math.floor(term1 / term2);
          residuo = term1 % term2;
          operadorSigno = "/";
          break;
        default:
          solucion = term1 + term2;
          residuo = 0;
          operadorSigno = "+";
          break;
      }

      let ejercicio: Ejercicio = {
        id: i + 1,
        operador: operadorSigno,
        columnas: [term1, term2],
        solucion: solucion,
        residuo: residuo,
      };
      nuevosEjercicios.push(ejercicio);
    }

    let newGuia: Guia = {
      // id: (guiaLength + 1).toString(),
      id: "",
      usuario: selectedUsuario.email,
      fechaAsignada: new Date(),
      fechaProgramada: fechaProgramada,
      fechaRealizada: null,
      fechaAsignadaTimestamp: new Date().getTime(),
      fechaProgramadaTimestamp: fechaProgramada.getTime(),
      fechaRealizadaTimestamp: null,
      resuelta: false,
      codigo: codigo,
      titulo: this.generateTitulo(),
      operador: operador,
      asignatura: asignatura,
      nivel: nivel,
      subnivel: subnivel,
      primerTerminoDesde: primerTerminoDesde,
      primerTerminoHasta: primerTerminoHasta,
      segundoTerminoDesde: segundoTerminoDesde,
      segundoTerminoHasta: segundoTerminoHasta,
      cantidadEjercicios: cantidadEjercicios,
      ejercicios: nuevosEjercicios,
    };

    return newGuia;
  };

  addOrRemoveGuia = (indexGuia: number, currentDay: Date) => {
    const { guiasAgrupadas } = this.props;
    const { codigoGuia } = this.state;

    let guiasPorCodigo = guiasAgrupadas;
    let currentGrupo = guiasPorCodigo[indexGuia];

    const {
      primerTerminoDesde,
      primerTerminoHasta,
      segundoTerminoDesde,
      segundoTerminoHasta,
      cantidadEjercicios,
      operador,
      nivel,
      subnivel,
      asignatura,
      id,
    } = currentGrupo;

    //filtering entrenamientos by date
    var query: Guia[] = currentGrupo.guias.filter(
      (el) =>
        this.getDateMonthFromTimestamp(
          new Date(el.fechaProgramada).getTime()
        ) === this.getDateMonthFromTimestamp(currentDay.getTime())
    );

    if (query.length == 0) {
      //entrenamiento no encontrado
      //agregar entrenamiento

      console.log("entrenamiento no encontrado");

      let rangos =
        cantidadEjercicios +
        "(" +
        primerTerminoDesde +
        "," +
        primerTerminoHasta +
        ")" +
        "(" +
        segundoTerminoDesde +
        "," +
        segundoTerminoHasta +
        ")";

      // only add rango when in doesn't exist
      // in other words, if the parenthesis is missing
      let existingRango = codigoGuia.indexOf("(");
      let codigo;
      if (existingRango === -1) {
        codigo = codigoGuia + "-" + rangos;
      } else {
        codigo = codigoGuia;
      }

      let newEntrenamiento = this.generarGuia(currentDay, codigo);

      currentGrupo.id = id;
      currentGrupo.guias.push(newEntrenamiento);
      currentGrupo.primerTerminoDesde = primerTerminoDesde;
      currentGrupo.primerTerminoHasta = primerTerminoHasta;
      currentGrupo.segundoTerminoDesde = segundoTerminoDesde;
      currentGrupo.segundoTerminoHasta = segundoTerminoHasta;
      currentGrupo.cantidadEjercicios = cantidadEjercicios;
      currentGrupo.operador = operador;
      currentGrupo.subnivel = subnivel;
      currentGrupo.nivel = nivel;
      currentGrupo.asignatura = asignatura;
      currentGrupo.codigo = codigo;

      guiasPorCodigo[indexGuia] = currentGrupo;
    } else {
      //entrenamiento encontrado
      //remover entrenamiento

      console.log("entrenamiento encontrado");

      let queryByDateIndex = currentGrupo.guias.findIndex(
        (el) =>
          this.getDateMonthFromTimestamp(
            new Date(el.fechaProgramada).getTime()
          ) === this.getDateMonthFromTimestamp(currentDay.getTime())
      );

      let selectedGuia = currentGrupo.guias[queryByDateIndex];
      console.log("selected guia");
      console.log(selectedGuia);

      if (selectedGuia.id !== "") {
        this.props.deleteGuia(selectedGuia.id);
      }

      currentGrupo.guias.splice(queryByDateIndex, 1);

      // removing guia if empty
      if (currentGrupo.guias.length === 0) {
        guiasPorCodigo.splice(indexGuia, 1);
      } else {
        guiasPorCodigo[indexGuia] = currentGrupo;
      }
    }

    this.props.updateGuias(guiasPorCodigo);
  };

  generateTitulo = () => {
    const {
      codigoAsignatura,
      codigoNivel,
      codigoSubnivel,
      codigoOperador,
    } = this.state;

    const { guiasAgrupadas, indexGuia } = this.props;

    const grupo = guiasAgrupadas[indexGuia];

    const {
      primerTerminoDesde,
      primerTerminoHasta,
      segundoTerminoDesde,
      segundoTerminoHasta,
      cantidadEjercicios,
      operador,
      nivel,
      subnivel,
      asignatura,
    } = grupo;

    let rangos =
      cantidadEjercicios +
      "(" +
      primerTerminoDesde +
      "," +
      primerTerminoHasta +
      ")" +
      "(" +
      segundoTerminoDesde +
      "," +
      segundoTerminoHasta +
      ")";

    //keyword
    const titulo = (
      codigoAsignatura +
      codigoNivel +
      codigoOperador +
      codigoSubnivel +
      "-" +
      rangos
    ).toUpperCase();
    return titulo;
  };

  generateCodigo = (code: string, type: string) => {
    const {
      codigoAsignatura,
      codigoNivel,
      codigoSubnivel,
      codigoOperador,
    } = this.state;

    const { guiasAgrupadas, indexGuia } = this.props;

    let codigo;

    let currentGrupo = guiasAgrupadas[indexGuia];

    switch (type) {
      case "asignatura":
        codigo = code + codigoNivel + codigoSubnivel + codigoOperador;
        break;
      case "nivel":
        codigo = codigoAsignatura + code + codigoSubnivel + codigoOperador;
        break;
      case "subnivel":
        codigo = codigoAsignatura + codigoNivel + codigoOperador + code;
        break;
      case "operador":
        codigo = codigoAsignatura + codigoNivel + code + codigoSubnivel;
        break;
      default:
        codigo = "";
        break;
    }

    codigo = currentGrupo.id + "-" + codigo;

    this.setState({ codigoGuia: codigo.toUpperCase() });
  };

  setCantidadEjercicios = (value: string) => {
    const { guiasAgrupadas, indexGuia, updateGuias } = this.props;

    let updatedGuias = guiasAgrupadas;

    let currentGuia = guiasAgrupadas[indexGuia];

    currentGuia.cantidadEjercicios = value;

    updatedGuias[indexGuia] = currentGuia;

    updateGuias(updatedGuias);
  };

  setPrimerTerminoDesde = (value: string) => {
    const { guiasAgrupadas, indexGuia, updateGuias } = this.props;

    let updatedGuias = guiasAgrupadas;

    let currentGuia = guiasAgrupadas[indexGuia];

    currentGuia.primerTerminoDesde = value;

    updatedGuias[indexGuia] = currentGuia;

    updateGuias(updatedGuias);
  };

  setPrimerTerminoHasta = (value: string) => {
    // this.setState({ primerTerminoHasta: value });

    const { guiasAgrupadas, indexGuia, updateGuias } = this.props;

    let updatedGuias = guiasAgrupadas;

    let currentGuia = guiasAgrupadas[indexGuia];

    currentGuia.primerTerminoHasta = value;

    updatedGuias[indexGuia] = currentGuia;

    updateGuias(updatedGuias);
  };

  setSegundoTerminoDesde = (value: string) => {
    // this.setState({ segundoTerminoDesde: value });

    const { guiasAgrupadas, indexGuia, updateGuias } = this.props;

    let updatedGuias = guiasAgrupadas;

    let currentGuia = guiasAgrupadas[indexGuia];

    currentGuia.segundoTerminoDesde = value;

    updatedGuias[indexGuia] = currentGuia;

    updateGuias(updatedGuias);
  };

  setSegundoTerminoHasta = (value: string) => {
    // this.setState({ segundoTerminoHasta: value });

    const { guiasAgrupadas, indexGuia, updateGuias } = this.props;

    let updatedGuias = guiasAgrupadas;

    let currentGuia = guiasAgrupadas[indexGuia];

    currentGuia.segundoTerminoHasta = value;

    updatedGuias[indexGuia] = currentGuia;

    updateGuias(updatedGuias);
  };

  render() {
    const {
      dia1,
      dia2,
      dia3,
      dia4,
      dia5,
      dia6,
      dia7,
      dia8,
      dia9,
      dia10,
      asignaturas,
      niveles,
      subniveles,
      guiasAgrupadas,
      indexGuia,
    } = this.props;

    let currentGuia = guiasAgrupadas[indexGuia];

    const {
      primerTerminoDesde,
      primerTerminoHasta,
      segundoTerminoDesde,
      segundoTerminoHasta,
      cantidadEjercicios,
      operador,
      nivel,
      subnivel,
      asignatura,
    } = currentGuia;
    return (
      <div>
        <Grid style={{ marginLeft: 0, marginRight: 0 }}>
          <Row>
            <Col xs={1} md={1}>
              <select
                value={asignatura}
                onChange={(e) => this.selectAsignatura(e.target.value)}
                className="ui dropdown"
              >
                {asignaturas.map((asignatura) => (
                  <option key={asignatura.id} value={asignatura.nombre}>
                    {asignatura.descripcion.substring(0, 1)}
                  </option>
                ))}
              </select>
            </Col>
            <Col xs={1} md={1}>
              <select
                value={nivel}
                onChange={(e) => this.selectNivel(e.target.value)}
                className="ui dropdown"
              >
                {niveles.map((nivel) => (
                  <option key={nivel.id} value={nivel.nombre}>
                    {nivel.descripcion.substring(0, 1)}
                  </option>
                ))}
              </select>
            </Col>
            <Col xs={1} md={1}>
              <select
                value={operador}
                onChange={(e) => this.selectOperador(e.target.value)}
                className="ui dropdown"
              >
                <option key={"suma"} value={"suma"}>
                  S
                </option>
                <option key={"resta"} value={"resta"}>
                  R
                </option>
                <option key={"multiplicacion"} value={"multiplicacion"}>
                  M
                </option>
                <option key={"division"} value={"division"}>
                  D
                </option>
              </select>
            </Col>
            <Col xs={1} md={1}>
              <select
                value={subnivel}
                onChange={(e) => this.selectSubnivel(e.target.value)}
                className="ui dropdown"
              >
                {subniveles.map((subnivel) => (
                  <option key={subnivel.id} value={subnivel.nombre}>
                    {subnivel.descripcion.substring(0, 1)}
                  </option>
                ))}
              </select>
            </Col>
            <Col xs={1} md={1}>
              <input
                onChange={(e) => this.setCantidadEjercicios(e.target.value)}
                value={cantidadEjercicios}
                type="text"
                placeholder="Numero"
              ></input>
            </Col>
            <Col xs={1} md={1}>
              <input
                onChange={(e) => this.setPrimerTerminoDesde(e.target.value)}
                value={primerTerminoDesde}
                type="text"
                placeholder="Desde"
              ></input>
            </Col>
            <Col xs={1} md={1}>
              <input
                onChange={(e) => this.setPrimerTerminoHasta(e.target.value)}
                value={primerTerminoHasta}
                type="text"
                placeholder="Hasta"
              ></input>
            </Col>
            <Col xs={1} md={1}>
              <input
                onChange={(e) => this.setSegundoTerminoDesde(e.target.value)}
                value={segundoTerminoDesde}
                type="text"
                placeholder="Desde"
              ></input>
            </Col>
            <Col xs={1} md={1}>
              <input
                onChange={(e) => this.setSegundoTerminoHasta(e.target.value)}
                value={segundoTerminoHasta}
                type="text"
                placeholder="Hasta"
              ></input>
            </Col>
            <Col xs={1} md={1}>
              <Celda
                currentDay={dia1}
                indexGuia={indexGuia}
                addOrRemoveGuia={this.addOrRemoveGuia}
                getCellColor={this.getCellColor}
              />
            </Col>
            <Col xs={1} md={1}>
              <Celda
                currentDay={dia2}
                indexGuia={indexGuia}
                addOrRemoveGuia={this.addOrRemoveGuia}
                getCellColor={this.getCellColor}
              />
            </Col>
            <Col xs={1} md={1}>
              <Celda
                currentDay={dia3}
                indexGuia={indexGuia}
                addOrRemoveGuia={this.addOrRemoveGuia}
                getCellColor={this.getCellColor}
              />
            </Col>
            <Col xs={1} md={1}>
              <Celda
                currentDay={dia4}
                indexGuia={indexGuia}
                addOrRemoveGuia={this.addOrRemoveGuia}
                getCellColor={this.getCellColor}
              />
            </Col>
            <Col xs={1} md={1}>
              <Celda
                currentDay={dia5}
                indexGuia={indexGuia}
                addOrRemoveGuia={this.addOrRemoveGuia}
                getCellColor={this.getCellColor}
              />
            </Col>
            <Col xs={1} md={1}>
              <Celda
                currentDay={dia6}
                indexGuia={indexGuia}
                addOrRemoveGuia={this.addOrRemoveGuia}
                getCellColor={this.getCellColor}
              />
            </Col>
            <Col xs={1} md={1}>
              <Celda
                currentDay={dia7}
                indexGuia={indexGuia}
                addOrRemoveGuia={this.addOrRemoveGuia}
                getCellColor={this.getCellColor}
              />
            </Col>
            <Col xs={1} md={1}>
              <Celda
                currentDay={dia8}
                indexGuia={indexGuia}
                addOrRemoveGuia={this.addOrRemoveGuia}
                getCellColor={this.getCellColor}
              />
            </Col>
            <Col xs={1} md={1}>
              <Celda
                currentDay={dia9}
                indexGuia={indexGuia}
                addOrRemoveGuia={this.addOrRemoveGuia}
                getCellColor={this.getCellColor}
              />
            </Col>
            <Col xs={1} md={1}>
              <Celda
                currentDay={dia10}
                indexGuia={indexGuia}
                addOrRemoveGuia={this.addOrRemoveGuia}
                getCellColor={this.getCellColor}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Scheduler;

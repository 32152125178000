import React, { Component } from "react";
import { User, defaultUser } from "../data-models/user";
import { Ejercicio } from "../data-models/ejercicio";
import { Button } from "semantic-ui-react";
import api from "../api/AxiosAPI";
import Auth from "../api/Auth";
import Select from "react-select";
import wiserContext from "../providers/wiserContext";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { Grid, Row, Col } from "react-flexbox-grid";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface IProps {}

interface IState {
  selectedUsuario: User;
  chartData: any;
  data: any;
  desde: Date;
  hasta: Date;
  currentGuia: number;
  loading: boolean;
  usuarios: User[];
}

class InformesResultados extends Component<IProps, IState> {
  static contextType = wiserContext;
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedUsuario: defaultUser,
      chartData: [],
      data: [],
      desde: new Date(),
      hasta: new Date(),
      currentGuia: null,
      loading: false,
      usuarios: [defaultUser],
    };
  }
  componentDidMount() {
    this.getUsuarios();
  }

  getUsuarios = () => {
    this.setState({ loading: true });
    api({
      method: "get",
      url: "/usuarios/FilterUsersByGrupo?grupo=usuario_activo",
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        console.log("usuarios");
        console.log(response);
        this.setState({ loading: false });
        if (response.data[0]) {
          this.setState({ usuarios: response.data });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  filterResultadosByEmail = (email: string) => {
    // const { user } = props;
    // const { email } = user;
    this.setState({ loading: true });
    api({
      method: "get",
      url: "/resultados/FilterResultadosByEmail?email=" + email,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log("data");
        console.log(response.data);
        let data = response.data;

        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          let fecha = item.fecha;
          let shortDate = fecha.substring(0, 10);
          item.shortDate = shortDate;
        }

        this.setState({ chartData: data });
        this.setState({ data: data });
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response) {
          // console.log(error.response.data);
          // console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  selectUser = (e: any) => {
    const { usuarios } = this.state;
    console.log("e");
    console.log(e);
    if (e) {
      let id = e.value;
      let selectedUsuario = usuarios.find((element: any) => element.id == id);
      console.log(selectedUsuario);
      this.setState({
        selectedUsuario: selectedUsuario,
      });
      this.filterResultadosByEmail(selectedUsuario.email);
    }
  };

  generateUserOptions = () => {
    const { usuarios } = this.state;
    let results = [];
    for (let i in usuarios) {
      results.push({
        value: usuarios[i].id,
        label: usuarios[i].nombre + " " + usuarios[i].apellido,
      });
    }
    return results;
  };

  saveUserData = (user: User) => {
    this.setState({ loading: true });
    api({
      method: "put",
      url: "/usuarios/SaveUser",
      data: user,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log("user saved successfully");
        console.log(response);
        this.getUsuarios();
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  submit = () => {
    const { selectedUsuario } = this.state;
    console.log("selected usuario");
    console.log(selectedUsuario);
    this.saveUserData(selectedUsuario);
  };

  selectDesde = (event: any, data: any) => {
    console.log(data.value);
    this.setState({ desde: data.value });
  };

  selectHasta = (event: any, data: any) => {
    const { desde } = this.state;
    let hasta = data.value;
    this.setState({ hasta: data.value });
    this.filterChartByDate(desde, hasta);
  };

  filterChartByDate = (desde: any, hasta: any) => {
    const { data } = this.state;
    if (hasta) {
      let desdeTimestamp = desde.getTime();
      let hastaTimestamp = hasta.getTime();

      console.log("desdeTimestamp");
      console.log(desdeTimestamp);

      console.log("hastaTimestamp");
      console.log(hastaTimestamp);

      console.log("item timestamps");
      for (let d in data) {
        let item = data[d];
        console.log(item.timestamp);
      }

      let filteredData = data.filter((item: any) => {
        return (
          item.timestamp >= desde.getTime() && item.timestamp <= hasta.getTime()
        );
      });

      console.log("filtered data");
      console.log(filteredData);
      this.setState({ chartData: filteredData });
    }
  };

  selectcurrentGuia = (ejID: number) => {
    console.log(ejID);
    this.setState({ currentGuia: ejID });
  };

  ejerciciosConError = (ejercicios: Ejercicio[]) => {
    var ejerciciosConError = ejercicios.filter((el: any) => el.errores > 0);
    return ejerciciosConError.length;
  };

  generateGuiaDetalles = () => {
    console.log("generate ejercicio detalles");
    const { chartData, currentGuia } = this.state;
    const { container, child } = styles;
    let element;
    let guia;
    let ejercicios;
    if (!chartData) {
      return element;
    }
    // debugger;
    if (currentGuia) {
      // var query = ejercicios.filter((el: any) => el.id == currentGuia);
      // if (query.length > 0) {
      //     let ejercicio = query[0];
      //     console.log('ejercicio');
      //     console.log(ejercicio);
      // }
      guia = chartData[currentGuia];
      ejercicios = guia.ejercicios;
      console.log("ejercicios");
      console.log(ejercicios);

      console.log("guia");
      console.log(guia);

      // var ejerciciosConError = ejercicios.filter((el: any) => el.errores > 0);
      var ejerciciosConError = this.ejerciciosConError(ejercicios);
      // if (ejerciciosConError.length > 0) {
      //     let ejercicio = query[0];
      //     console.log('ejercicio');
      //     console.log(ejercicio);
      // }
      element = (
        <BarChart width={1000} height={250} data={ejercicios}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="tiempoResolucion" fill="#8884d8" />
          <Bar dataKey="errores" fill="#FF0000" />
        </BarChart>
      );
    }

    console.log("guia detalles");
    console.log(element);

    return element;
  };

  generateChart = () => {
    const { chartData } = this.state;

    const sortedResults = [...chartData].sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );

    let element;
    if (chartData.length > 0) {
      element = (
        <div>
          <div>
            <AreaChart
              width={1000}
              height={400}
              data={sortedResults}
              margin={{
                top: 20,
                right: 30,
                left: 0,
                bottom: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis angle={45} tickMargin={40} dataKey="shortDate" />
              <YAxis />
              <Tooltip />
              <Legend
                verticalAlign="top"
                margin={{ top: 10, bottom: 10 }}
                iconType="square"
              />
              <Area
                name="Tiempo Promedio (secs)"
                type="monotone"
                dataKey="tiempoPromedio"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity="0.5"
                label="tiempo promedio en segundos"
              />
              <Area
                name="Desviacion"
                type="monotone"
                dataKey="desviacion"
                stroke="#008000"
                fill="#82ca9d"
                fillOpacity="0.5"
              />
              <Area
                name="Tiempo Total (mins)"
                type="monotone"
                dataKey="tiempoTotal"
                stroke="#505050"
                fill="#505050"
                fillOpacity="0.5"
                label="tiempo total en minutos"
              />
              <Area
                name="Errores"
                type="monotone"
                dataKey="errores"
                stroke="#FF0000"
                fill="#FF0000"
                fillOpacity="0.5"
              />
            </AreaChart>
          </div>
          <div>
            <Grid style={{ marginTop: 20, marginBottom: 20 }} fluid>
              <Row>
                <Col xs={6} md={2}>
                  Titulo
                </Col>
                <Col xs={6} md={2}>
                  Fecha
                </Col>
                <Col xs={6} md={2}>
                  Hora Inicio
                </Col>
                <Col xs={6} md={2}>
                  Hora Fin
                </Col>
                <Col xs={6} md={1}>
                  Errores
                </Col>
                <Col xs={6} md={1}>
                  Ejercicios con error
                </Col>
                <Col xs={6} md={1}>
                  Cantidad de ejercicios
                </Col>
                <Col xs={6} md={1}>
                  Promedio (segundos)
                </Col>
                <Col xs={6} md={1}>
                  DS
                </Col>
                <Col xs={6} md={1}>
                  Tiempo Total (minutos)
                </Col>
              </Row>
              {chartData.map((item: any, index: number) => (
                <Row key={item.timestamp}>
                  <Col xs={6} md={2}>
                    {item.guiaTitulo}
                  </Col>
                  <Col
                    xs={6}
                    md={2}
                    onClick={() => {
                      this.selectcurrentGuia(index);
                    }}
                  >
                    {item.fecha.substring(0, 10)}
                  </Col>
                  <Col xs={6} md={2}>
                    {item.horaComienzo}
                  </Col>
                  <Col xs={6} md={2}>
                    {item.horaFin}
                  </Col>
                  <Col xs={6} md={1}>
                    {item.errores}
                  </Col>
                  <Col xs={6} md={1}>
                    {this.ejerciciosConError(item.ejercicios)}
                  </Col>
                  <Col xs={6} md={1}>
                    {item.ejercicios.length}
                  </Col>
                  <Col xs={6} md={1}>
                    {item.tiempoPromedio}
                  </Col>
                  <Col xs={6} md={1}>
                    {item.desviacion}
                  </Col>
                  <Col xs={6} md={1}>
                    {item.tiempoTotal}
                  </Col>
                </Row>
              ))}
            </Grid>
            <br></br>
            <br></br>
          </div>
        </div>
      );
    }
    return element;
  };

  render() {
    const { selectedUsuario, chartData, loading } = this.state;
    const { container, child } = styles;
    return (
      <LoadingOverlay active={loading} spinner text="Cargando...">
        <div>
          <form className="ui form">
            <div className="four fields">
              <div className="field">
                <label>Usuario</label>
                <Select
                  onChange={(e) => this.selectUser(e)}
                  className="basic-single"
                  classNamePrefix="select"
                  // defaultValue={{ value: usuarios[0].id, label: usuarios[0].nombre }}
                  isDisabled={false}
                  placeholder={"usuario"}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="color"
                  options={this.generateUserOptions()}
                />
              </div>
            </div>
          </form>
          <div style={container}>
            <div style={child}>Desde</div>
            <div style={child}>Hasta</div>
          </div>
          <div style={container}>
            <div style={child}>
              <SemanticDatepicker onChange={this.selectDesde} />
            </div>
            <div style={child}>
              <SemanticDatepicker onChange={this.selectHasta} />
            </div>
          </div>
          {this.generateChart()}
          {/* {this.generateGuiaDetalles()} */}
        </div>
      </LoadingOverlay>
    );
  }
}

// const styles = {
//     container: {
//         display: "flex",
//   flexDirection: "row",
//   justifContent: flex-end;
//     }
// };

const styles = {
  container: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center"
  },
  child: {
    flex: 1,
  },
};

export default InformesResultados;

import React, { Component } from 'react';
import { User } from '../../data-models/user';
import { Button } from 'semantic-ui-react'
import { Grid, Row, Col } from 'react-flexbox-grid';

function ListaUsuarios(props: { users: User[], switchScreen: any, selectUser: any, deleteUser: any }) {
    const { rowContainer } = styles;
    return (
        <div>
            <Button onClick={() => props.switchScreen('agregar')} className="ui primary button">
                Agregar usuario
            </Button>
            <Grid style={{ marginTop: 20 }} fluid>
                {props.users.map(user => (
                    <Row key={user.id}>
                        <Col xs={6} md={2}>
                            {user.nombre}
                        </Col>
                        <Col xs={6} md={2}>
                            {user.apellido}
                        </Col>
                        <Col xs={6} md={3}>
                            {user.email}
                        </Col>
                        <Col xs={6} md={2}>
                            {user.grupo}
                        </Col>
                        <Col xs={6} md={2}>
                            <Button onClick={() => props.selectUser(user)}>Detalles</Button>
                        </Col>
                        <Col xs={6} md={1}>
                            <Button className="ui red" onClick={() => props.deleteUser(user.email)}>Eliminar</Button>
                        </Col>
                    </Row>
                ))}
            </Grid>
        </div>

    );
}

const styles = {
    rowContainer: {
        flexDirection: 'row',
        padding: 5,
    },
}

export default ListaUsuarios;
import { Asignacion, defaultAsignacion } from "./asignacion";
import { Guia, defaultGuia } from "./guia";

type Nullable<T> = T | null;
export interface User {
  id?: string;
  nombre: string;
  apellido: string;
  email: string;
  fechaInscripcion?: Nullable<number>;
  fechaNacimiento?: Nullable<number>;
  gradoInicial?: string;
  grupo: string;
  nivel?: string;
  subnivel?: string;
  pais: string;
  pedagogoAsignado?: string;
  representante?: string;
  status: string;
  telefono: string;
  unidad?: string;
  meta?: string;
  guias?: Guia[];
  asignaciones?: Asignacion[];
}

export const defaultUser: User = {
  id: "",
  nombre: "",
  apellido: "",
  email: "",
  fechaInscripcion: 0,
  fechaNacimiento: 0,
  gradoInicial: "",
  grupo: "",
  nivel: "",
  subnivel: "",
  pais: "",
  pedagogoAsignado: "",
  representante: "",
  status: "",
  telefono: "",
  unidad: "",
  meta: "",
  guias: [defaultGuia],
  asignaciones: [defaultAsignacion],
};

import React, { Component } from "react";
import { User, defaultUser } from "../data-models/user";
import { Button } from "semantic-ui-react";
import api from "../api/AxiosAPI";
import Auth from "../api/Auth";
import Select from "react-select";
import wiserContext from "../providers/wiserContext";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { Grid, Row, Col } from "react-flexbox-grid";
import Switch from "react-switch";
import html2canvas from "@nidi/html2canvas";
import logo from "../assets/logo_white.jpg";
import LoadingOverlay from "react-loading-overlay-ts";
import { Guia } from "../data-models/guia";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface IProps {}

interface IState {
  selectedUsuario: User;
  chartData: any;
  data: any;
  desde: Date;
  hasta: Date;
  guias: Guia[];
  asistencia: number;
  currentGuia: number;
  guiasMPV: string; // matematicas para la vida
  guiasLenguaje: string;
  colorSemaforo: string;
  metaAnterior: string;
  metaActual: string;
  comentariosPadres: string;
  recomendacionesPedagogo: string;
  compromisos: string;
  nombreRepresentate: string;
  reporteMensual: boolean;
  loading: boolean;
  usuarios: User[];
  semaforoColor: string;
  semaforoMensaje: string;
  mensajeAmarillo: string;
  mensajeRojo: string;
  mensajeVerde: string;
  desviacionEstandarAlto: string;
  desviacionEstandarBajo: string;
  porcentajeErroresAlto: string;
  porcentajeErroresBajo: string;
  tiempoResolucionAlto: string;
  tiempoResolucionBajo: string;
}

class InformesSituacion extends Component<IProps, IState> {
  static contextType = wiserContext;
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedUsuario: defaultUser,
      chartData: [],
      data: [],
      desde: new Date(),
      hasta: new Date(),
      guias: [],
      asistencia: 0,
      currentGuia: null,
      guiasMPV: "",
      guiasLenguaje: "",
      colorSemaforo: "#149414",
      metaAnterior: "",
      metaActual: "",
      comentariosPadres: "",
      recomendacionesPedagogo: "",
      compromisos: "",
      nombreRepresentate: "",
      reporteMensual: true,
      loading: false,
      usuarios: [defaultUser],
      semaforoColor: "white",
      semaforoMensaje: "",
      mensajeAmarillo: "",
      mensajeRojo: "",
      mensajeVerde: "",
      desviacionEstandarAlto: "",
      desviacionEstandarBajo: "",
      porcentajeErroresAlto: "",
      porcentajeErroresBajo: "",
      tiempoResolucionAlto: "",
      tiempoResolucionBajo: "",
    };
  }
  componentDidMount() {
    this.getUsuarios();
  }

  getUsuarios = () => {
    this.setState({ loading: true });
    api({
      method: "get",
      url: "/usuarios/FilterUsersByGrupo?grupo=usuario_activo",
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        console.log("usuarios");
        console.log(response);
        this.setState({ loading: false });
        if (response.data[0]) {
          this.setState({ usuarios: response.data });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  getAllSemaforoParams = () => {
    console.log("getting semaforo params");
    this.setState({ loading: true });
    api({
      method: "get",
      url: "/semaforo/GetSemaforoParams",
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log("response data");
        console.log(response.data);
        let params = response.data[0];

        const {
          desviacion_estandar_alto,
          desviacion_estandar_bajo,
          porcentaje_errores_alto,
          porcentaje_errores_bajo,
          tiempo_resolucion_alto,
          tiempo_resolucion_bajo,
          verde_mensaje,
          rojo_mensaje,
          amarillo_mensaje,
        } = params;

        this.setState({
          mensajeAmarillo: amarillo_mensaje,
          mensajeRojo: rojo_mensaje,
          mensajeVerde: verde_mensaje,
          desviacionEstandarAlto: desviacion_estandar_alto,
          desviacionEstandarBajo: desviacion_estandar_bajo,
          porcentajeErroresAlto: porcentaje_errores_alto,
          porcentajeErroresBajo: porcentaje_errores_bajo,
          tiempoResolucionAlto: tiempo_resolucion_alto,
          tiempoResolucionBajo: tiempo_resolucion_bajo,
        });

        this.calculateSemaforo(
          tiempo_resolucion_alto,
          tiempo_resolucion_bajo,
          porcentaje_errores_alto,
          porcentaje_errores_bajo,
          desviacion_estandar_alto,
          desviacion_estandar_bajo,
          rojo_mensaje,
          amarillo_mensaje,
          verde_mensaje
        );
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  calculateSemaforo = (
    tiempoResolucionAlto: string,
    tiempoResolucionBajo: string,
    porcentajeErroresAlto: string,
    porcentajeErroresBajo: string,
    desviacionEstandarAlto: string,
    desviacionEstandarBajo: string,
    mensajeRojo: string,
    mensajeAmarillo: string,
    mensajeVerde: string
  ) => {
    const { chartData } = this.state;

    console.log("calculate semaforo");
    console.log("chart data");
    console.log(chartData);

    let codigoTiempoPromedio;
    let codigoPorcentajeErrores;
    let codigoDesviacion;
    let codigoTotal;
    let semaforoColor;
    let semaforoMensaje;

    let tiempoPromedio = this.promedioTiempoTotal();

    let desviacion = this.promedioDesviacion();

    let porcentajeErrores = 100 - this.calcularPrecision();

    console.log("porcentajeErrores");
    console.log(porcentajeErrores);

    console.log("tiempo promedio: ");
    console.log(tiempoPromedio);

    console.log("desviacion estandar");
    console.log(desviacion);

    let tiempoResolucionAltoInt = parseInt(tiempoResolucionAlto);
    let tiempoResolucionBajoInt = parseInt(tiempoResolucionBajo);

    let porcentajeErroresAltoInt = parseFloat(porcentajeErroresAlto);
    let porcentajeErroresBajoInt = parseFloat(porcentajeErroresBajo);

    let desviacionEstandarAltoInt = parseInt(desviacionEstandarAlto);
    let desviacionEstandarBajoInt = parseInt(desviacionEstandarBajo);

    if (tiempoPromedio < tiempoResolucionAltoInt) {
      codigoTiempoPromedio = "A";
    } else if (
      tiempoPromedio > tiempoResolucionAltoInt &&
      tiempoPromedio < tiempoResolucionBajoInt
    ) {
      codigoTiempoPromedio = "M";
    } else {
      codigoTiempoPromedio = "B";
    }

    if (porcentajeErrores < porcentajeErroresAltoInt) {
      codigoPorcentajeErrores = "A";
    } else if (
      porcentajeErrores > porcentajeErroresAltoInt &&
      porcentajeErrores < porcentajeErroresBajoInt
    ) {
      codigoPorcentajeErrores = "M";
    } else {
      codigoPorcentajeErrores = "B";
    }

    if (desviacion < desviacionEstandarAltoInt) {
      codigoDesviacion = "A";
    } else if (
      desviacion > desviacionEstandarAltoInt &&
      desviacion < desviacionEstandarBajoInt
    ) {
      codigoDesviacion = "M";
    } else {
      codigoDesviacion = "B";
    }

    console.log("codigoTiempoPromedio");
    console.log(codigoTiempoPromedio);

    console.log("codigoPorcentajeErrores");
    console.log(codigoPorcentajeErrores);

    console.log("codigoDesviacion");
    console.log(codigoDesviacion);

    codigoTotal =
      codigoTiempoPromedio + codigoPorcentajeErrores + codigoDesviacion;

    console.log("codigo: ");
    console.log(codigoTotal);

    let cantidadB = codigoTotal.split("B").length - 1;
    if (cantidadB > 2) {
      semaforoColor = "red";
      semaforoMensaje = mensajeRojo;
    } else if (cantidadB === 1) {
      semaforoColor = "yellow";
      semaforoMensaje = mensajeAmarillo;
    } else {
      semaforoColor = "green";
      semaforoMensaje = mensajeVerde;
    }

    this.setState({ semaforoColor: semaforoColor });
    this.setState({ semaforoMensaje: semaforoMensaje });

    console.log("semaforoColor");
    console.log(semaforoColor);
  };

  filterResultadosByEmail = (email: string) => {
    this.setState({ loading: true });
    api({
      method: "get",
      url: "/resultados/FilterResultadosByEmail?email=" + email,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log(response.data);
        let data = response.data;

        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          let fecha = item.fecha;
          let shortDate = fecha.substring(0, 10);
          item.shortDate = shortDate;
        }

        this.setState({ chartData: data });
        this.setState({ data: data });
        console.log("chartData");
        console.log(data);

        this.getAllSemaforoParams();
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  selectUser = (e: any) => {
    const { usuarios } = this.state;
    if (e) {
      let id = e.value;
      let selectedUsuario = usuarios.find((element: any) => element.id == id);
      console.log(selectedUsuario);
      this.setState({
        selectedUsuario: selectedUsuario!,
      });
      this.filterResultadosByEmail(selectedUsuario!.email);
    }
  };

  generateUserOptions = () => {
    const { usuarios } = this.state;
    let results: any = [];
    for (let i in usuarios) {
      results.push({
        value: usuarios[i].id,
        label: usuarios[i].nombre + " " + usuarios[i].apellido,
      });
    }
    return results;
  };

  selectDesde = (event: any, data: any) => {
    let desdeObj = new Date(data.value);

    desdeObj.setHours(0, 0, 0, 0);

    console.log("desde");
    console.log(data.value);

    if (!desdeObj) {
      desdeObj = new Date();
    }

    this.setState({ desde: desdeObj });
  };

  selectHasta = (event: any, data: any) => {
    const { desde } = this.state;
    let hasta = new Date(data.value);

    hasta.setHours(23, 59, 59, 0);

    console.log("desde");
    console.log(desde);

    console.log("hasta: ");
    console.log(hasta);

    this.setState({ hasta: hasta });
    this.filterChartByDate(desde, hasta);
    this.filterGuiasByDate(desde, hasta);
  };

  timeConverter = (timestamp: number) => {
    var current_date = new Date(timestamp);

    var formatted_date =
      current_date.getDate() +
      "/" +
      (current_date.getMonth() + 1) +
      "/" +
      current_date.getFullYear();

    return formatted_date;
  };

  //keyword
  filterGuiasByDate = (desde: Date, hasta: Date) => {
    if (!hasta) return;
    const { selectedUsuario } = this.state;
    let desdeTimestamp = desde.getTime();
    let hastaTimestamp = hasta.getTime();

    this.setState({ loading: true });

    let url =
      "/guias/FilterGuiasByEmailDateRange?usuario=" +
      selectedUsuario.email +
      "&&desde=" +
      desdeTimestamp +
      "&&hasta=" +
      hastaTimestamp;

    console.log("url");
    console.log(url);
    api({
      method: "get",
      url: url,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        console.log("guias");
        console.log(response);
        this.setState({ loading: false });
        let guias: Guia[] = [];
        if (response.data[0]) {
          for (let i = 0; i < response.data.length; i++) {
            let guia = response.data[i];
            guias.push(guia);
          }
          this.calcularAsistencia(guias);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  filterChartByDate = (desde: any, hasta: any) => {
    const { data } = this.state;
    if (hasta) {
      let filteredData = data.filter((item: any) => {
        return (
          item.timestamp >= desde.getTime() && item.timestamp <= hasta.getTime()
        );
      });
      console.log("filtered data");
      console.log(filteredData);

      this.setState({ chartData: filteredData });
    }
  };

  selectCurrentGuia = (ejID: number) => {
    console.log(ejID);
    this.setState({ currentGuia: ejID });
  };

  promedioTiempoTotal = () => {
    const { chartData } = this.state;
    let total = 0;
    let promedio;
    for (let i = 0; i < chartData.length; i++) {
      let currentGuia = chartData[i];
      total = total + currentGuia.tiempoTotal;
    }
    promedio = Number((total / chartData.length).toFixed(2));
    return promedio;
  };

  promedioVelocidad = () => {
    const { chartData } = this.state;
    let total = 0;
    let promedio;
    for (let i = 0; i < chartData.length; i++) {
      let currentGuia = chartData[i];
      total = total + currentGuia.tiempoPromedio;
    }
    promedio = Number((total / chartData.length).toFixed(2));
    return promedio;
  };

  promedioDesviacion = () => {
    const { chartData } = this.state;
    let total = 0;
    let promedio;
    for (let i = 0; i < chartData.length; i++) {
      let currentGuia = chartData[i];
      total = total + currentGuia.desviacion;
    }
    promedio = Number((total / chartData.length).toFixed(2));
    return promedio;
  };

  calcularPrecision = () => {
    // porcentaje de aciertos = 1 - ejercicios con error / ejercicios totales
    const { chartData } = this.state;
    let porcentajeDeEjerciciosSinErroresPorGuia = new Array(chartData.length);
    for (let i = 0; i < chartData.length; i++) {
      let currentGuia = chartData[i];
      let porcentajePrecision;
      let ejerciciosConErrores = 0;
      let ejerciciosPorGuia = currentGuia.ejercicios.length;
      let ejercicios = currentGuia.ejercicios;
      for (let a = 0; a < ejercicios.length; a++) {
        let ej = ejercicios[a];
        if (ej.errores > 0) {
          ejerciciosConErrores++;
        }
      }
      let ejerciciosSinErrores = ejerciciosPorGuia - ejerciciosConErrores;
      porcentajePrecision = Number(
        ((ejerciciosSinErrores / ejerciciosPorGuia) * 100).toFixed(2)
      );
      porcentajeDeEjerciciosSinErroresPorGuia[i] = porcentajePrecision;
    }

    //calculando promedio
    let totalPrecision = 0;

    for (let t = 0; t < porcentajeDeEjerciciosSinErroresPorGuia.length; t++) {
      let porcentaje = porcentajeDeEjerciciosSinErroresPorGuia[t];
      totalPrecision = totalPrecision + porcentaje;
    }
    let promedio = Number((totalPrecision / chartData.length).toFixed(2));
    return promedio;
  };

  calcularAsistencia = (guias: Guia[]) => {
    console.log("guias");
    console.log(guias);

    let guiasResueltasQuery = guias?.filter((e: Guia) => {
      return e.resuelta === true;
    });

    let guiasResueltas = guiasResueltasQuery.length;

    let guiasAsignadas = guias.length;

    let asistencia = Math.round((guiasResueltas / guiasAsignadas) * 100);

    console.log("asistencia");
    console.log(asistencia);

    this.setState({ asistencia: asistencia });
  };

  handleReporteChange = (checked: boolean) => {
    this.setState({ reporteMensual: checked });
  };

  generateReporte = () => {
    const { currentUser } = this.context;
    const {
      selectedUsuario,
      chartData,
      guiasMPV,
      guiasLenguaje,
      metaAnterior,
      metaActual,
      comentariosPadres,
      recomendacionesPedagogo,
      compromisos,
      nombreRepresentate,
      reporteMensual,
      semaforoColor,
      semaforoMensaje,
      desde,
      hasta,
      asistencia,
    } = this.state;
    console.log("current user");
    console.log(currentUser);
    let element;
    const { row, semaforoVerde } = styles;
    let lastIndex = chartData.length - 1;

    let pedagogoNombreCompleto;
    let pedagogoNombre;
    let pedagogoApellido;

    const sortedResults = [...chartData].sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );

    if (
      selectedUsuario.pedagogoAsignado !== "" &&
      selectedUsuario.pedagogoAsignado
    ) {
      pedagogoNombreCompleto = selectedUsuario.pedagogoAsignado.match(
        /\b(\p{L}+)\b/gu
      );
      pedagogoNombre = pedagogoNombreCompleto[0];
      pedagogoApellido = pedagogoNombreCompleto[1];
    }

    console.log("chart data");
    console.log(chartData);

    if (selectedUsuario.nombre != "" && chartData.length > 0) {
      if (reporteMensual) {
        element = (
          <div
            id="reporte"
            style={{
              fontSize: 20,
              margin: "auto",
              height: 1500,
              width: 1000,
              padding: 40,
            }}
          >
            <Grid style={{ marginTop: 40, marginBottom: 20 }}>
              <Row style={row}>
                <Col xs={12} md={12}>
                  <div style={{ textAlign: "center" }}>
                    <img width={300} src={logo} alt="Logo" />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col xs={6} md={2}></Col>
                <Col xs={6} md={2}>
                  <h3>Fecha: </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{this.timeConverter(new Date().getTime())}, </h3>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={2}></Col>
                <Col xs={6} md={2}>
                  <h3>Nombre: </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{selectedUsuario.nombre} </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{selectedUsuario.apellido} </h3>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={2}></Col>
                <Col xs={6} md={2}>
                  <h3>Pedagogo: </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{pedagogoNombre}</h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{pedagogoApellido}</h3>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={2}></Col>
                <Col xs={6} md={2}>
                  <h3>Desde: </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{this.timeConverter(desde.getTime()) || ""}</h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>Hasta: </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{this.timeConverter(hasta.getTime()) || ""}</h3>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={12} md={12}>
                  <div>
                    <AreaChart
                      width={900}
                      height={200}
                      data={sortedResults}
                      margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 60,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis angle={45} tickMargin={40} dataKey="shortDate" />
                      <YAxis />
                      <Tooltip />
                      <Legend verticalAlign="top" iconType="square" />
                      <Area
                        name="Tiempo Promedio (secs)"
                        type="monotone"
                        dataKey="tiempoPromedio"
                        stroke="#8884d8"
                        fill="#8884d8"
                        fillOpacity="0.5"
                        label="tiempo promedio en segundos"
                      />
                      <Area
                        name="Desviacion"
                        type="monotone"
                        dataKey="desviacion"
                        stroke="#008000"
                        fill="#82ca9d"
                        fillOpacity="0.5"
                      />
                      <Area
                        name="Tiempo Total (mins)"
                        type="monotone"
                        dataKey="tiempoTotal"
                        stroke="#505050"
                        fill="#505050"
                        fillOpacity="0.5"
                        label="tiempo total en minutos"
                      />
                      <Area
                        name="Errores"
                        type="monotone"
                        dataKey="errores"
                        stroke="#FF0000"
                        fill="#FF0000"
                        fillOpacity="0.5"
                      />
                    </AreaChart>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col xs={1} md={6}>
                  Item
                </Col>
                <Col xs={1} md={2}>
                  Dos meses atras
                </Col>
                <Col xs={1} md={2}>
                  Un mes atras
                </Col>
                <Col xs={1} md={2}>
                  Mes actual
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Tema
                </Col>
                <Col xs={1} md={2}>
                  <input
                    style={{ width: "100%" }}
                    onChange={(e) => console.log(e.target.value)}
                    type="text"
                  ></input>
                </Col>
                <Col xs={1} md={2}>
                  <input
                    style={{ width: "100%" }}
                    onChange={(e) => console.log(e.target.value)}
                    type="text"
                  ></input>
                </Col>
                <Col xs={1} md={2}>
                  <input
                    style={{ width: "100%" }}
                    onChange={(e) => console.log(e.target.value)}
                    type="text"
                  ></input>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Nivel
                </Col>
                <Col xs={1} md={2}>
                  <input
                    style={{ width: "100%" }}
                    onChange={(e) => console.log(e.target.value)}
                    type="text"
                  ></input>
                </Col>
                <Col xs={1} md={2}>
                  <input
                    style={{ width: "100%" }}
                    onChange={(e) => console.log(e.target.value)}
                    type="text"
                  ></input>
                </Col>
                <Col xs={1} md={2}>
                  <input
                    style={{ width: "100%" }}
                    onChange={(e) => console.log(e.target.value)}
                    type="text"
                  ></input>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Asistencia
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  {asistencia}%
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Entrenamiento diario (minutos)
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  {this.promedioTiempoTotal()}
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Velocidad por ejercicio (segundos)
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  {this.promedioVelocidad()}
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Precision (porcentaje de aciertos)
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  {this.calcularPrecision()}%
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Guias Matematicas para la Vida
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  <input
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({ guiasMPV: e.target.value })
                    }
                    type="text"
                    value={guiasMPV}
                    placeholder="100%"
                  ></input>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Guias Lenguaje
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  <input
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({ guiasLenguaje: e.target.value })
                    }
                    type="text"
                    value={guiasLenguaje}
                    placeholder="100%"
                  ></input>
                </Col>
              </Row>
              <Row style={{ marginTop: 40, marginBottom: 60 }}>
                <Col xs={4} md={6} style={{ textAlign: "center" }}>
                  <div
                    style={{
                      height: 50,
                      width: 50,
                      backgroundColor: semaforoColor,
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  ></div>
                </Col>
                <Col
                  xs={4}
                  md={6}
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ textAlign: "center", marginTop: 20 }}>
                    {semaforoMensaje}
                  </div>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={6}>
                  <div style={{ fontWeight: "bold" }}>Meta Anterior</div>
                </Col>
                <Col xs={6} md={6}>
                  <div style={{ fontWeight: "bold" }}>Meta Actual</div>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={6}>
                  <div>
                    <textarea
                      wrap="soft"
                      cols={1}
                      rows={5}
                      style={{ height: 100, width: "100%" }}
                      onChange={(e) =>
                        this.setState({ metaAnterior: e.target.value })
                      }
                      value={metaAnterior}
                      placeholder="100%"
                    ></textarea>
                  </div>
                </Col>
                <Col xs={6} md={6}>
                  <textarea
                    wrap="soft"
                    cols={1}
                    rows={5}
                    style={{ height: 100, width: "100%" }}
                    onChange={(e) =>
                      this.setState({ metaActual: e.target.value })
                    }
                    value={metaActual}
                    placeholder="100%"
                  ></textarea>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={6}>
                  <div style={{ fontWeight: "bold" }}>
                    Comentarios de los padres:{" "}
                  </div>
                </Col>
                <Col xs={6} md={6}>
                  <div style={{ fontWeight: "bold" }}>
                    Recomendaciones del pedagogo:{" "}
                  </div>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={6}>
                  <textarea
                    wrap="soft"
                    cols={1}
                    rows={5}
                    style={{ height: 100, width: "100%" }}
                    onChange={(e) =>
                      this.setState({ comentariosPadres: e.target.value })
                    }
                    value={comentariosPadres}
                    placeholder="100%"
                  ></textarea>
                </Col>
                <Col xs={6} md={6}>
                  <textarea
                    wrap="soft"
                    cols={1}
                    rows={5}
                    style={{ height: 100, width: "100%" }}
                    onChange={(e) =>
                      this.setState({ recomendacionesPedagogo: e.target.value })
                    }
                    value={recomendacionesPedagogo}
                    placeholder="100%"
                  ></textarea>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={6}>
                  <div style={{ fontWeight: "bold" }}>Compromisos: </div>
                </Col>
                <Col xs={6} md={6}>
                  <div style={{ fontWeight: "bold" }}>
                    Nombre del (los) Representante(s):{" "}
                  </div>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={6}>
                  <textarea
                    wrap="soft"
                    cols={1}
                    rows={5}
                    style={{ height: 100, width: "100%" }}
                    onChange={(e) =>
                      this.setState({ compromisos: e.target.value })
                    }
                    value={compromisos}
                    placeholder="100%"
                  ></textarea>
                </Col>
                <Col xs={6} md={6}>
                  <textarea
                    wrap="soft"
                    cols={1}
                    rows={5}
                    style={{ height: 100, width: "100%" }}
                    onChange={(e) =>
                      this.setState({ nombreRepresentate: e.target.value })
                    }
                    value={nombreRepresentate}
                    placeholder="100%"
                  ></textarea>
                </Col>
              </Row>
            </Grid>
          </div>
        );
      } else {
        element = (
          <div
            id="reporte"
            style={{ margin: "auto", height: 900, width: 700, paddingTop: 20 }}
          >
            <Grid style={{ marginTop: 40 }}>
              <Row style={row}>
                <Col xs={12} md={12}>
                  <div style={{ textAlign: "center" }}>
                    <img width={300} src={logo} alt="Logo" />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col xs={6} md={2}></Col>
                <Col xs={6} md={2}>
                  <h3>Fecha: </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{this.timeConverter(new Date().getTime())}, </h3>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={2}></Col>
                <Col xs={6} md={2}>
                  <h3>Nombre: </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{selectedUsuario.nombre} </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{selectedUsuario.apellido} </h3>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={2}></Col>
                <Col xs={6} md={2}>
                  <h3>Pedagogo: </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{pedagogoNombre}</h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{pedagogoApellido}</h3>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={6} md={2}></Col>
                <Col xs={6} md={2}>
                  <h3>Desde: </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{this.timeConverter(desde.getTime())}, </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>Hasta: </h3>
                </Col>
                <Col xs={6} md={2}>
                  <h3>{this.timeConverter(hasta.getTime())} </h3>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Asistencia
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  {asistencia}%
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Entrenamiento diario (minutos)
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  {this.promedioTiempoTotal()}
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Velocidad por ejercicio (segundos)
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  {this.promedioVelocidad()}
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Precision (porcentaje de aciertos)
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  {this.calcularPrecision()}%
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Guias Matematicas para la Vida
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  <input
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({ guiasMPV: e.target.value })
                    }
                    type="text"
                    value={guiasMPV}
                    placeholder="100%"
                  ></input>
                </Col>
              </Row>
              <Row style={row}>
                <Col xs={2} md={6}>
                  Guias Lenguaje
                </Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}></Col>
                <Col xs={1} md={2}>
                  <input
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({ guiasLenguaje: e.target.value })
                    }
                    type="text"
                    value={guiasLenguaje}
                    placeholder="100%"
                  ></input>
                </Col>
              </Row>
              <Row style={{ marginTop: 40, marginBottom: 60 }}>
                <Col xs={4} md={6} style={{ textAlign: "center" }}>
                  <div
                    style={{
                      height: 50,
                      width: 50,
                      backgroundColor: semaforoColor,
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  ></div>
                </Col>
                <Col
                  xs={4}
                  md={6}
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ textAlign: "center", marginTop: 20 }}>
                    {semaforoMensaje}
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        );
      }
    }
    return element;
  };

  downloadReporte = () => {
    html2canvas(document.querySelector("#reporte")).then((canvas) => {
      // document.body.appendChild(canvas)
      console.log(canvas);
      canvas.style.display = "none";
      var image = canvas.toDataURL("png");
      var a = document.createElement("a");
      a.setAttribute("download", "reporte.png");
      a.setAttribute("href", image);
      a.click();
      //hello world
    });
  };

  render() {
    const { reporteMensual, loading } = this.state;
    const { container, child } = styles;
    return (
      <LoadingOverlay active={loading} spinner text="Cargando...">
        <div>
          <Grid>
            <Row>
              <Col xs={1} md={3}>
                <form className="ui form">
                  <div>
                    <div className="field">
                      <label>Usuario</label>
                      <Select
                        onChange={(e) => this.selectUser(e)}
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={{ value: usuarios[0].id, label: usuarios[0].nombre }}
                        isDisabled={false}
                        placeholder={"usuario"}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="color"
                        options={this.generateUserOptions()}
                      />
                    </div>
                  </div>
                </form>
              </Col>
              <Col xs={1} md={3}>
                <div style={child}>
                  <label>Desde</label>
                  <SemanticDatepicker onChange={this.selectDesde} />
                </div>
              </Col>
              <Col xs={1} md={3}>
                <div style={child}>
                  <label>Hasta</label>
                  <SemanticDatepicker onChange={this.selectHasta} />
                </div>
              </Col>
              <Col xs={1} md={1}>
                <div>
                  <label>Tipo de reporte</label>
                  <div>
                    <Switch
                      onChange={this.handleReporteChange}
                      checked={reporteMensual}
                      className="react-switch"
                    />
                  </div>
                </div>
              </Col>
              <Col xs={1} md={1}>
                <h3>{reporteMensual ? "Mensual" : "Semanal"}</h3>
              </Col>
              <Col xs={1} md={1}>
                <Button style={{ margin: 20 }} onClick={this.downloadReporte}>
                  Descargar
                </Button>
              </Col>
            </Row>
          </Grid>

          {this.generateReporte()}
        </div>
      </LoadingOverlay>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center"
  },
  child: {
    flex: 1,
  },
  row: {
    marginTop: 10,
    // marginBottom: 20,
  },
  semaforoVerde: {
    height: 50,
    width: 50,
    backgroundColor: "#149414",
    borderRadius: "50%",
    display: "inline-block",
  },
};

export default InformesSituacion;

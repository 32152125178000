import { Guia, defaultGuia } from './guia';

export interface Asignacion {
    id?: string,
    fecha: number,
    guia: Guia
}

export const defaultAsignacion = {
    fecha: 0,
    guia: defaultGuia
}
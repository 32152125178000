import { Pais } from '../data-models/pais';
export const paises: Pais[] = [
    { code: 'SV', text: 'El Salvador' },
    { code: 'US', text: 'United States' },
    { code: 'BR', text: 'Brazil' },
    { code: 'MX', text: 'Mexico' },
    { code: 'CO', text: 'Colombia' },
    { code: 'AR', text: 'Argentina' },
    { code: 'PE', text: 'Peru' },
    { code: 'VE', text: 'Venezuela' },
    { code: 'CL', text: 'Chile' },
    { code: 'EC', text: 'Ecuador' },
    { code: 'GT', text: 'Guatemala' },
    { code: 'BO', text: 'Bolivia' },
    { code: 'HN', text: 'Honduras' },
    { code: 'PY', text: 'Paraguay' },
    { code: 'NI', text: 'Nicaragua' },
    { code: 'CR', text: 'Costa Rica' },
    { code: 'PA', text: 'Panama' },
    { code: 'UY', text: 'Uruguay' },
]
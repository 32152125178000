import React, { useState, useEffect, useContext } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import { User } from "../../data-models/user";

function Celda(props: {
  addOrRemoveGuia: any;
  getCellColor: any;
  indexGuia: number;
  currentDay: Date;
}) {
  let white = "#A9A9A9";
  let red = "red";

  const [buttonColor, setButtonColor] = useState(white);
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    let color = props.getCellColor(props.indexGuia, props.currentDay);
    setButtonColor(color);
  });

  const handleColorChange = (e: any) => {
    const newColor = buttonColor === white ? red : white;
    setButtonColor(newColor);
    props.addOrRemoveGuia(props.indexGuia, props.currentDay);
  };

  return (
    <div style={{ padding: 0 }}>
      <div
        style={{
          backgroundColor: buttonColor,
          width: "100%",
          height: 20,
          borderWidth: 0,
        }}
        onClick={handleColorChange}
      ></div>
    </div>
  );
}

export default Celda;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
//@ts-ignore
import authentication from "@kdpw/msal-b2c-react";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { render } from "react-dom";
import "semantic-ui-css/semantic.min.css";

authentication.initialize({
  instance: "https://WLSTenant.b2clogin.com/tfp/",
  tenant: "WLSTenant.onmicrosoft.com",
  signInPolicy: "B2C_1_SignUpSignIn",
  applicationId: "6b0cbfae-06aa-45a0-9485-93d91c35de87",
  cacheLocation: "sessionStorage",
  scopes: ["profile", "openid"],
  // redirectUri: "http://localhost:3000",
  // redirectUri: 'https://wiserlearningstorage.z22.web.core.windows.net',
  redirectUri: "https://go.wiser.center",
  validateAuthority: false,
});

authentication.run(() => {
  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
});

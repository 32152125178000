import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import wiserContext from "../../providers/wiserContext";
import Switch from "react-switch";
import { Button } from "semantic-ui-react";
import { history } from "../history";
import { Ejercicio } from "../../data-models/ejercicio";
import TecladoRespuesta from "../Teclado/TecladoRespuesta";
import TecladoProcedimiento from "../Teclado/TecladoProcedimiento";
import { User } from "../../data-models/user";
import Resultados from "./Resultados";
import { cleanProcedimientoGrid, compareGrids } from "../../helpers/grids";
import { isBrowser, isMobile } from "react-device-detect";

import {
  generateProcedimientoGridDivision,
  generateTargetProcedimientoGridDivision,
} from "../../helpers/gridDivision";

interface IProps {
  tiempo: string;
  resetTimer: any;
  startTimer: any;
  stopTimer: any;
  stopGeneralTimer: any;
  currentUser: User;
  tiemposRespuesta: number[];
}

interface IState {
  counter: number;
  respuesta: string[];
  errores: number;
  respuestaIndex: number;
  erroresPorEjercicio: number[];
  residuoRespuesta: string[];
  radicalTipo: string;
  procedimientoGrid: string[][];
  targetProcedimientoGrid: string[][];
  width: number;
  direccion: string;
  currentGrid: string;
  rowIndex: number;
  colIndex: number;
  residuoIndex: number;
  horaComienzo: string;
}

class Division extends Component<IProps, IState> {
  static contextType = wiserContext;
  state = {
    counter: 0,
    respuesta: [""],
    errores: 0,
    respuestaIndex: 0,
    erroresPorEjercicio: [0],
    residuoRespuesta: [""],
    radicalTipo: "inferior",
    procedimientoGrid: [[""]],
    targetProcedimientoGrid: [[""]],
    width: 0,
    direccion: "derecha",
    currentGrid: "cociente",
    rowIndex: 0,
    colIndex: 0,
    residuoIndex: 0,
    horaComienzo: "",
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.setupCounter();
    this.setupErroresPorEjercicio();
    this.setupErrores();
    this.setupGrids();
    this.selectFirstBox();
    this.setHoraComienzo();
  }

  setHoraComienzo = () => {
    var time = new Date();
    let timeString = time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    this.setState({ horaComienzo: timeString });
  };

  clearRespuesta = (arr: string[]) => {
    let filteredArr = arr.filter((digit) => digit !== "");
    return filteredArr;
  };

  handleFocus = (event: any, rowIndex: number, colIndex: number) => {
    this.updateCurrentGrid("procedimiento");
    this.updateRowIndex(rowIndex);
    this.updateColIndex(colIndex);

    if (isBrowser) {
      console.log("browser");
      event.target.select();
    } else {
      console.log("mobile");
      event.target.blur();
      event.preventDefault();
    }
  };

  handleFocusResiduo = (event: any, residuoIndex: number) => {
    this.updateCurrentGrid("residuo");
    this.updateResiduoIndex(residuoIndex);

    if (isBrowser) {
      console.log("browser");
      event.target.select();
    } else {
      console.log("mobile");
      event.target.blur();
      event.preventDefault();
    }
  };

  handleFocusCociente = (event: any, cocienteIndex: number) => {
    this.updateRespuestaIndex(cocienteIndex);
    this.updateCurrentGrid("respuesta");

    if (isBrowser) {
      console.log("browser");
      event.target.select();
    } else {
      console.log("mobile");
      event.target.blur();
      event.preventDefault();
    }
  };

  generateGrid = () => {
    const { radicalTipo } = this.state;
    let grid;
    switch (radicalTipo) {
      case "superior":
        // grid = generateSuperiorRadicalView();
        console.log("generate superior radical view");
        break;
      case "inferior":
        grid = this.generateInferiorRadicalView();
        break;
      default:
        grid = this.generateInferiorRadicalView();
        break;
    }

    return grid;
  };

  updateResiduoIndex = (index: number) => {
    this.setState({ residuoIndex: index });
  };

  updateCurrentGrid = (currentGrid: string) => {
    this.setState({ currentGrid: currentGrid });
  };

  updateRowIndex = (index: number) => {
    this.setState({ rowIndex: index });
  };

  updateColIndex = (index: number) => {
    this.setState({ colIndex: index });
  };

  updateRespuestaIndex = (index: number) => {
    this.setState({ respuestaIndex: index });
  };

  selectNextBox = (ejercicioIndex: number) => {
    const { selectedGuia } = this.context;

    let selectedEjercicio = selectedGuia.ejercicios[ejercicioIndex];
    if (!selectedEjercicio) {
      return;
    }
    let solucion = selectedEjercicio.solucion;
    let solucionLength = solucion.toString().length;

    if (!selectedGuia) {
      return;
    }

    let ejercicio = selectedGuia.ejercicios[ejercicioIndex];
    if (!ejercicio) {
      return;
    }

    let nextBoxId;
    let nextBox;

    nextBoxId = "respuesta_box_0";
    nextBox = document.getElementById(nextBoxId);
    if (nextBox) {
      nextBox.focus();
    }
  };

  // local storage

  next = () => {
    const { selectedGuia } = this.context;
    const { counter } = this.state;
    let newCount = counter + 1;

    let ejerciciosLength = selectedGuia.ejercicios.length;

    this.props.resetTimer(counter);

    if (newCount === ejerciciosLength) {
      this.props.stopTimer();
      this.props.stopGeneralTimer();
    } else {
      this.props.stopTimer();
      this.props.startTimer();
    }

    this.setState({ counter: newCount });

    this.resetProcedimientoGrid(newCount);
    this.resetTargetProcedimientoGrid(newCount);
    this.updateCounterLocalStorage(newCount.toString());
    this.selectNextBox(newCount);
  };

  getEntrenamientoIdLocalStorage = () => {
    // const { selectedGuia } = this.context;
    // const { titulo, guiaId } = selectedGuia;
    // const id = titulo + "_" + guiaId;

    // return id;

    const { selectedGuia } = this.context;
    const { codigo, id } = selectedGuia;
    const guiaId = codigo + "_" + id;

    return guiaId;
  };

  setupCounter = () => {
    const counterId = this.getEntrenamientoIdLocalStorage() + "_counter";
    const localStorageCounter = localStorage.getItem(counterId);

    if (localStorageCounter) {
      console.log("localStorageCounter: " + localStorageCounter);
      this.setState({ counter: parseInt(localStorageCounter) });
    } else {
      console.log("localStorageCounter not found, setting default to zer0");
      this.setState({ counter: 0 });
    }
  };

  setupErrores = () => {
    const erroresId = this.getEntrenamientoIdLocalStorage() + "_errores";
    const localStorageErrores = localStorage.getItem(erroresId);

    if (localStorageErrores) {
      console.log("localStorageErrores: " + localStorageErrores);
      this.setState({ errores: parseInt(localStorageErrores) });
    } else {
      console.log("localStorageErrores not found");
      this.setState({ errores: 0 });
    }
  };

  setupErroresPorEjercicio = () => {
    const { selectedGuia } = this.context;
    const erroresPorEjercicioId =
      this.getEntrenamientoIdLocalStorage() + "_erroresPorEjercicio";

    let ejerciciosLength = selectedGuia.ejercicios.length;
    let defaultErroresPE: number[] = [];

    const localStorageDefaultErrores = localStorage.getItem(
      erroresPorEjercicioId
    );
    console.log("localStorageDefaultErrores");
    console.log(localStorageDefaultErrores);
    if (localStorageDefaultErrores) {
      let localStorageDefaultErroresArray = localStorageDefaultErrores
        .substring(1, localStorageDefaultErrores.length - 1)
        .split(",");

      var nums = localStorageDefaultErroresArray.map((str) => parseInt(str));

      console.log("errores por ejercicio");
      console.log(nums);

      this.setState({ erroresPorEjercicio: nums });
    } else {
      console.log("local storage default errores not found, starting with 0");
      for (let i = 0; i < ejerciciosLength; i++) {
        defaultErroresPE.push(0);
      }
      this.setState({ erroresPorEjercicio: defaultErroresPE });
    }
  };

  setupGrids = () => {
    const counterId = this.getEntrenamientoIdLocalStorage() + "_counter";
    const localStorageCounter = localStorage.getItem(counterId);
    let startingIndex;

    if (localStorageCounter) {
      startingIndex = parseInt(localStorageCounter);
    } else {
      startingIndex = 0;
    }

    this.resetProcedimientoGrid(startingIndex);
    this.resetTargetProcedimientoGrid(startingIndex);
  };

  updateErroresLocalStorage = (errores: string) => {
    const erroresPorEjercicioId =
      this.getEntrenamientoIdLocalStorage() + "_errores";

    localStorage.setItem(erroresPorEjercicioId, errores);
  };

  updateCounterLocalStorage = (count: string) => {
    const counterId = this.getEntrenamientoIdLocalStorage() + "_counter";

    localStorage.setItem(counterId, count);
  };

  updateErroresPorEjercicioLocalStorage = (errores: string) => {
    const erroresPorEjercicioId =
      this.getEntrenamientoIdLocalStorage() + "_erroresPorEjercicio";

    localStorage.setItem(erroresPorEjercicioId, errores);
  };

  addErrorPorEjercicio = (index: number) => {
    const { erroresPorEjercicio } = this.state;
    let currentEjercicioErrores = erroresPorEjercicio[index];
    let newErrores = erroresPorEjercicio;
    let newErroresCount = currentEjercicioErrores + 1;
    newErrores[index] = newErroresCount;
    this.setState({ erroresPorEjercicio: newErrores });

    this.updateErroresPorEjercicioLocalStorage(JSON.stringify(newErrores));
  };

  resetProcedimientoGrid = (ejercicioIndex: number) => {
    const { selectedGuia } = this.context;
    if (!selectedGuia) {
      return;
    }

    const ejercicio = selectedGuia.ejercicios[ejercicioIndex];
    if (!ejercicio) {
      return;
    }

    const baseSolucionGrid = generateProcedimientoGridDivision(ejercicio);

    this.setState({ procedimientoGrid: baseSolucionGrid });
  };

  resetTargetProcedimientoGrid = (ejercicioIndex: number) => {
    const { selectedGuia } = this.context;
    if (!selectedGuia) {
      return;
    }

    let ejercicio = selectedGuia.ejercicios[ejercicioIndex];
    if (!ejercicio) {
      return;
    }

    const targetProcedimientoGrid = generateTargetProcedimientoGridDivision(
      ejercicio
    );

    this.setState({ targetProcedimientoGrid: targetProcedimientoGrid });
  };

  resetRespuesta = () => {
    const { selectedGuia } = this.context;
    const { counter } = this.state;
    let solucion = selectedGuia.ejercicios[counter].solucion;
    let residuo = selectedGuia.ejercicios[counter].residuo;
    let solucionLength = solucion.toString().length;
    let residuoLength = residuo.toString().split("").length;
    let defaultRespuesta: string[] = [];
    let defaultResiduo: string[] = [];
    for (let i = 0; i < solucionLength; i++) {
      defaultRespuesta.push("");
    }

    for (let i = 0; i < residuoLength; i++) {
      defaultResiduo.push("");
    }
    this.setState({ respuesta: defaultRespuesta });
    this.setState({ residuoRespuesta: defaultResiduo });
    this.updateCurrentGrid("cociente");
    this.updateColIndex(0);
    this.updateRowIndex(0);
  };

  evaluateDivisionRespuesta = (resp: string[], residuoRespuesta: string[]) => {
    const { selectedGuia } = this.context;
    const {
      counter,
      errores,
      procedimientoGrid,
      targetProcedimientoGrid,
    } = this.state;

    let newErrores = errores;

    let solucion = selectedGuia.ejercicios[counter].solucion;
    let residuo = selectedGuia.ejercicios[counter].residuo;

    let concatRespuesta = "";
    let concatResiduo = "";
    for (let i in resp) {
      let digit = resp[i];
      concatRespuesta = concatRespuesta + digit;
    }

    for (let i in residuoRespuesta) {
      let digit = residuoRespuesta[i];
      concatResiduo = concatResiduo + digit;
    }

    if (
      parseInt(concatRespuesta) === solucion &&
      parseInt(concatResiduo) === residuo
    ) {
      let cleanGrid = cleanProcedimientoGrid(procedimientoGrid);
      let cleanTargetGrid = cleanProcedimientoGrid(targetProcedimientoGrid);
      console.log("clean grid");
      console.log(cleanGrid);

      console.log("targetProcedimientoGrid");
      console.log(targetProcedimientoGrid);
      let match = compareGrids(cleanGrid, cleanTargetGrid);
      // console.log('match: ', match);
      // bug: returns true if cleanGrid is empty
      if (match) {
        window.setTimeout(() => {
          this.resetRespuesta();
          this.next();
        }, 500);
      } else {
        //resetting the first residuo box
        let residuoBoxFirstBox = document.getElementById(
          "residuo_box_0"
        ) as HTMLInputElement;
        console.log("box: ");
        console.log(residuoBoxFirstBox);
        // let value = residuoBoxFirstBox.value;
        residuoBoxFirstBox.value = "";
        // let newResiduoRespuesta = this.state.residuoRespuesta.shift();

        this.setState({ residuoRespuesta: [""] });

        alert("revisa el procedimiento");
      }
    } else {
      this.addErrorPorEjercicio(counter);
      newErrores = newErrores + 1;
      this.setState({ errores: newErrores });
      this.updateErroresLocalStorage(newErrores.toString());
    }
  };

  updateDivisionRespuesta = (res: string, index: number) => {
    console.log("update division respuestsa");
    const { selectedGuia } = this.context;
    const { counter, respuesta, direccion, residuoRespuesta } = this.state;
    let solucion = selectedGuia.ejercicios[counter].solucion;
    let residuo = selectedGuia.ejercicios[counter].residuo;
    let solucionLength = solucion.toString().length;
    let lastIndex = solucionLength - 1;
    let newRespuesta = respuesta;
    let newDireccion = direccion;
    let nextBoxIndex;

    if (index === 0 && newRespuesta[1] === "") {
      newDireccion = "derecha";
      this.setState({ direccion: newDireccion });
    }

    if (index === lastIndex && newRespuesta[lastIndex - 1] === "") {
      newDireccion = "izquierda";
      this.setState({ direccion: newDireccion });
    }

    if (newDireccion === "derecha") {
      nextBoxIndex = 1;
    } else {
      nextBoxIndex = -1;
    }

    let nextBox = document.getElementById((index + nextBoxIndex).toString());
    if (nextBox) {
      nextBox.focus();
    }

    newRespuesta[index] = res;

    this.setState({ respuesta: newRespuesta });
    this.setState({ currentGrid: "procedimiento" });
    // console.log('respuesta');
    // console.log(newRespuesta);

    let escapedRespuesta = this.clearRespuesta(newRespuesta);
    let escapedResiduo = this.clearRespuesta(residuoRespuesta);
    let newRLength = escapedRespuesta.length;
    let newResiduoLength = escapedResiduo.length;
    let residuoLength = residuo.toString().split("").length;

    if (newRLength === solucionLength && newResiduoLength === residuoLength) {
      this.evaluateDivisionRespuesta(newRespuesta, residuoRespuesta);
    }
  };

  divisionCocienteValueChanged = (val: string, index: number) => {
    const {
      procedimientoGrid,
      targetProcedimientoGrid,
      counter,
      respuesta,
    } = this.state;
    const { selectedGuia } = this.context;
    let ejercicio = selectedGuia.ejercicios[counter];
    const { solucion, operador, columnas, residuo } = ejercicio;

    let filteredVal = val.match(/\d/g);

    if (!filteredVal) {
      this.updateDivisionRespuesta("", index);
      return;
    }

    if (val == "") {
      this.updateDivisionRespuesta(val, index);
      return;
    }

    if (val.length > 1) {
      let lastIndex = val.length - 1;
      val = val[lastIndex];
    }

    let dividendo = ejercicio.columnas[0];
    let divisor = ejercicio.columnas[1];

    // Getting the length of value * divisor to see
    // which box to select next
    let valPorDivisor = parseInt(val) * divisor;
    let valPorDivisorLength = valPorDivisor.toString().length;
    let valPorDivisorLastIndex = valPorDivisorLength - 1;

    let mulLength = (parseInt(val) * divisor).toString().length;
    let dividendoLength = dividendo.toString().split("").length;
    let columnCount = 0;
    let nextRow = index * 2;

    // getting the length of the first sub number
    // of the dividendo where subnumber / divisor >=1
    // the length difference should be added to the index

    // keyword;

    // let dividendoSplit = dividendo.toString().split("");
    // let currentDividendo = '';
    // for (let i in dividendoSplit) {
    //     let currentItem = dividendoSplit[i];
    //     currentDividendo = currentDividendo + currentItem;
    //     let currentDividendoInt = parseInt(currentDividendo);
    //     if ((currentDividendoInt / divisor) >= 1) {
    //         break;
    //     }
    // }

    // let difference = currentDividendo.split("").length - valPorDivisorLength;

    let dividendoSubstr: string[] = [];

    for (let e = 0; e < dividendoLength; e++) {
      let digit = dividendo[e];
      dividendoSubstr.push(digit);
      let digitNum = parseInt(dividendoSubstr.join(""));
      if (digitNum / divisor >= 1) {
        break;
      }
    }

    let difference;

    if (dividendoSubstr.length > valPorDivisorLength) {
      difference = dividendoLength - dividendoSubstr.length;
    } else {
      difference = dividendoSubstr.length - valPorDivisorLength;
    }

    console.log("next row index: ", nextRow);
    // let nextColumn = (index) + 1;
    let nextColumn;
    let nextBoxId;
    let nextBox;
    // add index only in the first row
    if (nextRow == 0) {
      nextColumn = valPorDivisorLastIndex + index + difference;
    } else {
      nextColumn = valPorDivisorLastIndex + 1;
      nextBoxId = "grid_row_" + nextRow + "_col_" + nextColumn.toString();
      nextBox = document.getElementById(nextBoxId);
      if (!nextBox) {
        nextColumn = valPorDivisorLastIndex;
      }
    }

    if (valPorDivisor == 0) {
      nextColumn = dividendoLength - 1;
    }

    nextBoxId = "grid_row_" + nextRow + "_col_" + nextColumn.toString();
    // debugger;
    nextBox = document.getElementById(nextBoxId);
    if (nextBox) {
      nextBox.focus();
    }

    this.updateRowIndex(nextRow);

    this.updateColIndex(nextColumn);

    this.updateDivisionRespuesta(val, index);
  };

  generateCocienteBoxes = () => {
    const {
      procedimientoGrid,
      targetProcedimientoGrid,
      counter,
      respuesta,
    } = this.state;
    const { selectedGuia } = this.context;
    let ejercicio = selectedGuia.ejercicios[counter];
    const { solucion, operador, columnas, residuo } = ejercicio;
    let boxes: JSX.Element[] = [];
    let cociente = solucion;
    let cocienteString = cociente.toString();
    let escapedCociente = cocienteString.replace(/[^0-9]/g, "");
    const { inputBox } = this.getStyles();

    for (let i = 0; i < escapedCociente.length; i++) {
      let box = (
        <input
          id={"respuesta_box_" + i.toString()}
          key={"respuesta_box_" + i.toString()}
          onChange={(e) => this.divisionCocienteValueChanged(e.target.value, i)}
          type="text"
          value={respuesta[i]}
          style={inputBox}
          onFocus={(e) => {
            this.handleFocusCociente(e, i);
          }}
          autoComplete="off"
        ></input>
      );
      boxes.push(box);
    }
    return boxes;
  };

  updateProcedimientoGrid = (
    text: string,
    rowIndex: number,
    colIndex: number
  ) => {
    console.log("updateProcedimientoGrid");
    console.log("RowIndex: " + rowIndex);
    console.log("ColIndex: " + colIndex);
    const {
      procedimientoGrid,
      targetProcedimientoGrid,
      counter,
      respuesta,
    } = this.state;

    //keyword
    let filteredText = text.match(/\d/g);

    if (!filteredText) {
      text = "";
    }

    const { selectedGuia } = this.context;
    let ejercicio = selectedGuia.ejercicios[counter];
    const { solucion, operador, columnas, residuo } = ejercicio;

    let dividendo = columnas[0].toString().split(""); //54 becomes ['5', '4']
    let divisor = columnas[1].toString().split("");
    let cociente = solucion;
    let newGrid: string[][] = [];
    let replaceRow: string[] = [];
    let gridLength: number = procedimientoGrid.length;
    let nextBoxId: string;
    let nextBox;
    let nexColumn;
    let nextRow;
    let residuoLength = residuo.toString().split("").length;

    for (let i = 0; i < procedimientoGrid.length; i++) {
      newGrid.push(procedimientoGrid[i]);
    }

    let rowToUpdate = newGrid[rowIndex];
    let rowToUpdateLength = rowToUpdate.length;

    for (let i = 0; i < rowToUpdateLength; i++) {
      replaceRow.push(rowToUpdate[i]);
    }
    // debugger;
    replaceRow[colIndex] = text;
    newGrid[rowIndex] = replaceRow;

    //behavior of each row is different if even or odd
    if (text != "") {
      if (rowIndex % 2 == 0) {
        //if even
        let procedimientoCurrentRow = newGrid[rowIndex];
        let cleanProcedimientoRows = procedimientoCurrentRow.filter(
          (digit: string) => digit != ""
        );
        let procedimientoRowLength = cleanProcedimientoRows.length;

        let target = targetProcedimientoGrid[rowIndex];
        let targetRowLength = target.length;

        if (procedimientoRowLength == targetRowLength || colIndex == 0) {
          //next row
          nextRow = rowIndex + 1;
          nexColumn = colIndex + 1;
        } else {
          nextRow = rowIndex;
          nexColumn = colIndex - 1;
        }

        nextBoxId =
          "grid_row_" + nextRow.toString() + "_col_" + nexColumn.toString();
      } else {
        switch (colIndex) {
          case 0:
            nextRow = rowIndex;
            nexColumn = gridLength - 1;
            nextBoxId =
              "grid_row_" + nextRow.toString() + "_col_" + nexColumn.toString();

            nextBox = document.getElementById(nextBoxId);
            if (!nextBox) {
              nextBoxId = "respuesta_box_" + rowIndex.toString();
            }
            break;
          case gridLength - 1: // la primera caja de derecha a izquierda, osea si acabo de bajar un numero
            nextBoxId = "respuesta_box_" + rowIndex.toString();
            break;
          default:
            let term1;
            let term2;
            let previousRow = procedimientoGrid[rowIndex - 1];
            let result;
            if (rowIndex == 1) {
              // take first term from label
              term1 = parseInt(dividendo[colIndex - 1] + dividendo[colIndex]);
              term2 = parseInt(
                previousRow[colIndex - 1] + previousRow[colIndex]
              );
            } else {
              let firstTermRow = procedimientoGrid[rowIndex - 2];
              term1 = parseInt(
                firstTermRow[colIndex - 1] + firstTermRow[colIndex]
              );
              term2 = parseInt(
                previousRow[colIndex - 1] + previousRow[colIndex]
              );
            }

            let difference = term1 - term2;

            // if this answer has the same number of digits as the target row:
            let differenceLength = difference.toString().split("").length;
            let procedimientoCurrentRow = newGrid[rowIndex];
            let cleanProcedimientoRows = procedimientoCurrentRow.filter(
              (digit: string) => digit != ""
            );
            let procedimientoRowLength = cleanProcedimientoRows.length;
            if (procedimientoRowLength == differenceLength) {
              //next row
              nextRow = rowIndex;
              nexColumn = colIndex + 1;
            } else {
              nextRow = rowIndex;
              nexColumn = colIndex - 1;
            }
            nextBoxId =
              "grid_row_" + nextRow.toString() + "_col_" + nexColumn.toString();
        }
      }

      nextBox = document.getElementById(nextBoxId);

      if (nextBox) {
        nextBox.focus();
        this.updateRowIndex(nextRow);
        this.updateColIndex(nexColumn);
      } else {
        //if nextBox doesn't exist, it means you're at the last row
        //of the procedimiento grid, next row is for residuo
        nextBoxId = "residuo_box_" + (residuoLength - 1);
        nextBox = document.getElementById(nextBoxId);
        if (nextBox) {
          nextBox.focus();
          this.updateCurrentGrid("residuo");
          this.updateResiduoIndex(residuoLength - 1);
        }
      }
    }

    this.setState({ procedimientoGrid: newGrid });
  };

  generateInferiorRadicalView = () => {
    const { procedimientoGrid, targetProcedimientoGrid, counter } = this.state;
    const { selectedGuia } = this.context;
    const { ejercicioContainer, inputBox, colStyle } = this.getStyles();

    let ejercicio = selectedGuia.ejercicios[counter];
    const { solucion, operador, columnas, residuo } = ejercicio;

    let dividendo: string[] = columnas[0].toString().split(""); //54 becomes ['5', '4']
    let divisorNum = columnas[1];
    let divisor = columnas[1].toString().split("");
    let cociente = solucion;
    let divisorLength = divisor.length;
    let cocienteArray = cociente.toString().split("");
    let cocienteLength = cocienteArray.length;
    let cocienteLastIndex = cocienteLength - 1;
    let dividendoLength = dividendo.length;
    let startingProcedimientoGrid: any[][] = [];
    let residuoGrid: JSX.Element[] = [];
    let residuoLength = residuo.toString().split("").length;
    let empty = <div style={colStyle}></div>;

    // adds the initial boxes to the calculation grid, below the label (full/empty)
    // 2 rows for each digit in the cociente plus 1
    // i => rows
    // a => columns
    let numOfRows = cocienteLength * 2 - 1;
    for (let i = 0; i < numOfRows; i++) {
      let row: any[] = [];

      // workaround, this applies only to the first row
      // it should apply to all rows
      // not all columns in the first row should have a cell
      // sometimes it should have an empty space
      // for example:
      // 1 1 / 4
      //   []|[]
      // --------

      // but in the case of
      // 2 3 / 2
      // []  |[]
      // [][]
      // [][]
      // -------

      //It depends on whether one should lower the next number
      let item;
      if (i == 0) {
        let cocienteFirstNum = parseInt(cocienteArray[0]);
        let cocientePorDivisor = cocienteFirstNum * divisorNum;
        let cocientePorDivisorLength = cocientePorDivisor.toString().split("")
          .length;

        let lengthDifference = dividendoLength - cocientePorDivisorLength;

        // one box for each digit in cociente[i] * divisor
        // if the target procedimiento grid has more than one row

        // so the empty space should be the last column from the right

        // but if the grid has only one row,
        // that means that there is no need to pull the next number
        // so the empty space should be on the first column from the left
        // debugger;
        if (targetProcedimientoGrid.length > 1) {
          let dividendoSubstr: string[] = [];

          // debugger;

          for (let e = 0; e < dividendoLength; e++) {
            let digit = dividendo[e];
            dividendoSubstr.push(digit);
            let digitNum = parseInt(dividendoSubstr.join(""));
            let divisorNum = parseInt(divisor.join(""));
            if (digitNum / divisorNum >= 1) {
              break;
            }
          }

          if (dividendoSubstr.length > cocientePorDivisorLength) {
            row.push(empty);
            lengthDifference = dividendoLength - dividendoSubstr.length;
          }

          for (let a = 0; a < cocientePorDivisorLength; a++) {
            let rowIndex = i;
            let colIndex = a;
            let currentCol;
            let currentRow = procedimientoGrid[rowIndex];
            if (currentRow) {
              currentCol = currentRow[colIndex];
            } else {
              currentCol = "";
            }

            let full = (
              <input
                id={
                  "grid_row_" +
                  rowIndex.toString() +
                  "_col_" +
                  colIndex.toString()
                }
                key={
                  "grid_row_" +
                  rowIndex.toString() +
                  "_col_" +
                  colIndex.toString()
                }
                type="text"
                onChange={(e) =>
                  this.updateProcedimientoGrid(
                    e.target.value,
                    rowIndex,
                    colIndex
                  )
                }
                value={currentCol}
                style={inputBox}
                onFocus={(e) => {
                  this.handleFocus(e, rowIndex, colIndex);
                }}
                autoComplete="off"
              ></input>
            );
            row.push(full);
          }

          // debugger;

          //one box for each digit in cociente[i] * divisor
          for (let a = 0; a < lengthDifference; a++) {
            let rowIndex = i;
            let colIndex = a;
            let currentCol;
            let currentRow = procedimientoGrid[rowIndex];
            if (currentRow) {
              currentCol = currentRow[colIndex];
            } else {
              currentCol = "";
            }

            let full = <div></div>;
            row.push(full);
          }
        } else {
          // same as in the previous block, but inverted
          // adding empty space first and cells later

          //one box for each digit in cociente[i] * divisor
          for (let a = 0; a < lengthDifference; a++) {
            let rowIndex = i;
            let colIndex = a;
            let currentCol;
            let currentRow = procedimientoGrid[rowIndex];
            if (currentRow) {
              currentCol = currentRow[colIndex];
            } else {
              currentCol = "";
            }

            let full = <div></div>;
            row.push(full);
          }

          // starting at lengthDifference instead of zero
          // to maintain the proper column id for the cursor
          for (let a = lengthDifference; a < dividendoLength; a++) {
            let rowIndex = i;
            let colIndex = a;
            let currentCol;
            let currentRow = procedimientoGrid[rowIndex];
            if (currentRow) {
              currentCol = currentRow[colIndex];
            } else {
              currentCol = "";
            }

            let full = (
              <input
                id={
                  "grid_row_" +
                  rowIndex.toString() +
                  "_col_" +
                  colIndex.toString()
                }
                key={
                  "grid_row_" +
                  rowIndex.toString() +
                  "_col_" +
                  colIndex.toString()
                }
                type="text"
                onChange={(e) =>
                  this.updateProcedimientoGrid(
                    e.target.value,
                    rowIndex,
                    colIndex
                  )
                }
                value={currentCol}
                style={inputBox}
                onFocus={(e) => {
                  this.handleFocus(e, rowIndex, colIndex);
                }}
                autoComplete="off"
              ></input>
            );
            row.push(full);
          }
        }
      } else if (i == numOfRows - 1) {
        // index of last row is number of rows - 1
        let cocienteLastNum = parseInt(cocienteArray[cocienteArray.length - 1]);
        let cocientePorDivisor = cocienteLastNum * divisorNum;
        let cocientePorDivisorLength = cocientePorDivisor.toString().split("")
          .length;
        let lengthDifference = dividendoLength - cocientePorDivisorLength;

        // one box for each digit in cociente[lastIndex] * divisor
        // adding empty space first and cells later

        //one box for each digit in cociente[i] * divisor
        for (let a = 0; a < lengthDifference; a++) {
          let rowIndex = i;
          let colIndex = a;
          let currentCol;
          let currentRow = procedimientoGrid[rowIndex];
          if (currentRow) {
            currentCol = currentRow[colIndex];
          } else {
            currentCol = "";
          }

          let full = <div></div>;
          row.push(full);
        }

        // starting at lengthDifference instead of zero
        // to maintain the proper column id for the cursor
        for (let a = lengthDifference; a < dividendoLength; a++) {
          let rowIndex = i;
          let colIndex = a;
          let currentCol;
          let currentRow = procedimientoGrid[rowIndex];
          if (currentRow) {
            currentCol = currentRow[colIndex];
          } else {
            currentCol = "";
          }

          let full = (
            <input
              id={
                "grid_row_" +
                rowIndex.toString() +
                "_col_" +
                colIndex.toString()
              }
              key={
                "grid_row_" +
                rowIndex.toString() +
                "_col_" +
                colIndex.toString()
              }
              type="text"
              onChange={(e) =>
                this.updateProcedimientoGrid(e.target.value, rowIndex, colIndex)
              }
              value={currentCol}
              style={inputBox}
              onFocus={(e) => {
                this.handleFocus(e, rowIndex, colIndex);
              }}
              autoComplete="off"
            ></input>
          );
          row.push(full);
        }
      } else {
        //one box for each digit in the dividendo

        let targetRow = targetProcedimientoGrid[i];
        if (!targetRow) {
          return;
        }

        let targetRowLength = targetRow.length;

        let targetDividendoDifference = dividendoLength - targetRowLength;

        for (let e = 0; e < targetDividendoDifference; e++) {
          let empty = <div style={colStyle}></div>;

          row.push(empty);
        }

        for (let a = 0 + targetDividendoDifference; a < dividendoLength; a++) {
          let rowIndex = i;
          let colIndex = a;
          let currentCol;
          let currentRow = procedimientoGrid[rowIndex];
          if (currentRow) {
            currentCol = currentRow[colIndex];
          } else {
            currentCol = "";
          }

          let full = (
            <input
              id={
                "grid_row_" +
                rowIndex.toString() +
                "_col_" +
                colIndex.toString()
              }
              key={
                "grid_row_" +
                rowIndex.toString() +
                "_col_" +
                colIndex.toString()
              }
              type="text"
              onChange={(e) =>
                this.updateProcedimientoGrid(e.target.value, rowIndex, colIndex)
              }
              value={currentCol}
              style={inputBox}
              onFocus={(e) => {
                this.handleFocus(e, rowIndex, colIndex);
              }}
              autoComplete="off"
            ></input>
          );

          row.push(full);
        }
      }

      startingProcedimientoGrid.push(row);
    }

    //adding boxes on first row for result
    let cocienteBoxes: JSX.Element[] = this.generateCocienteBoxes();

    for (let i = 0; i <= cocienteLastIndex; i++) {
      startingProcedimientoGrid[0].push(cocienteBoxes[i]);
    }

    //adding boxes for residuo
    let residuoBoxes = this.generateResiduoBoxes();
    for (let i = 0; i < residuoLength; i++) {
      residuoGrid.push(residuoBoxes[i]);
    }

    //adding empty spaces on residuo row
    if (dividendoLength > residuoLength) {
      let differencia = dividendoLength - residuoLength;
      for (let i = 0; i <= differencia - 1; i++) {
        residuoGrid.unshift(empty);
      }
    }

    //add extra row first to match the rows in superior view

    let longestItem;

    if (cocienteBoxes.length > dividendo.length) {
      longestItem = cocienteBoxes;
    } else {
      longestItem = dividendo;
    }

    let grid = (
      <div style={ejercicioContainer}>
        <Grid>
          <Row>
            {dividendo.map((num: any, index: number) => (
              <Col
                style={colStyle}
                xs={this.getColSize(dividendo.length + longestItem.length)}
                md={2}
                key={index}
              >
                {num}
              </Col>
            ))}
            {divisor.map((num: any, index: number) => (
              <Col
                style={this.getColStyle(index)}
                xs={this.getColSize(dividendo.length + longestItem.length)}
                md={2}
                key={index}
              >
                {num}
              </Col>
            ))}
          </Row>
          {startingProcedimientoGrid.map((row: string[], rowIndex: number) => (
            <Row key={"procedimiento_grid_row_" + rowIndex.toString()}>
              {row.map((column: any, colIndex: number) => (
                <Col
                  style={colStyle}
                  xs={this.getColSize(dividendo.length + longestItem.length)}
                  md={2}
                  key={
                    "procedimiento_grid_row_" +
                    rowIndex.toString() +
                    "_col_" +
                    colIndex.toString()
                  }
                >
                  {column}
                </Col>
              ))}
            </Row>
          ))}
          <Row style={{ borderTop: "3px solid #017894" }}>
            {residuoGrid.map((full: any, index: number) => (
              <Col
                style={colStyle}
                xs={this.getColSize(dividendo.length + longestItem.length)}
                md={2}
                key={index}
              >
                {full}
              </Col>
            ))}
          </Row>
        </Grid>
      </div>
    );
    return grid;
  };

  getColSize = (rowLength: number) => {
    let size = Math.floor(12 / rowLength);

    // console.log("col size");
    // console.log(size);
    return size;
  };

  updateResiduoRespuesta = (resp: string, index: number) => {
    console.log("update residuo respuestsa");
    console.log("Index: " + index);

    // making sure there can only be 1 digit per box
    if (resp.length > 1) {
      let lastIndex = resp.length - 1;
      resp = resp[lastIndex];
    }

    let filteredRespuesta = resp.match(/\d/g);

    const { selectedGuia } = this.context;
    const { counter, respuesta, residuoRespuesta } = this.state;
    let solucion = selectedGuia.ejercicios[counter].solucion;
    let residuo = selectedGuia.ejercicios[counter].residuo;
    let solucionLength = solucion.toString().length;
    let newResiduo = residuoRespuesta;

    if (filteredRespuesta) {
      newResiduo[index] = resp;
      let netxBoxId = "residuo_box_" + (index - 1);

      let nextBox = document.getElementById(netxBoxId.toString());
      this.updateResiduoIndex(index - 1);
      if (nextBox) {
        nextBox.focus();
      }
    } else {
      newResiduo[index] = "";
    }

    this.setState({ residuoRespuesta: newResiduo });
    console.log("residuoRespuesta");
    console.log(newResiduo);

    let escapedRespuesta = this.clearRespuesta(respuesta);
    let escapedResiduo = this.clearRespuesta(newResiduo);
    let newRLength = escapedRespuesta.length;
    let newResiduoLength = escapedResiduo.length;
    let residuoLength = residuo.toString().split("").length;

    if (newRLength === solucionLength && newResiduoLength === residuoLength) {
      this.evaluateDivisionRespuesta(respuesta, newResiduo);
    }
  };

  generateResiduoBoxes = () => {
    const { selectedGuia } = this.context;
    const { counter, residuoRespuesta } = this.state;
    const { ejercicioContainer, inputBox } = this.getStyles();

    let residuo = selectedGuia.ejercicios[counter].residuo;

    let boxes: JSX.Element[] = [];
    let residuoString = residuo.toString();
    let escapedResiduo = residuoString.replace(/[^0-9]/g, "");
    for (let i = 0; i < escapedResiduo.length; i++) {
      let box = (
        <input
          id={"residuo_box_" + i.toString()}
          key={"residuo_box_" + i.toString()}
          onChange={(e) => this.updateResiduoRespuesta(e.target.value, i)}
          type="text"
          value={residuoRespuesta[i]}
          style={inputBox}
          onFocus={(e) => {
            this.handleFocusResiduo(e, i);
          }}
          autoComplete="off"
        ></input>
      );
      boxes.push(box);
    }
    return boxes;
  };

  updateDimensions = () => {
    const width = window.innerWidth;
    this.setState({ width: width });
    console.log("width: ", width);
  };

  switchScreen = (screen: string) => {
    console.log("switch to: ", screen);
    history.push(screen);
  };

  selectFirstBox = () => {
    const { counter } = this.state;
    const { selectedGuia } = this.context;
    const ejercicio: Ejercicio = selectedGuia.ejercicios[counter];
    let solucion = ejercicio.solucion;
    let solucionLength = solucion.toString().length;

    window.setTimeout(() => {
      let nextBoxId = "respuesta_box_0";
      let nextBox = document.getElementById(nextBoxId);

      if (nextBox) {
        nextBox.focus();
      } else {
        nextBoxId = (solucionLength - 1).toString();
        nextBox = document.getElementById(nextBoxId);
        if (nextBox) {
          nextBox.focus();
        }
      }
    }, 500);
  };

  getColStyle = (index: number) => {
    const { radicalTipo } = this.state;
    let borderTop = "";
    let borderLeft = "";
    let padding = 10;
    let borderBottom = "";

    if (radicalTipo == "superior") {
      borderTop = "3px solid #017894";
    }

    if (radicalTipo == "inferior") {
      borderBottom = "3px solid #017894";
    }

    if (index == 0) {
      borderLeft = "3px solid #017894";
    }

    let style = {
      borderTop,
      borderLeft,
      borderBottom,
      padding,
    };

    return style;
  };

  getStyles = () => {
    const { width, counter } = this.state;
    let currentStyles;

    if (isMobile || width < 500) {
      currentStyles = stylesResponsive;
    } else {
      currentStyles = styles;
    }

    // if (width > 500) {
    //   currentStyles = styles;
    // } else {
    //   currentStyles = stylesResponsive;

    //   let longestItem;

    //   const { selectedGuia } = this.context;

    //   let selectedEjercicio = selectedGuia.ejercicios[counter];
    //   if (!selectedEjercicio) {
    //     return;
    //   }
    //   let solucion = selectedEjercicio.solucion;
    //   let dividendo = selectedEjercicio.columnas[0];
    //   let divisor = selectedEjercicio.columnas[1];

    //   let sum = solucion.toString().length + dividendo.toString().length;

    //   // debugger;
    //   if (sum > 4) {
    //     let inputB = {
    //       width: 40,
    //       color: "#017894",
    //       minWidth: 50,
    //     };

    //     currentStyles.inputBox = inputB;
    //   }
    // }

    return currentStyles;
  };

  generateTeclado = () => {
    const {
      currentGrid,
      respuestaIndex,
      rowIndex,
      colIndex,
      residuoIndex,
    } = this.state;
    let teclado;

    switch (currentGrid) {
      case "cociente":
        teclado = (
          <TecladoRespuesta
            respuestaIndex={respuestaIndex}
            updateRespuesta={this.divisionCocienteValueChanged}
          ></TecladoRespuesta>
        );
        break;
      case "procedimiento":
        teclado = (
          <TecladoProcedimiento
            updateProcedimientoGrid={this.updateProcedimientoGrid}
            rowIndex={rowIndex}
            colIndex={colIndex}
          ></TecladoProcedimiento>
        );
        break;

      case "residuo":
        teclado = (
          <TecladoRespuesta
            respuestaIndex={residuoIndex}
            updateRespuesta={this.updateResiduoRespuesta}
          ></TecladoRespuesta>
        );
        break;
      default:
        teclado = (
          <TecladoRespuesta
            respuestaIndex={respuestaIndex}
            updateRespuesta={this.divisionCocienteValueChanged}
          ></TecladoRespuesta>
        );
        break;
    }

    return teclado;
  };

  generateBody = () => {
    const { selectedGuia } = this.context;
    const { counter } = this.state;
    let ejercicios = selectedGuia.ejercicios;
    let ejerciciosLength = ejercicios.length;
    let body;
    if (counter < ejerciciosLength) {
      body = this.generateEjercicio();
    } else {
      body = this.generateResultados();
    }
    return body;
  };

  generateResultados = () => {
    const { currentUser, tiemposRespuesta } = this.props;
    const { errores, erroresPorEjercicio, horaComienzo } = this.state;
    const { selectedGuia } = this.context;
    let ejercicios = selectedGuia.ejercicios;

    let frame = (
      <Resultados
        currentUser={currentUser}
        errores={errores}
        tiempos={tiemposRespuesta}
        erroresPorEjercicio={erroresPorEjercicio}
        ejercicios={ejercicios}
        horaComienzo={horaComienzo}
        guia={selectedGuia}
        getUserData={() => console.log("hell oworld")}
      ></Resultados>
    );
    return frame;
  };

  ayuda = () => {
    const { procedimientoGrid, targetProcedimientoGrid } = this.state;

    console.log("targetProcedimientoGrid");
    console.log(targetProcedimientoGrid);

    console.log("procedimientoGrid");
    console.log(procedimientoGrid);
  };

  generateEjercicio = () => {
    const { width, errores, counter } = this.state;
    const { gridContainer, gridItemContainer } = this.getStyles();
    const { tiempo } = this.props;
    let body;

    if (width > 500) {
      body = (
        <div style={gridContainer}>
          <Grid fluid>
            <Row>
              <Col xs={3} md={3}>
                <h2>{tiempo}</h2>
                <h2>Ej. # {counter + 1}</h2>
              </Col>
              <Col xs={6} md={6}>
                {this.generateGrid()}
              </Col>
              <Col xs={3} md={3}>
                {this.generateTeclado()}
              </Col>
            </Row>
          </Grid>
          <Button
            style={{ marginTop: 50 }}
            onClick={() => this.switchScreen("/usuario")}
          >
            Regresar
          </Button>
          {/* <Button style={{ marginBottom: 50 }} onClick={() => this.next()}>
            Siguiente
          </Button> */}
          <Button
            style={{
              marginBottom: 50,
              backgroundColor: "#d9534f",
              color: "white",
            }}
            onClick={() => this.ayuda()}
          >
            Ayuda
          </Button>
        </div>
      );
    } else {
      body = (
        <div style={{ marginTop: 75 }}>
          <Grid fluid>
            <Row>
              <div style={gridItemContainer}>
                <h2>{tiempo}</h2>
                <h2>Ej. # {counter + 1}</h2>
              </div>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                {this.generateGrid()}
              </Col>
            </Row>
            <Row>
              <div style={gridItemContainer}>{this.generateTeclado()}</div>
            </Row>
          </Grid>
          <Button
            style={{ marginTop: 50 }}
            onClick={() => this.switchScreen("/usuario")}
          >
            Regresar
          </Button>
          {/* <Button style={{ marginBottom: 50 }} onClick={() => this.next()}>
            Siguiente
          </Button> */}
        </div>
      );
    }

    return body;
  };

  generateSwitch = () => {
    const { container, switchContainer } = this.getStyles();
    const { radicalTipo } = this.state;
    let body = (
      <div style={{ marginTop: 20 }}>
        <div style={switchContainer}>
          <h2>
            <span>Radical: </span>
          </h2>
        </div>
        <h2>{radicalTipo}</h2>
      </div>
    );

    return body;
  };

  render() {
    const { container } = this.getStyles();
    return (
      <div style={container}>
        {/* {this.generateSwitch()} */}
        {this.generateBody()}
      </div>
    );
  }
}

const styles = {
  container: {
    height: "100vh",
  } as React.CSSProperties,
  gridContainer: {
    marginTop: 100,
  } as React.CSSProperties,
  ejercicioContainer: {
    color: "#017894",
    backgroundColor: "#FFFFE0",
    width: 400,
    fontSize: 42,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: 20,
    borderRadius: 20,
  } as React.CSSProperties,
  gridItemContainer: {
    margin: 0,
  } as React.CSSProperties,
  switchContainer: {
    textAlign: "center",
  } as React.CSSProperties,
  inputBox: {
    width: 50,
    color: "#017894",
    minWidth: 50,
  },
  colStyle: {
    width: 50,
    padding: 10,
  },
};

const stylesResponsive = {
  container: {
    height: "105vh",
  } as React.CSSProperties,
  ejercicioContainer: {
    color: "#017894",
    backgroundColor: "#FFFFE0",
    fontSize: 32,
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: 20,
    borderRadius: 20,
    // width: '38vh',
  } as React.CSSProperties,
  gridContainer: {
    marginTop: 0,
  } as React.CSSProperties,
  gridItemContainer: {
    margin: "auto",
  } as React.CSSProperties,
  switchContainer: {
    textAlign: "left",
  } as React.CSSProperties,
  inputBox: {
    width: 60,
    color: "#017894",
    minWidth: 60,
  },
  colStyle: {
    width: 60,
    padding: 10,
  },
};

export default Division;

import React, { useState, useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Tecla from "./Tecla";
import { Ejercicio } from '../../data-models/ejercicio';

type Props = {
    updateProcedimientoGrid: any,
    rowIndex: number,
    colIndex: number
}

const TecladoProcedimiento = (
    props: Props
) => {

    const {
        updateProcedimientoGrid,
        rowIndex,
        colIndex
    } = props;

    const submit = (num: number) => {
        updateProcedimientoGrid(num.toString(), rowIndex, colIndex)
    }

    return (
        <div>
            <Grid /*style={{ backgroundColor: '#A9CCE3' }}*/>
                <Row>
                    <Col>
                        <Tecla num={1} submit={submit}></Tecla>
                    </Col>
                    <Col>
                        <Tecla num={2} submit={submit}></Tecla>
                    </Col>
                    <Col>
                        <Tecla num={3} submit={submit}></Tecla>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Tecla num={4} submit={submit}></Tecla>
                    </Col>
                    <Col>
                        <Tecla num={5} submit={submit}></Tecla>
                    </Col>
                    <Col>
                        <Tecla num={6} submit={submit}></Tecla>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Tecla num={7} submit={submit}></Tecla>
                    </Col>
                    <Col>
                        <Tecla num={8} submit={submit}></Tecla>
                    </Col>
                    <Col>
                        <Tecla num={9} submit={submit}></Tecla>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{
                            marginTop: 20,
                            marginLeft: 5,
                            marginRight: 5,
                            width: 50,
                            height: 50,
                        }}></div>
                    </Col>
                    <Col>
                        <Tecla num={0} submit={submit}></Tecla>
                    </Col>
                    <Col>
                        <div style={{
                            marginTop: 20,
                            marginLeft: 5,
                            marginRight: 5,
                            width: 50,
                            height: 50,
                        }}></div>
                    </Col>
                </Row>
            </Grid>

        </div>
    );
};

export default TecladoProcedimiento;
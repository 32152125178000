import React, { Component } from 'react';
import api from '../api/AxiosAPI';
import Auth from '../api/Auth';
import { Menu, Button } from 'semantic-ui-react'
import { history } from './history';
import { User } from '../data-models/user';
import { Asignatura } from '../data-models/asignatura';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AgregarItem from './AgregarItem';
import LoadingOverlay from 'react-loading-overlay-ts';

interface IProps {
}

interface IState {
    status_message: string,
    selectedScreen: string,
    showAddItem: boolean,
    asignaturas: Asignatura[],
    newAsignaturaDescripcion: string,
    loading: boolean
}

class Asignaturas extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            status_message: '',
            selectedScreen: '',
            showAddItem: false,
            asignaturas: [],
            newAsignaturaDescripcion: '',
            loading: false
        };
    }

    componentDidMount() {
        this.getAllAsignaturas();
    }

    getAllAsignaturas() {
        console.log('getting Asignaturas');
        let fetchAsignaturas: Asignatura[];
        fetchAsignaturas = [];
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'asignaturas/GetAllAsignaturas',
            headers: {
                Authorization: Auth.getToken(),
            }
        }).then((response) => {
            this.setState({ loading: false });
            console.log('response data');
            console.log(response.data);
            for (var i in response.data) {
                let asignatura = response.data[i];
                fetchAsignaturas.push({
                    id: asignatura.id,
                    nombre: asignatura.nombre,
                    descripcion: asignatura.descripcion,
                })
            }
            this.setState({
                asignaturas: fetchAsignaturas,
                status_message: 'Data about all asignaturas received successfully.'
            })
        }).catch((error) => {
            this.setState({ loading: false });
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up');
            }
        });
    };

    addAsignatura = (asignatura: Asignatura) => {
        const { nombre, descripcion } = asignatura;
        console.log('Asignatura por agregar');
        console.log(asignatura);
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'asignaturas/SaveAsignatura?nombre=' + nombre,
            data: { descripcion: descripcion },
            headers: { Authorization: Auth.getToken() }
        }).then((response) => {
            this.setState({ loading: false });
            console.log('Asignatura saved successfully')
            this.setState({ status_message: 'Data about the user saved successfully.' });
            this.getAllAsignaturas();
            this.setState({ showAddItem: false });
        }).catch((error) => {
            this.setState({ loading: false });
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
            this.setState({ status_message: 'Error saving data about the user.' });
        });
    }

    addNewAsignatura = () => {
        const { newAsignaturaDescripcion } = this.state;
        let newAsignatura: Asignatura;
        newAsignatura = {
            nombre: this.generateNombre(newAsignaturaDescripcion),
            descripcion: newAsignaturaDescripcion
        }
        this.addAsignatura(newAsignatura);
    }

    generateNombre = (descripcion: string) => {
        let nombre = descripcion.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        nombre = nombre.toLowerCase().replace(/ /g, "_");
        return nombre;
    }

    deleteAsignatura(asignatura: Asignatura) {
        const { id, nombre, descripcion } = asignatura;
        this.setState({ loading: true });
        api({
            method: 'delete',
            url: '/asignaturas/DeleteAsignatura?nombre=' + nombre,
            headers: { Authorization: Auth.getToken() }
        }).then((response) => {
            this.setState({ loading: false });
            this.setState({
                status_message: 'Data about the Asignatura deleted successfully.'
            });
            this.getAllAsignaturas();
        }).catch((error) => {
            this.setState({ loading: false });
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    }

    generateAgregarItem = () => {
        const { showAddItem } = this.state;
        let a;
        if (showAddItem) {
            a = (
                <Grid style={{ marginTop: 20 }} fluid>
                    <Row>
                        <Col xs={6} md={3}>
                            <form className="ui form">
                                <div className="one fields">
                                    <div className="field">
                                        <input
                                            onChange={e => this.setState({ newAsignaturaDescripcion: e.target.value })}
                                            type="text"
                                            placeholder="descripcion">
                                        </input>
                                    </div>
                                </div>
                            </form>
                        </Col>
                        <Col xs={6} md={3}>
                            <Button className="ui primary button" onClick={() => this.addNewAsignatura()}>Guardar</Button>
                        </Col>
                    </Row>
                </Grid>
            );
        }

        return a;
    }

    render() {
        const { asignaturas, loading } = this.state;
        const agregarItem = this.generateAgregarItem();
        return (
            <LoadingOverlay
                active={loading}
                spinner
                text='Cargando...'
            >
                <div>
                    <Button onClick={() => this.setState({ showAddItem: !this.state.showAddItem })} className="ui primary button">
                        Agregar Asignatura
                    </Button>
                    {agregarItem}
                    <Grid style={{ marginTop: 20 }} fluid>
                        {asignaturas.map(asignatura => (
                            <Row key={asignatura.id}>
                                <Col xs={6} md={7}>
                                    {asignatura.descripcion}
                                </Col>
                                <Col xs={6} md={3}>
                                    <Button className="ui red" onClick={() => this.deleteAsignatura(asignatura)}>Eliminar</Button>
                                </Col>
                            </Row>
                        ))}
                    </Grid>
                </div>
            </LoadingOverlay>

        );
    }
}

export default Asignaturas;
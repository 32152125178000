import React, { Component } from "react";
import api from "../api/AxiosAPI";
import Auth from "../api/Auth";
import { Menu, Button } from "semantic-ui-react";
import Usuarios from "./Usuarios/Usuarios";
import Grupos from "./Grupos";
import Unidades from "./Unidades";
import Niveles from "./Niveles";
import Subniveles from "./Subniveles";
import Asignaturas from "./Asignaturas";
import InformesResultados from "./InformesResultados";
import wiserContext from "../providers/wiserContext";
import { history } from "./history";
import { User, defaultUser } from "../data-models/user";
import InformesSituacion from "./InformesSituacion";
import LoadingOverlay from "react-loading-overlay-ts";
import Asignaciones from "./Asignaciones";
import SemaforoParams from "./SemaforoParams";
import ReportesMasivos from "./ReportesMasivos";

class PortalPedagogo extends Component {
  static contextType = wiserContext;
  state = {
    status_message: "",
    user_location: "",
    user_interests: "",
    activeItem: "directorio",
    subMenuActiveItem: "usuarios",
    currentUserEmail: Auth.currentUser().emails[0],
    loading: false,
    currentUser: defaultUser,
    grupo: "",
  };

  componentDidMount() {
    this.getUserData();
  }

  getUserData() {
    // console.log('getting current user data');
    const { currentUserEmail } = this.context;
    this.setState({ loading: true });
    api({
      method: "get",
      url: "usuarios/GetUser?email=" + currentUserEmail,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        if (response.data[0]) {
          this.context.setCurrentUser(response.data);
          this.setState({ currentUser: response.data });

          let grupo = response.data[0].grupo;
          this.evaluateGrupo(grupo);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  }

  evaluateGrupo = (grupo: string) => {
    switch (grupo) {
      case "usuario_activo":
        history.push("/usuario");
        break;
      case "pedagogo":
        // console.log('pedagogo');
        this.setState({ grupo: "pedagogo" });
        break;
      default:
        console.log("pedagogo");
        break;
    }
  };

  handleItemClick = (e: any, name: any) => {
    this.setState({ activeItem: name.name });
  };

  handleSubMenuItemClick = (e: any, name: any) => {
    this.setState({ subMenuActiveItem: name.name });
  };

  switchScreen = (screen: string) => {
    this.setState({ subMenuActiveItem: screen });
  };

  generateSubMenuDirectorio = () => {
    const { subMenuActiveItem } = this.state;
    let submenu = (
      <Menu>
        <Menu.Item
          name="usuarios"
          active={subMenuActiveItem === "usuarios"}
          onClick={this.handleSubMenuItemClick}
        >
          Usuarios
        </Menu.Item>

        <Menu.Item
          name="grupos"
          active={subMenuActiveItem === "grupos"}
          onClick={this.handleSubMenuItemClick}
        >
          Grupos
        </Menu.Item>

        <Menu.Item
          name="unidades"
          active={subMenuActiveItem === "unidades"}
          onClick={this.handleSubMenuItemClick}
        >
          Unidades Organizativas
        </Menu.Item>
      </Menu>
    );

    return submenu;
  };

  generateSubMenuPatrones = () => {
    const { subMenuActiveItem } = this.state;
    let submenu = (
      <Menu>
        <Menu.Item
          name="asignaturas"
          active={subMenuActiveItem === "asignaturas"}
          onClick={this.handleSubMenuItemClick}
        >
          Asignaturas
        </Menu.Item>

        <Menu.Item
          name="nivel"
          active={subMenuActiveItem === "nivel"}
          onClick={this.handleSubMenuItemClick}
        >
          Nivel
        </Menu.Item>

        <Menu.Item
          name="subnivel"
          active={subMenuActiveItem === "subnivel"}
          onClick={this.handleSubMenuItemClick}
        >
          Subnivel
        </Menu.Item>
      </Menu>
    );

    return submenu;
  };

  generateSubMenuInformes = () => {
    const { subMenuActiveItem } = this.state;
    let submenu = (
      <Menu>
        <Menu.Item
          name="situacion"
          active={subMenuActiveItem === "situacion"}
          onClick={this.handleSubMenuItemClick}
        >
          De Situación
        </Menu.Item>

        <Menu.Item
          name="resultados"
          active={subMenuActiveItem === "resultados"}
          onClick={this.handleSubMenuItemClick}
        >
          De Resultados
        </Menu.Item>

        <Menu.Item
          name="masivos"
          active={subMenuActiveItem === "masivos"}
          onClick={this.handleSubMenuItemClick}
        >
          Masivos
        </Menu.Item>
      </Menu>
    );

    return submenu;
  };

  generateSubMenuGuias = () => {
    const { subMenuActiveItem } = this.state;
    let submenu = (
      <Menu>
        <Menu.Item
          name="asignaciones"
          active={subMenuActiveItem === "asignaciones"}
          onClick={this.handleSubMenuItemClick}
        >
          Asignar
        </Menu.Item>
        <Menu.Item
          name="semaforo"
          active={subMenuActiveItem === "semaforo"}
          onClick={this.handleSubMenuItemClick}
        >
          Semaforo
        </Menu.Item>
      </Menu>
    );

    return submenu;
  };

  generateSubMenu = () => {
    const { activeItem } = this.state;
    let tabToDisplay;
    switch (activeItem) {
      case "directorio":
        tabToDisplay = this.generateSubMenuDirectorio();
        break;
      case "patrones":
        tabToDisplay = this.generateSubMenuPatrones();
        break;
      case "guias":
        tabToDisplay = this.generateSubMenuGuias();
        break;
      case "informes":
        tabToDisplay = this.generateSubMenuInformes();
        break;
      default:
        tabToDisplay = this.generateSubMenuDirectorio();
        break;
    }

    return tabToDisplay;
  };

  generateBodyUsuarios = () => {
    let body = <Usuarios></Usuarios>;
    return body;
  };

  generateBodyGrupos = () => {
    let body = <Grupos></Grupos>;
    return body;
  };

  generateBodyUnidades = () => {
    let body = <Unidades></Unidades>;
    return body;
  };

  generateBodyAsignaturas = () => {
    let body = <Asignaturas></Asignaturas>;
    return body;
  };

  generateBodyNiveles = () => {
    let body = <Niveles></Niveles>;
    return body;
  };

  generateBodySubniveles = () => {
    let body = <Subniveles></Subniveles>;
    return body;
  };

  generateBodyResultados = () => {
    let body = <InformesResultados></InformesResultados>;
    return body;
  };

  generateBodySituacion = () => {
    let body = <InformesSituacion></InformesSituacion>;
    return body;
  };

  generateBodyAsignaciones = () => {
    let body = <Asignaciones></Asignaciones>;
    return body;
  };

  generateBodySemaforo = () => {
    let body = <SemaforoParams></SemaforoParams>;
    return body;
  };

  generateBodyMasivos = () => {
    let body = <ReportesMasivos></ReportesMasivos>;
    return body;
  };

  generateBody = () => {
    //keyword
    const { subMenuActiveItem, currentUser, grupo } = this.state;
    let body;
    if (currentUser.nombre != "" && grupo == "pedagogo") {
      switch (subMenuActiveItem) {
        case "usuarios":
          body = this.generateBodyUsuarios();
          break;
        case "grupos":
          body = this.generateBodyGrupos();
          break;
        case "unidades":
          body = this.generateBodyUnidades();
          break;
        case "asignaturas":
          body = this.generateBodyAsignaturas();
          break;
        case "nivel":
          body = this.generateBodyNiveles();
          break;
        case "subnivel":
          body = this.generateBodySubniveles();
          break;
        case "asignaciones":
          body = this.generateBodyAsignaciones();
          break;
        case "resultados":
          body = this.generateBodyResultados();
          break;
        case "situacion":
          body = this.generateBodySituacion();
          break;
        case "masivos":
          body = this.generateBodyMasivos();
          break;
        case "semaforo":
          body = this.generateBodySemaforo();
          break;
        default:
          body = this.generateBodyUsuarios();
          break;
      }
    }

    return body;
  };

  logout = () => {
    history.push("/");
    Auth.logout();
  };

  render() {
    const { activeItem, loading } = this.state;
    const subMenu = this.generateSubMenu();
    const body = this.generateBody();
    const { container } = styles;
    return (
      <LoadingOverlay active={loading} spinner text="Cargando...">
        <div style={container}>
          <Menu>
            <Menu.Item
              name="directorio"
              active={activeItem === "directorio"}
              onClick={this.handleItemClick}
            >
              Directorio
            </Menu.Item>

            <Menu.Item
              name="patrones"
              active={activeItem === "patrones"}
              onClick={this.handleItemClick}
            >
              Patrones
            </Menu.Item>

            <Menu.Item
              name="guias"
              active={activeItem === "guias"}
              onClick={this.handleItemClick}
            >
              Guías
            </Menu.Item>

            <Menu.Item
              name="informes"
              active={activeItem === "informes"}
              onClick={this.handleItemClick}
            >
              Informes
            </Menu.Item>
            <Button onClick={() => this.logout()}>Salir</Button>
            <div style={{ color: "red" }}>Test 2</div>
          </Menu>
          {subMenu}
          {body}
        </div>
      </LoadingOverlay>
    );
  }
}

const styles = {
  container: {
    margin: 20,
  },
};

export default PortalPedagogo;

import React, { useState, useEffect } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import api from "../../api/AxiosAPI";
import Auth from "../../api/Auth";
import { Ejercicio } from "../../data-models/ejercicio";
import { User } from "../../data-models/user";
import { Guia } from "../../data-models/guia";
import LoadingOverlay from "react-loading-overlay-ts";
import { Button } from "semantic-ui-react";
import { history } from "../history";
import { Resultado, ResultadoEjercicio } from "../../data-models/resultado";

const Resultados = (props: {
  tiempos: number[];
  errores: number;
  erroresPorEjercicio: number[];
  ejercicios: Ejercicio[];
  horaComienzo: string;
  guia: Guia;
  currentUser: User;
  getUserData: any;
}) => {
  const { tiempos, errores } = props;

  const [loading, setLoading] = useState(false);
  const [semaforoColor, setSemaforoColor] = useState("white");
  const [mensajeAmarillo, setMensajeAmarillo] = useState("");
  const [mensajeRojo, setMensajeRojo] = useState("");
  const [mensajeVerde, setMensajeVerde] = useState("");

  const [desviacionEstandarAlto, setDesviacionEstandarAlto] = useState("");
  const [desviacionEstandarBajo, setDesviacionEstandarBajo] = useState("");

  const [porcentajeErroresAlto, setPorcentajeErroresAlto] = useState("");
  const [porcentajeErroresBajo, setPorcentajeErroresBajo] = useState("");

  const [tiempoResolucionAlto, setTiempoResolucionAlto] = useState("");
  const [tiempoResolucionBajo, setTiempoResolucionBajo] = useState("");

  useEffect(() => {
    getAllSemaforoParams();
  }, []);

  const setEndTime = () => {
    var time = new Date();
    let timeString = time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    // console.log("fin: ", timeString);
    // this.setState({ endTime: timeString });
    return timeString;
  };

  const getAllSemaforoParams = () => {
    console.log("getting semaforo params");
    setLoading(true);
    api({
      method: "get",
      url: "/semaforo/GetSemaforoParams",
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        setLoading(false);
        console.log("response data");
        console.log(response.data);
        let params = response.data[0];

        const {
          desviacion_estandar_alto,
          desviacion_estandar_bajo,
          porcentaje_errores_alto,
          porcentaje_errores_bajo,
          tiempo_resolucion_alto,
          tiempo_resolucion_bajo,
          verde_mensaje,
          rojo_mensaje,
          amarillo_mensaje,
        } = params;

        setMensajeAmarillo(amarillo_mensaje);
        setMensajeRojo(rojo_mensaje);
        setMensajeVerde(verde_mensaje);
        setDesviacionEstandarAlto(desviacion_estandar_alto);
        setDesviacionEstandarBajo(desviacion_estandar_bajo);
        setPorcentajeErroresAlto(porcentaje_errores_alto);
        setPorcentajeErroresBajo(porcentaje_errores_bajo);
        setTiempoResolucionAlto(tiempo_resolucion_alto);
        setTiempoResolucionBajo(tiempo_resolucion_bajo);

        saveResultados(
          tiempo_resolucion_alto,
          tiempo_resolucion_bajo,
          porcentaje_errores_alto,
          porcentaje_errores_bajo,
          desviacion_estandar_alto,
          desviacion_estandar_bajo
        );
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  const calcularDesviacion = () => {
    let promedio = calcularPromedio(tiempos);
    let tiempoMenosPromedio: number[] = [];
    let tiempoMenosPromedioCuadrado: number[] = [];
    let sumatoriaTiempoMenosPromedioCuadrado = 0;
    let n = tiempos.length;
    let desviacion;

    for (let i = 0; i < n; i++) {
      let tiempo = tiempos[i];
      let xi = tiempo - promedio;
      tiempoMenosPromedio.push(xi);
    }

    for (let t = 0; t < n; t++) {
      let xi_x = tiempoMenosPromedio[t];
      let xi_x_square = xi_x * xi_x;
      tiempoMenosPromedioCuadrado.push(xi_x_square);
    }

    for (let a = 0; a < n; a++) {
      let tmpc = tiempoMenosPromedioCuadrado[a];
      sumatoriaTiempoMenosPromedioCuadrado =
        sumatoriaTiempoMenosPromedioCuadrado + tmpc;
    }

    desviacion = Math.sqrt(sumatoriaTiempoMenosPromedioCuadrado / n);
    return Number(desviacion.toFixed(1));
  };

  const formatEjercicios = () => {
    const { ejercicios, tiempos, erroresPorEjercicio } = props;
    let formattedEjercicios: ResultadoEjercicio[] = [];
    for (let i = 0; i < ejercicios.length; i++) {
      let tiempo = tiempos[i];
      let ejercicio = ejercicios[i];
      let errores = erroresPorEjercicio[i];
      let formattedEj: ResultadoEjercicio = {
        id: ejercicio.id,
        label:
          ejercicio.columnas[0] + ejercicio.operador + ejercicio.columnas[1],
        tiempoResolucion: Number(tiempo.toFixed(1)),
        errores: errores,
      };
      formattedEjercicios.push(formattedEj);
    }
    return formattedEjercicios;
  };

  const updateGuia = () => {
    const { guia } = props;

    let updatedGuia = guia;

    updatedGuia.fechaRealizada = new Date();
    updatedGuia.fechaRealizadaTimestamp = new Date().getTime();
    updatedGuia.resuelta = true;

    setLoading(true);
    api({
      method: "post",
      url: "/guias/SaveGuia",
      data: updatedGuia,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        setLoading(false);
        console.log("Guia saved successfully");
        alert("cambios guardados con exito");
        // console.log(response);
        // props.getUserData();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  const saveUserData = (user: User) => {
    setLoading(true);
    api({
      method: "put",
      url: "/usuarios/SaveUser",
      data: user,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        setLoading(false);
        // console.log('user saved successfully')
        // console.log(response);
        // props.getUserData();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  const getGuiaIdLocalStorage = () => {
    const { guia } = props;
    const { codigo, id } = guia;
    const guiaId = codigo + "_" + id;

    return guiaId;
  };

  const saveResultados = (
    tiempoResolucionAlto: string,
    tiempoResolucionBajo: string,
    porcentajeErroresAlto: string,
    porcentajeErroresBajo: string,
    desviacionEstandarAlto: string,
    desviacionEstandarBajo: string
  ) => {
    const { tiempos, errores, currentUser, guia } = props;
    console.log("tiempos: ");
    console.log(tiempos);
    console.log(tiempos[0]);
    let tiempoPromedio = calcularPromedio(tiempos);
    let tiempoTotal = Number((sumatoria(tiempos) / 60).toFixed(1));
    let desviacion = calcularDesviacion();
    let fecha = new Date().toLocaleDateString();
    let ejercicios = formatEjercicios();
    let horaComienzo = props.horaComienzo;
    let horaFin = setEndTime();
    let guiaID = guia.id;
    let guiaCodigo = guia.codigo;
    // let email = currentUser.email;
    let email = guia.usuario;

    if (email === "") {
      alert("email vacío. Abortando" + "\n" + "Guia: " + guia.titulo + "\n");
      console.log("current user");
      console.log(currentUser);
      return;
    }

    let params: Resultado = {
      timestamp: Date.now(),
      email: currentUser.email,
      tiempoPromedio,
      tiempoTotal,
      desviacion,
      errores,
      fecha: new Date(),
      horaComienzo: horaComienzo,
      horaFin: horaFin,
      guiaID: guia.id!,
      guiaCodigo: guia.codigo,
      guiaTitulo: guia.titulo,
      ejercicios: ejercicios,
    };
    console.log("params");
    console.log(params);

    let jsonParams = JSON.stringify(params);

    setLoading(true);
    api({
      method: "post",
      url: "/resultados/SaveResultados",
      data: jsonParams,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        setLoading(false);
        console.log("Resultados saved successfully");

        let query = ejercicios.filter((el: any) => el.errores > 0);
        let ejerciciosConErrores = query.length;

        calculateSemaforo(
          tiempoPromedio,
          desviacion,
          tiempoResolucionAlto,
          tiempoResolucionBajo,
          porcentajeErroresAlto,
          porcentajeErroresBajo,
          desviacionEstandarAlto,
          desviacionEstandarBajo
        );

        updateGuia();

        let counterID = getGuiaIdLocalStorage() + "_counter";
        let lsErrores = getGuiaIdLocalStorage() + "_errores";
        let lsErroresPorEjercicio =
          getGuiaIdLocalStorage() + "_erroresPorEjercicio";
        let lsTiemposRespuesta = getGuiaIdLocalStorage() + "_tiemposRespuesta";

        // debugger;
        console.log("removing errores: ");
        console.log(lsErrores);
        localStorage.removeItem(lsErrores);

        console.log("removing counter: ");
        console.log(counterID);
        localStorage.removeItem(counterID);

        console.log("removing errores por ejercicio: ");
        console.log(lsErroresPorEjercicio);
        localStorage.removeItem(lsErroresPorEjercicio);

        console.log("removing tiempos respuesta: ");
        console.log(lsTiemposRespuesta);
        localStorage.removeItem(lsTiemposRespuesta);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  const calculateSemaforo = (
    tiempoPromedio: number,
    desviacion: number,
    tiempoResolucionAlto: string,
    tiempoResolucionBajo: string,
    porcentajeErroresAlto: string,
    porcentajeErroresBajo: string,
    desviacionEstandarAlto: string,
    desviacionEstandarBajo: string
  ) => {
    console.log("calculate semarofo");

    let codigoTiempoPromedio;
    let codigoPorcentajeErrores;
    let codigoDesviacion;
    let codigoTotal;
    let semaforoColor;

    let ejercicios = formatEjercicios();

    let totalEjercicios = ejercicios.length;

    let query = ejercicios.filter((el: any) => el.errores > 0);
    let ejerciciosConErrores = query.length;

    let porcentajeErrores = (ejerciciosConErrores / totalEjercicios) * 100;

    console.log("porcentajeErrores");
    console.log(porcentajeErrores);

    console.log("tiempo promedio: ");
    console.log(tiempoPromedio);

    console.log("desviacion estandar");
    console.log(desviacion);

    let tiempoResolucionAltoInt = parseInt(tiempoResolucionAlto);
    let tiempoResolucionBajoInt = parseInt(tiempoResolucionBajo);

    let porcentajeErroresAltoInt = parseFloat(porcentajeErroresAlto);
    let porcentajeErroresBajoInt = parseFloat(porcentajeErroresBajo);

    let desviacionEstandarAltoInt = parseInt(desviacionEstandarAlto);
    let desviacionEstandarBajoInt = parseInt(desviacionEstandarBajo);

    // debugger;
    if (tiempoPromedio < tiempoResolucionAltoInt) {
      codigoTiempoPromedio = "A";
    } else if (
      tiempoPromedio > tiempoResolucionAltoInt &&
      tiempoPromedio < tiempoResolucionBajoInt
    ) {
      codigoTiempoPromedio = "M";
    } else {
      codigoTiempoPromedio = "B";
    }

    if (porcentajeErrores < porcentajeErroresAltoInt) {
      codigoPorcentajeErrores = "A";
    } else if (
      porcentajeErrores > porcentajeErroresAltoInt &&
      porcentajeErrores < porcentajeErroresBajoInt
    ) {
      codigoPorcentajeErrores = "M";
    } else {
      codigoPorcentajeErrores = "B";
    }

    if (desviacion < desviacionEstandarAltoInt) {
      codigoDesviacion = "A";
    } else if (
      desviacion > desviacionEstandarAltoInt &&
      desviacion < desviacionEstandarBajoInt
    ) {
      codigoDesviacion = "M";
    } else {
      codigoDesviacion = "B";
    }

    console.log("codigoTiempoPromedio");
    console.log(codigoTiempoPromedio);

    console.log("codigoPorcentajeErrores");
    console.log(codigoPorcentajeErrores);

    console.log("codigoDesviacion");
    console.log(codigoDesviacion);

    codigoTotal =
      codigoTiempoPromedio + codigoPorcentajeErrores + codigoDesviacion;

    console.log("codigo: ");
    console.log(codigoTotal);

    let cantidadB = codigoTotal.split("B").length - 1;
    if (cantidadB > 2) {
      semaforoColor = "red";
    } else if (cantidadB == 1) {
      semaforoColor = "yellow";
    } else {
      semaforoColor = "green";
    }

    setSemaforoColor(semaforoColor);

    console.log("semaforoColor");
    console.log(semaforoColor);
  };

  const millisToMinutesAndSeconds = (millis: number) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  const sumatoria = (tiempos: number[]) => {
    let tiempoTotal = 0;
    for (let i = 0; i < tiempos.length; i++) {
      let currentTiempo = tiempos[i];
      tiempoTotal = tiempoTotal + currentTiempo;
    }
    return Number(tiempoTotal.toFixed(2));
  };

  const calcularPromedio = (tiempos: number[]) => {
    let tiempoTotal = 0;
    for (let i = 0; i < tiempos.length; i++) {
      let currentTiempo = tiempos[i];
      tiempoTotal = tiempoTotal + currentTiempo;
    }
    let promedio = tiempoTotal / tiempos.length;
    return Number(promedio.toFixed(1));
  };

  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };

  const secsToMinsSecs = (secs: string) => {
    let totalSeconds = parseInt(secs);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    let result = padTo2Digits(minutes) + ":" + padTo2Digits(seconds);
    return result;
  };

  const getMins = (secs: string) => {
    let totalSeconds = parseInt(secs);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    let result = padTo2Digits(minutes);
    return result;
  };

  const getSecs = (secs: string) => {
    let totalSeconds = parseInt(secs);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    let result = padTo2Digits(seconds);
    return result;
  };

  const showErrores = () => {
    const { errores } = props;
    let message;
    if (errores === 0) {
      message = "No tuviste ningún error";
    } else {
      message = "Tuviste " + errores + " errores";
    }
    return message;
  };

  const switchScreen = (screen: string) => {
    console.log("switch to: ", screen);
    history.push(screen);
  };

  return (
    <LoadingOverlay active={loading} spinner text="Cargando...">
      <div style={{ marginTop: 75 }}>
        <h1>¡Felicidades! Terminaste tu guía en</h1>
        <h1>
          {getMins(sumatoria(props.tiempos).toFixed(2))} minutos{" "}
          {getSecs(sumatoria(props.tiempos).toFixed(2))} segundos
        </h1>
        <h1>{showErrores()}</h1>
        <h1>¡Te esperamos mañana!</h1>
        {/* <div style={{ marginTop: 20, height: 50, width: 50, backgroundColor: semaforoColor }}>

                </div> */}
        <div style={{ marginTop: 50 }}>
          <Button
            style={{ marginBottom: 50 }}
            onClick={() => switchScreen("/usuario")}
          >
            Regresar
          </Button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Resultados;

import React, { Component } from "react";
import api from "../../api/AxiosAPI";
import Auth from "../../api/Auth";
import { Menu, Button } from "semantic-ui-react";
import { history } from "../history";
import { User } from "../../data-models/user";
import { Grupo } from "../../data-models/grupo";
import { Unidad } from "../../data-models/unidad";
import { Nivel } from "../../data-models/nivel";
import { Subnivel } from "../../data-models/subnivel";
import ListaUsuarios from "./ListaUsuarios";
import UsuarioDetalles from "./UsuarioDetalles";
import UsuarioAgregar from "./UsuarioAgregar";
import wiserContext from "../../providers/wiserContext";
import LoadingOverlay from "react-loading-overlay-ts";

interface IProps {}

interface IState {
  status_message: string;
  user_location: string;
  user_interests: string;
  all_user_data: any;
  loading: boolean;
}

class Usuarios extends Component<IProps, IState> {
  static contextType = wiserContext;

  constructor(props: IProps) {
    super(props);
    this.state = {
      status_message: "",
      user_location: "",
      user_interests: "",
      all_user_data: [],
      loading: false,
    };
  }

  handleClick = () => {
    history.push("/agregar-usuario");
  };

  selectScreen = (screen: string) => {
    this.context.setSelectedScreen(screen);
  };

  componentDidMount() {
    this.getAllUserData();
  }

  saveUserData = (user: User) => {
    this.setState({ loading: true });
    api({
      method: "put",
      url: "/usuarios/SaveUser",
      data: user,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log("user saved successfully");
        console.log(response);
        this.setState({
          status_message: "Data about the user saved successfully.",
        });
        this.getAllUserData();
        this.selectScreen("usuarios");
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  prepareAddUser = (user: User) => {
    this.setState({ loading: true });
    api({
      method: "get",
      url: "/usuarios/GetUser?email=" + user.email,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log("get user data");
        console.log(response);

        //check this later
        if (response.data[0]) {
          alert("Ya existe un usuario con ese email");
        } else {
          this.addUser(user);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  addUser = (user: User) => {
    this.setState({ loading: true });
    api({
      method: "post",
      url: "/usuarios/SaveUser",
      data: user,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        alert("Usuario creado exitosamente!");
        console.log("user saved successfully");
        console.log(response);
        this.setState({ loading: false });
        this.getAllUserData();
        this.selectScreen("usuarios");
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  confirmDeleteUser = (userEmail: string) => {
    if (window.confirm("Eliminar usuario?") == true) {
      this.deleteUserData(userEmail);
    }
  };

  deleteUserData = (userEmail: string) => {
    this.setState({ loading: true });
    api({
      method: "delete",
      url: "/usuarios/DeleteUser?email=" + userEmail,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        console.log("response");
        console.log(response);
        alert(response.data);
        this.setState({ loading: false });
        this.getAllUserData();
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  getAllUserData() {
    let fetchUsers: User[];
    fetchUsers = [];
    this.setState({ loading: true });
    api({
      method: "get",
      url: "usuarios/GetAllUsers",
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        this.setState({ loading: false });
        for (var i in response.data) {
          let user = response.data[i];
          fetchUsers.push({
            id: user.id,
            nombre: user.nombre,
            apellido: user.apellido,
            email: user.email,
            fechaInscripcion: user.fechaInscripcion,
            fechaNacimiento: user.fechaNacimiento,
            gradoInicial: user.gradoInicial,
            grupo: user.grupo,
            nivel: user.nivel,
            subnivel: user.subnivel,
            pais: user.pais,
            pedagogoAsignado: user.pedagogoAsignado,
            representante: user.representante,
            status: user.status,
            telefono: user.telefono,
            unidad: user.unidad,
            meta: user.meta,
            guias: user.guias,
            asignaciones: user.asignaciones,
          });
        }
        this.context.setUsuarios(fetchUsers);
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  }

  renderListaUsuarios = () => {
    const { usuarios } = this.context;
    const usersObject = {
      users: usuarios,
    };
    let list = (
      <ListaUsuarios
        {...usersObject}
        deleteUser={this.confirmDeleteUser}
        selectUser={this.selectUser}
        switchScreen={this.selectScreen}
      />
    );
    return list;
  };

  selectUser = (user: User) => {
    this.context.setSelectedUsuario(user);
    this.selectScreen("detalles");
  };

  renderUsuarioDetalles = (selectedUsuario: User) => {
    const { grupos, niveles, subniveles, unidades } = this.context;
    const propsObject = {
      user: selectedUsuario,
      niveles: niveles,
      grupos: grupos,
      unidades: unidades,
      subniveles: subniveles,
    };
    let list = (
      <UsuarioDetalles
        {...propsObject}
        switchScreen={this.selectScreen}
        saveUserData={this.saveUserData}
      />
    );
    return list;
  };

  renderUsuarioAgregar = () => {
    let list = (
      <UsuarioAgregar
        saveUser={this.prepareAddUser}
        switchScreen={this.selectScreen}
      />
    );
    return list;
  };

  renderSelectedScreen = () => {
    const { selectedUsuario, selectedScreen } = this.context;
    let body;
    switch (selectedScreen) {
      case "usuarios":
        body = this.renderListaUsuarios();
        break;
      case "detalles":
        body = this.renderUsuarioDetalles(selectedUsuario);
        break;
      case "agregar":
        body = this.renderUsuarioAgregar();
        break;
      default:
        body = this.renderListaUsuarios();
        break;
    }
    return body;
  };

  render() {
    const { loading } = this.state;
    const body = this.renderSelectedScreen();
    return (
      <LoadingOverlay active={loading} spinner text="Cargando...">
        <div>{body}</div>
      </LoadingOverlay>
    );
  }
}

export default Usuarios;

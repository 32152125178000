import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import wiserContext from "../../providers/wiserContext";
import TecladoRespuesta from "../Teclado/TecladoRespuesta";
import Switch from "react-switch";
import { Button } from "semantic-ui-react";
import { history } from "../history";
import Resultados from "./Resultados";
import { User } from "../../data-models/user";
import { Ejercicio } from "../../data-models/ejercicio";
import { isBrowser, isMobile } from "react-device-detect";

interface IProps {
  tiempo: string;
  resetTimer: any;
  startTimer: any;
  stopTimer: any;
  stopGeneralTimer: any;
  currentUser: User;
  tiemposRespuesta: number[];
}

interface IState {
  counter: number;
  respuesta: string[];
  direccion: string;
  errores: number;
  respuestaIndex: number;
  erroresPorEjercicio: number[];
  sentidoVertical: boolean;
  width: number;
  horaComienzo: string;
}

class SumaResta extends Component<IProps, IState> {
  static contextType = wiserContext;
  state = {
    counter: 0,
    respuesta: [""],
    direccion: "derecha",
    errores: 0,
    respuestaIndex: 0,
    erroresPorEjercicio: [0],
    sentidoVertical: true,
    width: 0,
    tiemposRespuesta: [0],
    horaComienzo: "",
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.setupCounter();
    this.setupErroresPorEjercicio();
    this.setupErrores();
    this.setHoraComienzo();
    this.selectFirstBox();
  }

  setHoraComienzo = () => {
    var time = new Date();
    let timeString = time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    this.setState({ horaComienzo: timeString });
  };

  selectFirstBox = () => {
    //keyword
    const { selectedGuia } = this.context;
    const { counter } = this.state;
    const { solucion } = selectedGuia.ejercicios[counter];
    // let solucion = ejercicio.solucion;
    let solucionLength = solucion.toString().length;

    window.setTimeout(() => {
      let nextBoxId = (solucionLength - 1).toString();
      let nextBox = document.getElementById(nextBoxId);

      if (nextBox) {
        nextBox.focus();
      }

      this.updateRespuestaIndex(solucionLength - 1);
    }, 500);
  };

  switchScreen = (screen: string) => {
    console.log("switch to: ", screen);
    history.push(screen);
  };

  updateDimensions = () => {
    const width = window.innerWidth;
    this.setState({ width: width });
    console.log("width: ", width);

    console.log("userAgent");
    console.log(window.navigator.platform);
    console.log(window.navigator);
  };

  handleSentidoChange = (checked: boolean) => {
    console.log("sentido change");
    const { respuestaIndex } = this.state;

    window.setTimeout(() => {
      let nextBoxId = respuestaIndex.toString();
      let nextBox = document.getElementById(nextBoxId);

      if (nextBox) {
        nextBox.focus();
      }
    }, 500);

    this.setState({ sentidoVertical: checked });
  };

  getStyles = () => {
    const { width } = this.state;
    let currentStyles;

    if (isMobile || width < 500) {
      currentStyles = stylesResponsive;
    } else {
      currentStyles = styles;
    }

    // if (width > 500) {
    //   currentStyles = styles;
    // } else {
    //   currentStyles = stylesResponsive;
    //   console.log(window.navigator.platform);

    //   let platform = window.navigator.platform;

    //   if (platform === "iPhone" || platform === "iPad") {
    //     const { inputBox } = stylesResponsive;

    //     const newInputBoxStyle = {
    //       ...inputBox,
    //       width: 65,
    //       minWidth: 65,
    //     };

    //     currentStyles = {
    //       ...stylesResponsive,
    //       inputBox: newInputBoxStyle,
    //     };
    //   } else {
    //     currentStyles = stylesResponsive;
    //   }
    // }

    return currentStyles;
  };

  selectNextBox = (ejercicioIndex: number) => {
    const { selectedGuia } = this.context;
    const { direccion } = this.state;

    let selectedEjercicio = selectedGuia.ejercicios[ejercicioIndex];
    if (!selectedEjercicio) {
      return;
    }
    let solucion = selectedEjercicio.solucion;
    let solucionLength = solucion.toString().length;

    if (!selectedGuia) {
      return;
    }

    let ejercicio = selectedGuia.ejercicios[ejercicioIndex];
    if (!ejercicio) {
      return;
    }

    let nextBox;
    let lastBoxIndex;
    let firstBoxIndex;

    lastBoxIndex = solucionLength - 1;
    firstBoxIndex = 0;
    if (direccion === "izquierda") {
      nextBox = document.getElementById(lastBoxIndex.toString());
    } else {
      nextBox = document.getElementById(firstBoxIndex.toString());
    }

    if (nextBox) {
      nextBox.focus();
    }
  };

  next = () => {
    console.log("next");

    const { counter } = this.state;

    let newCount = counter + 1;
    const { selectedGuia } = this.context;
    let ejerciciosLength = selectedGuia.ejercicios.length;
    this.props.resetTimer(counter);
    if (newCount === ejerciciosLength) {
      this.props.stopTimer();
      this.props.stopGeneralTimer();
    } else {
      this.props.stopTimer();
      this.props.startTimer();
    }
    this.setState({ counter: newCount });
    this.updateCounterLocalStorage(newCount.toString());
    this.selectNextBox(newCount);
  };

  setupErrores = () => {
    const erroresId = this.getGuiaIdLocalStorage() + "_errores";
    const localStorageErrores = localStorage.getItem(erroresId);

    if (localStorageErrores) {
      console.log("localStorageErrores: " + localStorageErrores);
      this.setState({ errores: parseInt(localStorageErrores) });
    } else {
      console.log("localStorageErrores not found");
      this.setState({ errores: 0 });
    }
  };

  setupCounter = () => {
    debugger;
    const counterId = this.getGuiaIdLocalStorage() + "_counter";
    console.log("counter id: ");
    console.log(counterId);
    const localStorageCounter = localStorage.getItem(counterId);

    if (localStorageCounter) {
      console.log("localStorageCounter: " + localStorageCounter);
      this.setState({ counter: parseInt(localStorageCounter) });
    } else {
      console.log("localStorageCounter not found, setting default to zer0");
      this.setState({ counter: 0 });
    }
  };

  setupErroresPorEjercicio = () => {
    const { selectedGuia } = this.context;
    const erroresPorEjercicioId =
      this.getGuiaIdLocalStorage() + "_erroresPorEjercicio";

    let ejerciciosLength = selectedGuia.ejercicios.length;
    let defaultErroresPE: number[] = [];

    const localStorageDefaultErrores = localStorage.getItem(
      erroresPorEjercicioId
    );
    console.log("localStorageDefaultErrores");
    console.log(localStorageDefaultErrores);
    if (localStorageDefaultErrores) {
      let localStorageDefaultErroresArray = localStorageDefaultErrores
        .substring(1, localStorageDefaultErrores.length - 1)
        .split(",");

      var nums = localStorageDefaultErroresArray.map((str) => parseInt(str));

      console.log("errores por ejercicio");
      console.log(nums);

      this.setState({ erroresPorEjercicio: nums });
    } else {
      console.log("local storage default errores not found, starting with 0");
      for (let i = 0; i < ejerciciosLength; i++) {
        defaultErroresPE.push(0);
      }
      this.setState({ erroresPorEjercicio: defaultErroresPE });
    }
  };

  addErrorPorEjercicio = (index: number) => {
    const { erroresPorEjercicio } = this.state;
    let currentEjercicioErrores = erroresPorEjercicio[index];
    let newErrores = erroresPorEjercicio;
    let newErroresCount = currentEjercicioErrores + 1;
    newErrores[index] = newErroresCount;
    this.setState({ erroresPorEjercicio: newErrores });

    this.updateErroresPorEjercicioLocalStorage(JSON.stringify(newErrores));
  };

  updateErroresPorEjercicioLocalStorage = (errores: string) => {
    const erroresPorEjercicioId =
      this.getGuiaIdLocalStorage() + "_erroresPorEjercicio";

    localStorage.setItem(erroresPorEjercicioId, errores);
  };

  updateErroresLocalStorage = (errores: string) => {
    const erroresPorEjercicioId = this.getGuiaIdLocalStorage() + "_errores";

    localStorage.setItem(erroresPorEjercicioId, errores);
  };

  updateCounterLocalStorage = (count: string) => {
    const counterId = this.getGuiaIdLocalStorage() + "_counter";

    localStorage.setItem(counterId, count);
  };

  updateRespuestaIndex = (index: number) => {
    this.setState({ respuestaIndex: index });
  };

  getGuiaIdLocalStorage = () => {
    const { selectedGuia } = this.context;
    const { codigo, id } = selectedGuia;
    const guiaId = codigo + "_" + id;

    return guiaId;
  };

  resetRespuesta = () => {
    const { selectedGuia } = this.context;
    const { counter } = this.state;
    let solucion = selectedGuia.ejercicios[counter].solucion;
    let solucionLength = solucion.toString().length;
    let defaultRespuesta: string[] = [];
    for (let i = 0; i < solucionLength; i++) {
      defaultRespuesta.push("");
    }

    this.setState({ respuesta: defaultRespuesta });

    this.updateRespuestaIndex(0);
  };

  evaluateRespuesta = (resp: string[]) => {
    console.log("evaluate respuesta");
    const { selectedGuia } = this.context;
    const { counter, errores } = this.state;

    let newErrores = errores;

    let solucion = selectedGuia.ejercicios[counter].solucion;

    let concatRespuesta = "";
    for (let i in resp) {
      let digit = resp[i];
      concatRespuesta = concatRespuesta + digit;
    }

    if (concatRespuesta === solucion.toString()) {
      window.setTimeout(() => {
        this.resetRespuesta();
        this.next();
      }, 500);
    } else {
      this.addErrorPorEjercicio(counter);
      newErrores = newErrores + 1;
      this.setState({ errores: newErrores });
      this.updateRespuestaIndex(0);
      this.updateErroresLocalStorage(newErrores.toString());
    }
  };

  clearRespuesta = (arr: string[]) => {
    let filteredArr = arr.filter((digit) => digit !== "");
    return filteredArr;
  };

  updateRespuesta = (resp: string, rindex: string) => {
    console.log("resp");
    console.log(resp);

    if (resp.length > 1) {
      let lastIndex = resp.length - 1;
      resp = resp[lastIndex];
    }

    const index = parseInt(rindex);
    console.log("index: " + index);
    const { selectedGuia } = this.context;
    const { counter, respuesta, direccion } = this.state;
    // debugger;
    let solucion = selectedGuia.ejercicios[counter].solucion;
    let solucionLength = solucion.toString().length;
    let lastIndex = solucionLength - 1;
    let newRespuesta = respuesta;
    let newDireccion = direccion;
    let nextBoxIndex;
    let filteredRespuesta = resp.match(/\d/g);

    if (filteredRespuesta) {
      newRespuesta[index] = filteredRespuesta.join("");

      // if the user is in the first box from left to right
      // and the next box is empty
      // and the solution is more than one digit long
      // then direccion is derecha
      if (index === 0 && newRespuesta[1] === "" && solucionLength > 1) {
        newDireccion = "derecha";
        this.setState({ direccion: newDireccion });
      }

      let nr = newRespuesta;
      let li = lastIndex - 1;
      let nri = newRespuesta[li];

      if (!newRespuesta[lastIndex - 1]) {
        newRespuesta[lastIndex - 1] = "";
      }

      if (
        index === lastIndex &&
        newRespuesta[lastIndex - 1] === "" &&
        solucionLength > 1
      ) {
        newDireccion = "izquierda";
        this.setState({ direccion: newDireccion });
      }

      console.log("direccion: " + newDireccion);
      // debugger;
      if (newDireccion === "derecha") {
        nextBoxIndex = 1;
      } else {
        nextBoxIndex = -1;
      }

      this.updateRespuestaIndex(nextBoxIndex);

      let nextBox = document.getElementById((index + nextBoxIndex).toString());
      if (nextBox) {
        nextBox.focus();
      }
    } else {
      newRespuesta[index] = "";
    }

    this.setState({ respuesta: newRespuesta });

    let escapedRespuesta = this.clearRespuesta(newRespuesta);
    let newRLength = escapedRespuesta.length;

    if (newRLength === solucionLength) {
      this.evaluateRespuesta(newRespuesta);
    }

    return nextBoxIndex;
  };

  handleFocus = (event: any) => {
    let boxId = event.target.id;
    this.updateRespuestaIndex(boxId);

    if (isBrowser) {
      event.target.select();
    } else {
      event.target.blur();
      // event.target.blur();

      // if ('virtualKeyboard' in navigator) {
      //     // The VirtualKeyboard API is supported!
      //     navigator.virtualKeyboard.show()

      // }

      event.preventDefault();
    }
  };

  generateRespuestaBoxes = (ejercicio: Ejercicio, firstRow: string[]) => {
    const { respuesta } = this.state;
    const { solucion } = ejercicio;
    const { inputBox } = this.getStyles();

    let boxes: JSX.Element[] = [];

    let escapedSolucionLength = solucion.toString().replace(/[^0-9]/g, "")
      .length;

    //returns the length of the longest string in the array
    let longestTermino = Math.max(
      ...ejercicio.columnas.map((el: number) => el.toString().length)
    );

    //adding extra boxes if the solution length is less than of the longest term

    let rowLength = firstRow.length;
    if (rowLength > escapedSolucionLength) {
      let difference = rowLength - escapedSolucionLength;
      for (let i = 0; i < difference; i++) {
        let box = <div style={inputBox}></div>;
        boxes.push(box);
      }
    }

    for (let i = 0; i < escapedSolucionLength; i++) {
      let box = (
        <input
          autoComplete="off"
          id={i.toString()}
          key={i}
          onChange={(e) => this.updateRespuesta(e.target.value, i.toString())}
          type="text"
          value={respuesta[i]}
          style={inputBox}
          onFocus={this.handleFocus}
        ></input>
      );
      boxes.push(box);
    }

    return boxes;
  };

  generateGrid = () => {
    // keyword;
    const { counter, sentidoVertical } = this.state;
    const { selectedGuia } = this.context;
    let grid;

    let body = <div></div>;

    if (!selectedGuia) {
      return body;
    }

    const ejercicio = selectedGuia.ejercicios[counter];
    const { solucion, operador, columnas } = ejercicio;

    let rows: string[][] = [];

    //returns the length of the longest string in the array
    let longestTermino = Math.max(
      ...columnas.map((el: number) => el.toString().length)
    );

    for (let i = 0; i <= columnas.length; i++) {
      //for each termino in the sum, ex: [54, 1]

      let termino = columnas[i];

      // adding an extra column for the operator
      const colArray = new Array(longestTermino + 1);

      for (let t = 0; t < longestTermino; t++) {
        colArray[t] = "";
      }

      if (termino !== undefined) {
        let splitTermino = termino.toString().split(""); //54 becomes ['5', '4']

        for (let a = longestTermino; a > 0; a--) {
          // ["1"]
          if (splitTermino.length === 0) {
            break;
          }
          let lastNumInTerm = splitTermino.pop();

          colArray[a] = lastNumInTerm;
        }

        if (i !== 0) {
          colArray[0] = operador;
        } else {
          colArray[0] = "";
        }
        rows.push(colArray);
      }
    }

    // adding extra columns if the solution is longer than the terms
    let solucionLength = solucion.toString().split("").length;
    let diferencia = solucionLength - longestTermino;

    let rowsLength = rows.length;
    // debugger;

    // for (let i = 0; i < rowsLength; i++) {
    //     for (let a = 0; a < diferencia; a++) {
    //         rows[i].unshift('');
    //     }
    // }

    let getStyle = this.getStyles();

    let boxes = this.generateRespuestaBoxes(ejercicio, rows[0]);

    // console.log('boxes');
    // console.log(boxes);

    const { ejercicioContainer, colStyle } = getStyle;

    if (sentidoVertical) {
      grid = (
        <div style={ejercicioContainer}>
          <Grid>
            {rows.map((
              row: any,
              index: number // row[0]= ['', '5', '3']
            ) => (
              <Row style={{ marginTop: 20 /*flexWrap: 'nowrap'*/ }} key={index}>
                {row.map((column: any, index: number) => (
                  <Col
                    style={colStyle}
                    xs={this.getColSize(row.length)}
                    md={2}
                    key={index}
                  >
                    {column}
                  </Col>
                ))}
              </Row>
            ))}
            <Row style={{ marginTop: 20, borderTop: "5px solid #017894" }}>
              {boxes.map((box: any, index: number) => (
                <Col
                  key={index}
                  style={colStyle}
                  xs={this.getColSize(rows[0].length)}
                  md={2}
                >
                  {box}
                </Col>
              ))}
            </Row>
          </Grid>
        </div>
      );
    } else {
      let num1 = columnas[0];
      let num2 = columnas[1];
      grid = (
        <div style={getStyle.ejercicioContainer}>
          <div>
            {num1} {operador} {num2} ={boxes}
          </div>
        </div>
      );
    }
    return grid;
  };

  getColSize = (rowLength: number) => {
    let size = 12 / rowLength;
    return size;
  };

  getBoxColSize = (boxes: any[], rows: string[][]) => {
    let boxesLength = boxes.length;
    let longestRowLength = rows[0].length;

    let difference = longestRowLength - boxesLength;
  };

  generateBody = () => {
    const { selectedGuia } = this.context;
    const { counter } = this.state;
    let ejercicios = selectedGuia.ejercicios;
    let ejerciciosLength = ejercicios.length;
    let body;
    if (counter < ejerciciosLength) {
      body = this.generateEjercicio();
    } else {
      body = this.generateResultados();
    }
    return body;
  };

  generateResultados = () => {
    const { currentUser, tiemposRespuesta } = this.props;
    const { errores, erroresPorEjercicio, horaComienzo } = this.state;
    const { selectedGuia } = this.context;
    let ejercicios = selectedGuia.ejercicios;

    let frame = (
      <Resultados
        currentUser={currentUser}
        errores={errores}
        tiempos={tiemposRespuesta}
        erroresPorEjercicio={erroresPorEjercicio}
        ejercicios={ejercicios}
        horaComienzo={horaComienzo}
        guia={selectedGuia}
        getUserData={() => console.log("hell oworld")}
      ></Resultados>
    );
    return frame;
  };

  generateEjercicio = () => {
    const { width, respuestaIndex, errores, counter } = this.state;
    const { gridContainer, gridItemContainer } = this.getStyles();
    const { tiempo } = this.props;
    let body;

    if (width > 500) {
      body = (
        <div>
          {this.generateSwitch()}
          <div style={gridContainer}>
            <Grid fluid>
              <Row>
                <Col xs={3} md={3}>
                  <h2>{tiempo}</h2>
                  <h2>Ej. # {counter + 1}</h2>
                </Col>
                <Col xs={6} md={6}>
                  {this.generateGrid()}
                </Col>
                <Col xs={3} md={3}>
                  <div>
                    <TecladoRespuesta
                      respuestaIndex={respuestaIndex}
                      updateRespuesta={this.updateRespuesta}
                    ></TecladoRespuesta>
                  </div>
                </Col>
              </Row>
            </Grid>
            <Button
              style={{ marginBottom: 50 }}
              onClick={() => this.switchScreen("/usuario")}
            >
              Regresar
            </Button>
          </div>
        </div>
      );
    } else {
      body = (
        <div>
          {this.generateSwitch()}
          <div style={gridContainer}>
            <Grid fluid>
              <Row>
                <div style={gridItemContainer}>
                  <h2>{tiempo}</h2>
                  <h2>Ej. # {counter + 1}</h2>
                </div>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  {this.generateGrid()}
                </Col>
              </Row>
              <Row>
                <div style={gridItemContainer}>
                  <TecladoRespuesta
                    respuestaIndex={respuestaIndex}
                    updateRespuesta={this.updateRespuesta}
                  ></TecladoRespuesta>
                </div>
              </Row>
            </Grid>
            <Button
              style={{ marginBottom: 50 }}
              onClick={() => this.switchScreen("/usuario")}
            >
              Regresar
            </Button>
          </div>
        </div>
      );
    }

    return body;
  };

  generateSwitch = () => {
    const { selectedGuia } = this.context;
    const { sentidoVertical, respuestaIndex } = this.state;
    const { container, switchContainer } = this.getStyles();

    let body;

    let subnivel = selectedGuia.subnivel;

    if (subnivel == "basico" || subnivel == "medio") {
      body = (
        <div>
          <div style={switchContainer}>
            <h2>
              <span>Direccion: </span>
              <Switch
                onChange={this.handleSentidoChange}
                checked={sentidoVertical}
                className="react-switch"
              />
            </h2>
          </div>
          <h2>{sentidoVertical ? "vertical" : "horizontal"}</h2>
        </div>
      );
    } else {
      body = (
        <div>
          <div style={switchContainer}>
            <h2>
              <span>Direccion: </span>
            </h2>
          </div>
          <h2>{sentidoVertical ? "vertical" : "horizontal"}</h2>
        </div>
      );
    }

    return body;
  };

  render() {
    const { container } = this.getStyles();
    return <div style={container}>{this.generateBody()}</div>;
  }
}

const styles = {
  container: {
    height: "100vh",
  } as React.CSSProperties,
  gridContainer: {
    marginTop: 100,
  } as React.CSSProperties,
  ejercicioContainer: {
    color: "#017894",
    backgroundColor: "#FFFFE0",
    width: 400,
    fontSize: 42,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: 20,
    borderRadius: 20,
  } as React.CSSProperties,
  gridItemContainer: {
    margin: 0,
  } as React.CSSProperties,
  switchContainer: {
    textAlign: "center",
  } as React.CSSProperties,
  inputBox: {
    width: 50,
    color: "#017894",
    marginTop: 10,
    minWidth: 50,
  },
  colStyle: {
    width: 50,
  },
};

const stylesResponsive = {
  container: {
    height: "105vh",
  } as React.CSSProperties,
  ejercicioContainer: {
    color: "#017894",
    backgroundColor: "#FFFFE0",
    fontSize: 32,
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: 20,
    borderRadius: 20,
    width: "38vh",
  } as React.CSSProperties,
  gridContainer: {
    marginTop: 0,
  } as React.CSSProperties,
  gridItemContainer: {
    margin: "auto",
  } as React.CSSProperties,
  switchContainer: {
    textAlign: "left",
  } as React.CSSProperties,
  inputBox: {
    width: 60,
    color: "#017894",
    marginTop: 10,
    minWidth: 60,
    textAlign: "center",
  },
  colStyle: {
    width: 60,
  },
};

export default SumaResta;

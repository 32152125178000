import React from "react";
import { Grupo, defaultGrupo } from "../data-models/grupo";
import { Nivel, defaultNivel } from "../data-models/nivel";
import { Subnivel, defaultSubnivel } from "../data-models/subnivel";
import { User, defaultUser } from "../data-models/user";
import { Unidad, defaultUnidad } from "../data-models/unidad";
import { Asignatura, defaultAsignatura } from "../data-models/asignatura";
// import { Entrenamiento, defaultEntrenamiento } from '../data-models/entrenamiento';
import { Guia, defaultGuia } from "../data-models/guia";
import { Asignacion, defaultAsignacion } from "../data-models/asignacion";

export interface State {
  grupos: Grupo[];
  niveles: Nivel[];
  subniveles: Subnivel[];
  unidades: Unidad[];
  asignaturas: Asignatura[];
  selectedUsuario: User;
  selectedScreen: string;
  currentUserEmail: string;
  currentUser: User;
  usuarios: User[];
  statusMessage: string;
  guias: Guia[];
  selectedGuia: Guia;
}

export const StateContextDefaultValue: State = {
  grupos: [defaultGrupo],
  niveles: [defaultNivel],
  subniveles: [defaultSubnivel],
  unidades: [defaultUnidad],
  asignaturas: [defaultAsignatura],
  selectedUsuario: defaultUser,
  selectedScreen: "",
  currentUserEmail: "",
  currentUser: defaultUser,
  usuarios: [defaultUser],
  statusMessage: "",
  guias: [defaultGuia],
  selectedGuia: defaultGuia,
};

const wiserContext = React.createContext<State>(StateContextDefaultValue);

export default wiserContext;

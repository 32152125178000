import React, { Component, useState } from "react";
import { Router, Switch, Route, Link } from "react-router-dom";
import PortalPedagogo from "./PortalPedagogo";
import { history } from "./history";
import wiserContext from "../providers/wiserContext";
import { defaultUser } from "../data-models/user";
import { defaultNivel } from "../data-models/nivel";
import { defaultSubnivel } from "../data-models/subnivel";
import { defaultGrupo } from "../data-models/grupo";
import { defaultUnidad } from "../data-models/unidad";
import { defaultAsignatura } from "../data-models/asignatura";
import { defaultAsignacion } from "../data-models/asignacion";
import { defaultGuia } from "../data-models/guia";
import Home from "./Home";
import Auth from "../api/Auth";
import PortalUsuario from "./PortalUsuario";
import Gimnasio from "./Gimnasio";

const App = () => {
  const [grupos, setGrupos] = useState([defaultGrupo]);
  const [niveles, setNiveles] = useState([defaultNivel]);
  const [subniveles, setSubniveles] = useState([defaultSubnivel]);
  const [unidades, setUnidades] = useState([defaultUnidad]);
  const [asignaturas, setAsignaturas] = useState([]);
  const [selectedUsuario, setSelectedUsuario] = useState(defaultUser);
  const [selectedScreen, setSelectedScreen] = useState("usuarios");
  const [guias, setGuias] = useState([]);
  const [selectedGuia, setselectedGuia] = useState(defaultGuia);

  const [currentUserEmail, setCurrentUserEmail] = useState(
    Auth.currentUser().emails[0]
  );
  const [currentUser, setCurrentUser] = useState(defaultUser);
  const [usuarios, setUsuarios] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");

  const getState = () => {
    let state = {
      grupos,
      setGrupos,
      niveles,
      setNiveles,
      subniveles,
      setSubniveles,
      unidades,
      setUnidades,
      asignaturas,
      setAsignaturas,
      selectedUsuario,
      setSelectedUsuario,
      selectedScreen,
      setSelectedScreen,
      usuarios,
      setUsuarios,
      currentUser,
      setCurrentUser,
      currentUserEmail,
      setCurrentUserEmail,
      statusMessage,
      setStatusMessage,
      guias,
      setGuias,
      selectedGuia,
      setselectedGuia,
    };

    return state;
  };

  return (
    <wiserContext.Provider value={getState()}>
      <Router history={history}>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/usuario" component={PortalUsuario} />
            <Route path="/pedagogo" component={PortalPedagogo} />
            <Route path="/gimnasio" component={Gimnasio} />
          </Switch>
        </div>
      </Router>
    </wiserContext.Provider>
  );
};

export default App;

import React, { Component } from "react";
import wiserContext from "../providers/wiserContext";
import { User, defaultUser } from "../data-models/user";
import api from "../api/AxiosAPI";
import Auth from "../api/Auth";
import LoadingOverlay from "react-loading-overlay-ts";
import { history } from "./history";
import { Button } from "semantic-ui-react";
import logoAzul from "../assets/logo_azul.png";
import Multiplicacion from "./EjerciciosContainer/Multiplicacion";
import Division from "./EjerciciosContainer/Division";
import SumaResta from "./EjerciciosContainer/SumaResta";

const ms = require("pretty-ms");

interface IProps {}

interface IState {
  currentUser: User;
  loading: boolean;
  isOn: boolean;
  time: number;
  start: number;
  generalTimerActive: boolean;
  generalTime: number;
  generalTimerStart: number;
  tiemposRespuesta: number[];
}

class Gimnasio extends Component<IProps, IState> {
  static contextType = wiserContext;
  state = {
    currentUser: defaultUser,
    loading: false,
    isOn: false,
    time: 0,
    start: 0,
    generalTimerActive: false,
    generalTime: 0,
    generalTimerStart: 0,
    tiemposRespuesta: [0],
  };
  timer: any = null;
  generalTimer: any = null;

  componentDidMount() {
    this.getUserData();
    this.setupTiemposRespuesta();
    this.startTimer();
    this.startGeneralTimer();
  }

  padTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };

  secsToMinsSecs = (secs: string) => {
    let totalSeconds = parseInt(secs);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    let result = this.padTo2Digits(minutes) + ":" + this.padTo2Digits(seconds);
    return result;
  };

  setupTiemposRespuesta = () => {
    const { selectedGuia } = this.context;
    let tiemposLength = selectedGuia.ejercicios.length;
    let newTiempos = new Array(tiemposLength);

    const { titulo, id } = selectedGuia;

    const tiemposRespuestaId = titulo + "_" + id + "_" + "tiemposRespuesta";

    // recording tiempos de respuesta, errores, errores por ejercicio
    // in local storage in order to recover if the electricity or connection dies.
    const tiemposRespuestaLocalStorage = localStorage.getItem(
      tiemposRespuestaId
    );

    if (tiemposRespuestaLocalStorage) {
      let formattedTiempos = tiemposRespuestaLocalStorage
        .substring(1, tiemposRespuestaLocalStorage.length - 1)
        .split(",");
      var nums = formattedTiempos.map((str) => parseFloat(str));
      this.setState({ tiemposRespuesta: nums });
    } else {
      let newTiemposJSON = JSON.stringify(newTiempos);
      this.updateTiemposRespuestaLocalStorage(newTiemposJSON);
      this.setState({ tiemposRespuesta: newTiempos });
    }

    console.log("storage: ");
    console.log(localStorage.getItem(tiemposRespuestaId));
  };

  updateTiemposRespuestaLocalStorage = (tiempos: string) => {
    const { selectedGuia } = this.context;
    const { titulo, id } = selectedGuia;
    const tiemposRespuestaId = titulo + "_" + id + "_" + "tiemposRespuesta";
    this.updateLocalStorage(tiemposRespuestaId, tiempos);
  };

  updateLocalStorage = (id: string, val: string) => {
    localStorage.setItem(id, val);
  };

  startGeneralTimer = () => {
    this.setState({
      generalTimerActive: true,
      generalTime: this.state.generalTime,
      generalTimerStart: Date.now() - this.state.generalTime,
    });
    this.generalTimer = setInterval(
      () =>
        this.setState({
          generalTime: (Date.now() - this.state.generalTimerStart) / 1000,
        }),
      1000
    );
  };

  startTimer = () => {
    this.setState({
      isOn: true,
      time: this.state.time,
      start: Date.now() - this.state.time,
    });
    this.timer = setInterval(
      () =>
        this.setState({
          time: (Date.now() - this.state.start) / 1000,
        }),
      1000
    );
  };

  resetTimer = (counter: number) => {
    const { time, tiemposRespuesta } = this.state;
    let newTiempos = tiemposRespuesta;
    newTiempos[counter] = time;
    this.updateTiemposRespuestaLocalStorage(JSON.stringify(newTiempos));
    this.setState({ time: 0, isOn: false, tiemposRespuesta: newTiempos });
  };

  getUserData() {
    console.log("getting current user data");
    const { currentUserEmail } = this.context;
    const { currentUser } = this.state;
    let email;
    if (currentUserEmail) {
      email = currentUserEmail;
    } else {
      email = currentUser.email;
    }
    this.setState({ loading: true });
    api({
      method: "get",
      url: "usuarios/GetUser?email=" + email,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        if (response.data[0]) {
          this.context.setCurrentUser(response.data[0]);
          this.setState({ currentUser: response.data[0] });
        }
        let grupo = response.data[0].grupo;
        this.evaluateGrupo(grupo);
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          console.log("");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  }

  evaluateGrupo = (grupo: string) => {
    switch (grupo) {
      case "pedagogo":
        history.push("/pedagogo");
        break;
      default:
        break;
    }
  };

  switchScreen = (screen: string) => {
    console.log("switch to: ", screen);
    history.push(screen);
  };

  stopTimer = () => {
    this.setState({ isOn: false });
    clearInterval(this.timer);
  };

  stopGeneralTimer = () => {
    this.setState({ generalTimerActive: false });
    clearInterval(this.generalTimer);
  };

  generateBody = () => {
    const { selectedGuia } = this.context;
    const { generalTime, currentUser, tiemposRespuesta } = this.state;

    if (!selectedGuia) {
      return;
    }

    const { operador } = selectedGuia;

    let body;

    let tiempoMS = this.secsToMinsSecs(generalTime.toFixed(2));

    switch (operador) {
      case "suma":
        body = (
          <SumaResta
            tiempo={tiempoMS}
            currentUser={currentUser}
            resetTimer={this.resetTimer}
            startTimer={this.startTimer}
            stopTimer={this.stopTimer}
            stopGeneralTimer={this.stopGeneralTimer}
            tiemposRespuesta={tiemposRespuesta}
          ></SumaResta>
        );
        break;
      case "resta":
        body = (
          <SumaResta
            tiempo={tiempoMS}
            currentUser={currentUser}
            resetTimer={this.resetTimer}
            startTimer={this.startTimer}
            stopTimer={this.stopTimer}
            stopGeneralTimer={this.stopGeneralTimer}
            tiemposRespuesta={tiemposRespuesta}
          ></SumaResta>
        );
        break;
      case "multiplicacion":
        body = (
          <Multiplicacion
            tiempo={tiempoMS}
            currentUser={currentUser}
            resetTimer={this.resetTimer}
            startTimer={this.startTimer}
            stopTimer={this.stopTimer}
            stopGeneralTimer={this.stopGeneralTimer}
            tiemposRespuesta={tiemposRespuesta}
          ></Multiplicacion>
        );
        break;
      case "division":
        body = (
          <Division
            tiempo={tiempoMS}
            currentUser={currentUser}
            resetTimer={this.resetTimer}
            startTimer={this.startTimer}
            stopTimer={this.stopTimer}
            stopGeneralTimer={this.stopGeneralTimer}
            tiemposRespuesta={tiemposRespuesta}
          ></Division>
        );
        break;
      default:
        body = (
          <SumaResta
            tiempo={tiempoMS}
            currentUser={currentUser}
            resetTimer={this.resetTimer}
            startTimer={this.startTimer}
            stopTimer={this.stopTimer}
            stopGeneralTimer={this.stopGeneralTimer}
            tiemposRespuesta={tiemposRespuesta}
          ></SumaResta>
        );
        break;
    }

    return body;
  };

  render() {
    const { loading } = this.state;

    const { container, logoContainer } = styles;

    return (
      <LoadingOverlay active={loading} spinner text="Cargando...">
        <div style={container}>
          <div style={logoContainer}>
            <img width={200} src={logoAzul} alt="Logo" />
          </div>

          {this.generateBody()}
        </div>
      </LoadingOverlay>
    );
  }
}

const styles = {
  container: {
    backgroundColor: "#017894",
    // padding: 20,
    paddingTop: 20,
    paddingBotom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    // height: '100vh',
    color: "white",
    textAlign: "center",
  } as React.CSSProperties,
  logoContainer: {
    position: "absolute",
    right: 5,
    top: 5,
  } as React.CSSProperties,
};

export default Gimnasio;

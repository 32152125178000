import { Ejercicio, defaultEjercicio } from "./ejercicio";

export interface GuiaGrupoPorCodigo {
  id: string;
  codigo: string;
  titulo: string;
  operador: string;
  asignatura: string;
  nivel: string;
  subnivel: string;
  primerTerminoDesde: string;
  primerTerminoHasta: string;
  segundoTerminoDesde: string;
  segundoTerminoHasta: string;
  cantidadEjercicios: string;
  guias: Guia[];
}

export const defaultGuiaGrupoPorCodigo: GuiaGrupoPorCodigo = {
  id: "",
  codigo: "",
  titulo: "",
  operador: "",
  asignatura: "",
  nivel: "",
  subnivel: "",
  primerTerminoDesde: "0",
  primerTerminoHasta: "0",
  segundoTerminoDesde: "0",
  segundoTerminoHasta: "0",
  cantidadEjercicios: "0",
  guias: [],
};

export interface Guia {
  id?: string;
  codigo: string;
  titulo: string;
  usuario: string; //email
  fechaAsignada: Date; //cuando la guía fue asignada por el tutor
  fechaProgramada: Date; //en la cual el tutor programó que la guía debe ser resuelta
  fechaRealizada: Date | null; //cuando el usuario efectivamente la realizó la guía
  fechaAsignadaTimestamp: number;
  fechaProgramadaTimestamp: number;
  fechaRealizadaTimestamp: number | null;
  resuelta: boolean;
  operador: string;
  asignatura: string;
  nivel: string;
  subnivel: string;
  primerTerminoDesde: string;
  primerTerminoHasta: string;
  segundoTerminoDesde: string;
  segundoTerminoHasta: string;
  cantidadEjercicios: string;
  ejercicios: Ejercicio[];
}

export const defaultGuia: Guia = {
  id: "",
  codigo: "",
  titulo: "",
  usuario: "",
  fechaAsignada: new Date(),
  fechaProgramada: new Date(),
  fechaRealizada: new Date(),
  fechaAsignadaTimestamp: 0,
  fechaProgramadaTimestamp: 0,
  fechaRealizadaTimestamp: 0,
  resuelta: false,
  operador: "",
  asignatura: "",
  nivel: "",
  subnivel: "",
  primerTerminoDesde: "",
  primerTerminoHasta: "",
  segundoTerminoDesde: "",
  segundoTerminoHasta: "",
  cantidadEjercicios: "",
  ejercicios: [],
};

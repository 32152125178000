import axios from "axios";

/**
 * URLs and other api constants
 */
const constants = {
  api: {
    baseURL: "https://wlc-api-prod-01.azure-api.net",
  },
};

/**
 * Create an Axios Client with defaults
 */
export default axios.create({
  baseURL: constants.api.baseURL,
  headers: {
    "Ocp-Apim-Subscription-Key": "f60fe4736a514175b0ded640dbb70d70",
  },
});

// api: wlc-api-prod-01 'Ocp-Apim-Subscription-Key': 'f60fe4736a514175b0ded640dbb70d70',
// api: wlc-api-prod-premium 'Ocp-Apim-Subscription-Key': 'c3fc1b5a687c4797969461a37f2cf637',

import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import wiserContext from "../../providers/wiserContext";
import Switch from "react-switch";
import { Button } from "semantic-ui-react";
import { history } from "../history";
import { Ejercicio } from "../../data-models/ejercicio";
import TecladoProcedimiento from "../Teclado/TecladoProcedimiento";
import TecladoRespuesta from "../Teclado/TecladoRespuesta";
import { User } from "../../data-models/user";
import Resultados from "./Resultados";
import { isBrowser, isMobile } from "react-device-detect";

import { cleanProcedimientoGrid, compareGrids } from "../../helpers/grids";

import {
  generateProcedimientoGridMultiplicacion,
  generateTargetProcedimientoGridMultiplicacion,
} from "../../helpers/gridMultiplicacion";

interface IProps {
  tiempo: string;
  resetTimer: any;
  startTimer: any;
  stopTimer: any;
  stopGeneralTimer: any;
  currentUser: User;
  tiemposRespuesta: number[];
}

interface IState {
  counter: number;
  respuesta: string[];
  errores: number;
  respuestaIndex: number;
  erroresPorEjercicio: number[];
  residuoRespuesta: string[];
  procedimientoGrid: string[][];
  targetProcedimientoGrid: string[][];
  width: number;
  direccion: string;
  currentGrid: string;
  rowIndex: number;
  colIndex: number;
  sentidoVertical: boolean;
  horaComienzo: string;
}

class Multiplicacion extends Component<IProps, IState> {
  static contextType = wiserContext;
  state = {
    counter: 0,
    respuesta: ["", ""],
    errores: 0,
    respuestaIndex: 0,
    erroresPorEjercicio: [0],
    residuoRespuesta: [""],
    procedimientoGrid: [[""]],
    targetProcedimientoGrid: [[""]],
    width: 0,
    direccion: "",
    currentGrid: "procedimiento",
    rowIndex: 0,
    colIndex: 0,
    sentidoVertical: true,
    horaComienzo: "",
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.setupSentido();
    this.setupCounter();
    this.setupErroresPorEjercicio();
    this.setupErrores();
    this.setupGrids();
    this.selectFirstBox();
    this.setHoraComienzo();
  }

  setHoraComienzo = () => {
    var time = new Date();
    let timeString = time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    this.setState({ horaComienzo: timeString });
  };

  updateDimensions = () => {
    const width = window.innerWidth;
    this.setState({ width: width });
    console.log("width: ", width);
  };

  resetProcedimientoGrid = (ejercicioIndex: number) => {
    const { selectedGuia } = this.context;
    const { subnivel } = selectedGuia;
    if (!selectedGuia) {
      return;
    }

    const ejercicio = selectedGuia.ejercicios[ejercicioIndex];

    if (!ejercicio) {
      return;
    }

    const { solucion } = ejercicio;
    let solucionLength = solucion.length;

    const baseSolucionGrid = generateProcedimientoGridMultiplicacion(ejercicio);

    if (baseSolucionGrid.length > 0 && subnivel !== "basico") {
      this.updateCurrentGrid("procedimiento");
      this.updateColIndex(solucionLength - 1);
    } else {
      this.updateCurrentGrid("respuesta");
      this.updateRespuestaIndex(solucionLength - 1);
    }

    this.setState({ procedimientoGrid: baseSolucionGrid });
  };

  resetTargetProcedimientoGrid = (ejercicioIndex: number) => {
    const { selectedGuia } = this.context;
    if (!selectedGuia) {
      return;
    }

    let ejercicio = selectedGuia.ejercicios[ejercicioIndex];
    if (!ejercicio) {
      return;
    }

    const targetProcedimientoGrid = generateTargetProcedimientoGridMultiplicacion(
      ejercicio
    );

    console.log("targetProcedimientoGrid");
    console.log(targetProcedimientoGrid);

    this.setState({ targetProcedimientoGrid: targetProcedimientoGrid });
  };

  setupSentido = () => {
    const { sentidoVertical } = this.state;
    const { selectedGuia } = this.context;
    const { subnivel } = selectedGuia;

    if (subnivel == "basico") {
      this.setState({ sentidoVertical: false });
      this.updateCurrentGrid("respuesta");
    }
  };

  //local storage

  getEntrenamientoIdLocalStorage = () => {
    // const { selectedGuia } = this.context;
    // const { titulo, guiaId } = selectedGuia;
    // const id = titulo + "_" + guiaId;

    // return id;

    const { selectedGuia } = this.context;
    const { codigo, id } = selectedGuia;
    const guiaId = codigo + "_" + id;

    return guiaId;
  };

  setupErrores = () => {
    const erroresId = this.getEntrenamientoIdLocalStorage() + "_errores";
    const localStorageErrores = localStorage.getItem(erroresId);

    if (localStorageErrores) {
      console.log("localStorageErrores: " + localStorageErrores);
      this.setState({ errores: parseInt(localStorageErrores) });
    } else {
      console.log("localStorageErrores not found");
      this.setState({ errores: 0 });
    }
  };

  setupCounter = () => {
    // debugger;
    console.log("setting up counter");

    const counterId = this.getEntrenamientoIdLocalStorage() + "_counter";

    console.log(counterId);

    const localStorageCounter = localStorage.getItem(counterId);

    if (localStorageCounter) {
      console.log("localStorageCounter: " + localStorageCounter);
      this.setState({ counter: parseInt(localStorageCounter) });
    } else {
      console.log("localStorageCounter not found, setting default to zer0");
      this.setState({ counter: 0 });
    }
  };

  setupGrids = () => {
    const { selectedGuia } = this.context;
    const { subnivel } = selectedGuia;
    const counterId = this.getEntrenamientoIdLocalStorage() + "_counter";
    const localStorageCounter = localStorage.getItem(counterId);
    let startingIndex;

    if (localStorageCounter) {
      startingIndex = parseInt(localStorageCounter);
    } else {
      startingIndex = 0;
    }

    if (subnivel !== "basico") {
      this.resetProcedimientoGrid(startingIndex);
      this.resetTargetProcedimientoGrid(startingIndex);
    }
  };

  setupErroresPorEjercicio = () => {
    const { selectedGuia } = this.context;
    const erroresPorEjercicioId =
      this.getEntrenamientoIdLocalStorage() + "_erroresPorEjercicio";

    let ejerciciosLength = selectedGuia.ejercicios.length;
    let defaultErroresPE: number[] = [];

    const localStorageDefaultErrores = localStorage.getItem(
      erroresPorEjercicioId
    );
    console.log("localStorageDefaultErrores");
    console.log(localStorageDefaultErrores);
    if (localStorageDefaultErrores) {
      let localStorageDefaultErroresArray = localStorageDefaultErrores
        .substring(1, localStorageDefaultErrores.length - 1)
        .split(",");

      var nums = localStorageDefaultErroresArray.map((str) => parseInt(str));

      console.log("errores por ejercicio");
      console.log(nums);

      this.setState({ erroresPorEjercicio: nums });
    } else {
      console.log("local storage default errores not found, starting with 0");
      for (let i = 0; i < ejerciciosLength; i++) {
        defaultErroresPE.push(0);
      }
      this.setState({ erroresPorEjercicio: defaultErroresPE });
    }
  };

  updateErroresLocalStorage = (errores: string) => {
    const erroresPorEjercicioId =
      this.getEntrenamientoIdLocalStorage() + "_errores";

    localStorage.setItem(erroresPorEjercicioId, errores);
  };

  updateErroresPorEjercicioLocalStorage = (errores: string) => {
    const erroresPorEjercicioId =
      this.getEntrenamientoIdLocalStorage() + "_erroresPorEjercicio";

    localStorage.setItem(erroresPorEjercicioId, errores);
  };

  updateCounterLocalStorage = (count: string) => {
    const counterId = this.getEntrenamientoIdLocalStorage() + "_counter";

    localStorage.setItem(counterId, count);
  };

  switchScreen = (screen: string) => {
    console.log("switch to: ", screen);
    history.push(screen);
  };

  selectFirstBox = () => {
    // add logic to select first box depending on procedimientoGrid.length > 0
    const { counter, direccion } = this.state;
    const { selectedGuia } = this.context;
    const { subnivel } = selectedGuia;
    const ejercicio: Ejercicio = selectedGuia.ejercicios[counter];
    let solucion = ejercicio.solucion;
    let solucionLength = solucion.toString().length;

    window.setTimeout(() => {
      let nextBoxId;
      if (subnivel == "basico") {
        if (direccion == "derecha") {
          nextBoxId = "0";
        } else {
          nextBoxId = (solucionLength - 1).toString();
        }
      } else {
        nextBoxId = "grid_row_0_col_" + (solucionLength - 1);
      }

      let nextBox = document.getElementById(nextBoxId);

      // cuando el ejercicio tiene mas de una fila
      if (nextBox) {
        nextBox.focus();
        // this.updateColIndex(solucionLength - 1);
      } else {
        // cuando el ejercicio tiene solo una fila
        nextBoxId = (solucionLength - 1).toString();
        nextBox = document.getElementById(nextBoxId);
        if (nextBox) {
          nextBox.focus();
        }
      }

      this.updateColIndex(solucionLength - 1);
      this.updateRespuestaIndex(solucionLength - 1);
    }, 500);
  };

  handleSentidoChange = (checked: boolean) => {
    const { respuestaIndex } = this.state;

    window.setTimeout(() => {
      let nextBoxId = respuestaIndex.toString();
      let nextBox = document.getElementById(nextBoxId);

      if (nextBox) {
        nextBox.focus();
      }
    }, 500);
    this.setState({ sentidoVertical: checked });
  };

  getStyles = () => {
    const { width } = this.state;
    let currentStyles;
    if (isMobile || width < 500) {
      currentStyles = stylesResponsive;
    } else {
      currentStyles = styles;
    }

    return currentStyles;
  };

  updateRespuestaIndex = (index: number) => {
    this.setState({ respuestaIndex: index });
  };

  clearRespuesta = (arr: string[]) => {
    let filteredArr = arr.filter((digit) => digit !== "");
    return filteredArr;
  };

  addErrorPorEjercicio = (index: number) => {
    const { erroresPorEjercicio } = this.state;
    let currentEjercicioErrores = erroresPorEjercicio[index];
    let newErrores = erroresPorEjercicio;
    let newErroresCount = currentEjercicioErrores + 1;
    newErrores[index] = newErroresCount;
    this.setState({ erroresPorEjercicio: newErrores });

    this.updateErroresPorEjercicioLocalStorage(JSON.stringify(newErrores));
  };

  selectNextBox = (ejercicioIndex: number) => {
    const { selectedGuia } = this.context;
    const { subnivel } = selectedGuia;
    const { direccion } = this.state;

    let selectedEjercicio = selectedGuia.ejercicios[ejercicioIndex];
    if (!selectedEjercicio) {
      return;
    }
    let solucion = selectedEjercicio.solucion;
    let solucionLength = solucion.toString().length;

    if (!selectedGuia) {
      return;
    }

    let ejercicio = selectedGuia.ejercicios[ejercicioIndex];
    if (!ejercicio) {
      return;
    }

    let operador = ejercicio.operador;
    let nextBoxId;
    let nextBox;
    let lastBoxIndex;
    let firstBoxIndex;

    nextBoxId = "grid_row_0_col_" + (solucionLength - 1);
    nextBox = document.getElementById(nextBoxId);

    if (nextBox) {
      nextBox.focus();
    } else {
      lastBoxIndex = solucionLength - 1;
      firstBoxIndex = 0;

      if (direccion === "izquierda") {
        nextBox = document.getElementById(lastBoxIndex.toString());
      } else {
        nextBox = document.getElementById(firstBoxIndex.toString());
      }

      if (nextBox) {
        nextBox.focus();
      } else {
        nextBoxId = (solucionLength - 1).toString();
        nextBox = document.getElementById(nextBoxId);
        if (nextBox) {
          nextBox.focus();
        }
      }
    }

    const baseGrid = generateProcedimientoGridMultiplicacion(ejercicio);

    if (baseGrid.length > 0 && subnivel !== "basico") {
      this.updateCurrentGrid("procedimiento");
      this.updateColIndex(solucionLength - 1);
    } else {
      this.updateCurrentGrid("respuesta");
      if (subnivel == "basico") {
        if (direccion == "derecha") {
          this.updateRespuestaIndex(0);
        } else {
          this.updateRespuestaIndex(solucionLength - 1);
        }
      } else {
        this.updateRespuestaIndex(solucionLength - 1);
      }
    }
  };

  next = () => {
    const { selectedGuia } = this.context;
    const { subnivel } = selectedGuia;
    const { counter, sentidoVertical } = this.state;
    let newCount = counter + 1;
    let currentGrid;

    if (sentidoVertical) {
      currentGrid = "procedimiento";
    } else {
      currentGrid = "respuesta";
    }

    let ejerciciosLength = selectedGuia.ejercicios.length;
    this.props.resetTimer(counter);
    if (newCount === ejerciciosLength) {
      this.props.stopTimer();
      this.props.stopGeneralTimer();
    } else {
      this.props.stopTimer();
      this.props.startTimer();
    }

    this.setState({ counter: newCount });
    this.updateCounterLocalStorage(newCount.toString());
    this.resetProcedimientoGrid(newCount);
    this.resetTargetProcedimientoGrid(newCount);
    this.updateCurrentGrid(currentGrid);
    this.selectNextBox(newCount);
  };

  resetRespuesta = () => {
    const { selectedGuia } = this.context;
    const { counter } = this.state;
    let solucion = selectedGuia.ejercicios[counter].solucion;
    let solucionLength = solucion.toString().length;
    let defaultRespuesta: string[] = [];
    for (let i = 0; i < solucionLength; i++) {
      defaultRespuesta.push("");
    }

    this.setState({ respuesta: defaultRespuesta });

    this.updateRespuestaIndex(0);
    this.updateRowIndex(0);
    this.updateColIndex(solucionLength - 1);
  };

  evaluateRespuesta = (resp: string[]) => {
    console.log("evaluate respuesta");
    const { selectedGuia } = this.context;
    const { subnivel } = selectedGuia;
    const {
      counter,
      errores,
      procedimientoGrid,
      targetProcedimientoGrid,
    } = this.state;

    let newErrores = errores;

    let solucion = selectedGuia.ejercicios[counter].solucion;

    let concatRespuesta = "";
    for (let i in resp) {
      let digit = resp[i];
      concatRespuesta = concatRespuesta + digit;
    }

    if (concatRespuesta === solucion.toString()) {
      if (procedimientoGrid.length > 0 && subnivel !== "basico") {
        let cleanGrid = cleanProcedimientoGrid(procedimientoGrid);
        let cleanTargetGrid = cleanProcedimientoGrid(targetProcedimientoGrid);

        let match = compareGrids(cleanGrid, cleanTargetGrid);
        // console.log('match: ', match);
        // bug: returns true if cleanGrid is empty
        if (match) {
          window.setTimeout(() => {
            this.resetRespuesta();
            this.next();
          }, 500);
        } else {
          alert("revisa el procedimiento");
        }
      } else {
        window.setTimeout(() => {
          this.resetRespuesta();
          this.next();
        }, 500);
      }
    } else {
      this.addErrorPorEjercicio(counter);
      newErrores = newErrores + 1;
      this.setState({ errores: newErrores });
      this.updateErroresLocalStorage(newErrores.toString());
    }
  };

  handleFocus = (event: any, rowIndex: number, colIndex: number) => {
    this.updateCurrentGrid("procedimiento");
    this.updateRowIndex(rowIndex);
    this.updateColIndex(colIndex);

    if (isBrowser) {
      console.log("browser");
      event.target.select();
    } else {
      console.log("mobile");
      event.target.blur();
      event.preventDefault();
    }
  };

  handleRespuestaFocus = (event: any, respuestaIndex: number) => {
    this.updateCurrentGrid("respuesta");
    this.updateRespuestaIndex(respuestaIndex);

    if (isBrowser) {
      console.log("browser");
      event.target.select();
    } else {
      console.log("mobile");
      event.target.blur();
      event.preventDefault();
    }
  };

  generateRespuestaBoxes = (ejercicio: Ejercicio) => {
    const { selectedGuia } = this.context;
    const { subnivel } = selectedGuia;
    const { solucion } = ejercicio;
    const { respuesta } = this.state;
    const { inputBox, colStyle } = this.getStyles();
    let boxes: JSX.Element[] = [];

    let respString = solucion.toString();
    let escapedRespuesta = respString.replace(/[^0-9]/g, "");

    let styleObj = {
      color: inputBox.color,
      width: inputBox.width,
      marginTop: 10,
    };

    let escapedSolucionLength = solucion.toString().replace(/[^0-9]/g, "")
      .length;

    //returns the length of the longest string in the array
    let longestTermino = Math.max(
      ...ejercicio.columnas.map((el: number) => el.toString().length)
    );

    //adding extra boxes if the solution length is less than of the longest term

    if (subnivel !== "basico") {
      if (longestTermino >= escapedSolucionLength) {
        let difference = longestTermino - escapedSolucionLength;
        for (let i = 0; i <= difference; i++) {
          let box = <div style={colStyle}></div>;
          boxes.push(box);
        }
      }
    }

    for (let i = 0; i < escapedRespuesta.length; i++) {
      let box = (
        <input
          autoComplete="off"
          id={i.toString()}
          key={i}
          onChange={(e) => this.updateRespuesta(e.target.value, i)}
          type="text"
          value={respuesta[i]}
          style={styleObj}
          onFocus={(e) => {
            this.handleRespuestaFocus(e, i);
          }}
        ></input>
      );
      boxes.push(box);
    }
    return boxes;
  };

  getBoxValue = (rowIndex: number, colIndex: number) => {
    const { procedimientoGrid } = this.state;
    let newGrid = procedimientoGrid;
    let currentRow = newGrid[rowIndex];

    if (!currentRow) {
      return "";
    }

    let currentCol = currentRow[colIndex];

    if (!currentCol) {
      return "";
    }
    return currentCol;
  };

  updateRowIndex = (index: number) => {
    this.setState({ rowIndex: index });
  };

  updateColIndex = (index: number) => {
    this.setState({ colIndex: index });
  };

  updateCurrentGrid = (currentGrid: string) => {
    this.setState({ currentGrid: currentGrid });
  };

  updateProcedimientoGrid = (grid: string[][]) => {
    this.setState({ procedimientoGrid: grid });
  };

  updateRespuesta = (resp: string, index: number) => {
    // const index = rindex;
    console.log("index: " + index);

    if (resp.length > 1) {
      let lastIndex = resp.length - 1;
      resp = resp[lastIndex];
    }

    const { selectedGuia } = this.context;
    const { subnivel } = selectedGuia;
    const { counter, respuesta, direccion } = this.state;
    let solucion = selectedGuia.ejercicios[counter].solucion;
    let solucionLength = solucion.toString().length;
    let lastIndex = solucionLength - 1;
    let newRespuesta = respuesta;
    let newDireccion = direccion;
    let nextBoxIndex;
    let filteredRespuesta = resp.match(/\d/g);

    if (filteredRespuesta) {
      newRespuesta[index] = filteredRespuesta.join("");

      // if the user is in the first box from left to right
      // and the next box is empty
      // then direccion is derecha
      if (index === 0 && newRespuesta[1] === "") {
        newDireccion = "derecha";
        this.setState({ direccion: newDireccion });
      }

      if (index === lastIndex && newRespuesta[lastIndex - 1] === "") {
        newDireccion = "izquierda";
        this.setState({ direccion: newDireccion });
      }

      console.log("direccion: " + newDireccion);

      if (newDireccion === "derecha") {
        nextBoxIndex = 1;
      } else {
        nextBoxIndex = -1;
      }

      this.updateRespuestaIndex(index + nextBoxIndex);

      let nextBox = document.getElementById((index + nextBoxIndex).toString());
      if (nextBox) {
        nextBox.focus();
      }
    } else {
      newRespuesta[index] = "";
    }

    this.setState({ respuesta: newRespuesta });

    let escapedRespuesta = this.clearRespuesta(newRespuesta);
    let newRLength = escapedRespuesta.length;

    if (newRLength === solucionLength) {
      this.evaluateRespuesta(newRespuesta);
    }

    return nextBoxIndex;
  };

  updateSolutionGrid = (text: string, rowIndex: number, colIndex: number) => {
    console.log("text: " + text);
    console.log("rowIndex: " + rowIndex);
    console.log("colIndex: " + colIndex);
    const { counter, procedimientoGrid } = this.state;
    const { selectedGuia } = this.context;
    const ejercicio = selectedGuia.ejercicios[counter];
    const { solucion } = ejercicio;
    let baseRows: string[][] = [];

    let newGrid: any[] = [];
    let replaceRow: string[] = [];
    let nextBoxId;
    let nextBox;
    let productoLength = solucion.toString().split("").length - 1;

    let nextRow = procedimientoGrid[rowIndex + 1];
    let nextRowLength;
    if (nextRow) {
      nextRowLength = nextRow.length;
    }

    nextBoxId =
      "grid_row_" + rowIndex.toString() + "_col_" + (colIndex - 1).toString();
    nextBox = document.getElementById(nextBoxId);
    if (text != "") {
      if (nextBox) {
        nextBox.focus();
        // setRowIndex(rowIndex);
        // setColIndex(colIndex - 1);
        this.updateRowIndex(rowIndex);
        this.updateColIndex(colIndex - 1);
      } else {
        let nextColIndex = nextRowLength - 1 - (rowIndex + 1);
        nextBoxId =
          "grid_row_" +
          (rowIndex + 1).toString() +
          "_col_" +
          nextColIndex.toString();
        nextBox = document.getElementById(nextBoxId);
        if (nextBox) {
          nextBox.focus();
          // setRowIndex(rowIndex + 1);
          // setColIndex(nextColIndex);
          this.updateRowIndex(rowIndex + 1);
          this.updateColIndex(nextColIndex);
        } else {
          nextBoxId = productoLength.toString();
          nextBox = document.getElementById(nextBoxId);
          if (nextBox) {
            nextBox.focus();
            this.updateCurrentGrid("respuesta");
            this.updateRespuestaIndex(parseInt(nextBoxId));
            this.updateRowIndex(0);
            this.updateColIndex(solucion.toString().length - 1);
          }
        }
      }
    }

    for (let i = 0; i < procedimientoGrid.length; i++) {
      newGrid.push(procedimientoGrid[i]);
    }

    let rowToUpdate = newGrid[rowIndex];

    for (let i = 0; i < rowToUpdate.length; i++) {
      replaceRow.push(rowToUpdate[i]);
    }

    replaceRow[colIndex] = text;
    newGrid[rowIndex] = replaceRow;
    this.updateProcedimientoGrid(newGrid);
  };

  generateGrid = () => {
    const { counter, sentidoVertical, width } = this.state;
    const { selectedGuia } = this.context;

    let body = <div></div>;

    const {
      ejercicioContainer,
      inputBox,
      ejercicioBasicoContainer,
      colStyle,
    } = this.getStyles();

    if (!selectedGuia) {
      return body;
    }

    const ejercicio = selectedGuia.ejercicios[counter];
    const { solucion, operador, columnas } = ejercicio;

    let baseRows: string[][] = [];

    //returns the length of the longest string in the array
    // let longestTermino = Math.max(...(columnas.map(el => el.toString().length)));
    let productoLength = solucion.toString().length;

    //returns the length of the longest string in the array
    let longestTerminoLength = Math.max(
      ...columnas.map((el: number) => el.toString().length)
    );

    for (let i = 0; i <= columnas.length; i++) {
      //for each termino in the operation, ex: [54, 1]
      let termino = columnas[i];
      let rowArray: string[] = new Array(productoLength);

      for (let t = 0; t < longestTerminoLength; t++) {
        rowArray[t] = "";
      }

      if (termino) {
        let splitTermino = termino.toString().split(""); //54 becomes ['5', '4']

        let productoLastIndex = productoLength - 1;

        //generating label rows
        let labelIndex;

        if (productoLength == longestTerminoLength) {
          labelIndex = productoLength;
        } else {
          labelIndex = productoLastIndex;
        }

        for (let a = labelIndex; a > 0; a--) {
          // ["1"]
          if (splitTermino.length === 0) {
            break;
          }
          let lastNumInTerm = splitTermino.pop();

          rowArray[a] = lastNumInTerm;
        }

        if (i !== 0) {
          // rowArray[0] = operador;
          rowArray[0] = "x";
        } else {
          rowArray[0] = "";
        }
        baseRows.push(rowArray);
      }
    }

    //adding empty columns to match the total length of the solution
    //keyword
    let labelRows = baseRows;

    for (let r in baseRows) {
      let columns = baseRows[r];
      let columnsLength = columns.length;
      let extraBoxes = productoLength - columnsLength;

      if (extraBoxes > 0) {
        for (let i = 0; i <= extraBoxes - 1; i++) {
          let full = "";
          labelRows[r].unshift(full); //adding empty string at the first position
        }
      }
    }

    //creating textboxes below label
    let secondTerm = columnas[1].toString().split("");
    let secondTermLength = columnas[1].toString().length;
    let textBoxGrid: boolean[][] = [];
    let secondTermLastIndex = secondTermLength - 1;

    //show rows only when the last term's digit count is more than 1
    if (secondTermLength > 1) {
      for (let a = secondTermLastIndex; a >= 0; a--) {
        let currentNum = secondTerm[a];
        let multiplicador = parseInt(currentNum);
        let textBoxRow: boolean[] = [];

        let boxNumber = (columnas[0] * multiplicador).toString().length;
        for (let c = 0; c <= boxNumber - 1; c++) {
          textBoxRow.push(true);
        }

        textBoxGrid.push(textBoxRow);
      }

      for (let i = 0; i <= textBoxGrid.length - 1; i++) {
        //adding spaces at the end of the array making the illusion
        //that input boxes are moving to the left
        for (let e = 0; e < i; e++) {
          textBoxGrid[i].push(false);
        }
      }

      //adding empty columns to match the total length of the solution
      for (let r in textBoxGrid) {
        let columns = textBoxGrid[r];
        let columnsLength = columns.length;
        let extraBoxes = productoLength - columnsLength;

        if (extraBoxes > 0) {
          for (let i = 0; i <= extraBoxes - 1; i++) {
            textBoxGrid[r].unshift(false); //adding empty string at the first position
          }
        }
      }
    }

    // console.log('operaciones grid');
    // console.log(procedimientoGrid);

    let boxes = this.generateRespuestaBoxes(ejercicio);

    let grid;

    let lbr = labelRows;
    let tbg = textBoxGrid;
    let bxs = boxes;

    if (sentidoVertical) {
      grid = (
        <div style={ejercicioContainer}>
          <Grid>
            {labelRows.map((
              row: any,
              index: number // row[0]= ['', '5', '3']
            ) => (
              <Row style={{ marginTop: 20 }} key={index}>
                {row.map((column: any, index: number) => (
                  <Col
                    style={colStyle}
                    xs={this.getColSize(row.length)}
                    md={2}
                    key={index}
                  >
                    {column}
                  </Col>
                ))}
              </Row>
            ))}
            {textBoxGrid.map((row: any, rowIndex: number) => (
              <Row style={{ marginTop: 20 }} key={rowIndex}>
                {row.map((full: any, colIndex: number) => (
                  <Col
                    style={colStyle}
                    xs={this.getColSize(row.length)}
                    md={2}
                    key={colIndex}
                  >
                    {full ? (
                      <input
                        id={
                          "grid_row_" +
                          rowIndex.toString() +
                          "_col_" +
                          colIndex.toString()
                        }
                        key={
                          "grid_row_" +
                          rowIndex.toString() +
                          "_col_" +
                          colIndex.toString()
                        }
                        type="text"
                        onChange={(e) =>
                          this.updateSolutionGrid(
                            e.target.value,
                            rowIndex,
                            colIndex
                          )
                        }
                        value={this.getBoxValue(rowIndex, colIndex)}
                        style={inputBox}
                        onFocus={(e) => {
                          this.handleFocus(e, rowIndex, colIndex);
                        }}
                        autoComplete="off"
                      ></input>
                    ) : (
                      <div style={colStyle}></div>
                    )}
                  </Col>
                ))}
              </Row>
            ))}
            <Row style={{ marginTop: 20, borderTop: "5px solid #017894" }}>
              {boxes.map((box: any, index: number) => (
                <Col
                  key={index}
                  style={colStyle}
                  xs={this.getColSize(labelRows[0].length)}
                  md={2}
                >
                  {box}
                </Col>
              ))}
            </Row>
          </Grid>
        </div>
      );
    } else {
      let num1 = columnas[0];
      let num2 = columnas[1];
      let containerWidth;

      if (width < 500) {
        containerWidth = width * 0.85;
      } else {
        containerWidth = 400;
      }

      let containerStyle = {
        ...ejercicioBasicoContainer,
        width: containerWidth,
      };

      grid = (
        <div style={containerStyle}>
          <Grid>
            <Row>
              <Col style={{ width: 50, marginTop: 25 }}>{num1}</Col>
              <Col style={{ width: 50, marginTop: 25 }}>{operador}</Col>
              <Col style={{ width: 50, marginTop: 25 }}>{num2}</Col>
              <Col style={{ width: 50, marginTop: 25 }}>=</Col>
              {boxes.map((box: any, index: number) => (
                <Col key={index}>{box}</Col>
              ))}
            </Row>
          </Grid>
        </div>
      );
    }

    return grid;
  };

  // a grid has 12 spaces in each row
  // 12 divided by the number of columns
  // will return the ideal size for each column
  // such that it will maintain the right proportions
  getColSize = (rowLength: number) => {
    let size = 12 / rowLength;
    return size;
  };

  generateTeclado = () => {
    const { currentGrid, respuestaIndex, rowIndex, colIndex } = this.state;
    let teclado;

    // console.log('currentGrid: ' + currentGrid);

    switch (currentGrid) {
      case "respuesta":
        teclado = (
          <TecladoRespuesta
            respuestaIndex={respuestaIndex}
            updateRespuesta={this.updateRespuesta}
          ></TecladoRespuesta>
        );
        break;
      case "procedimiento":
        teclado = (
          <TecladoProcedimiento
            updateProcedimientoGrid={this.updateSolutionGrid}
            rowIndex={rowIndex}
            colIndex={colIndex}
          ></TecladoProcedimiento>
        );
        break;
      default:
        teclado = (
          <TecladoRespuesta
            respuestaIndex={respuestaIndex}
            updateRespuesta={this.updateRespuesta}
          ></TecladoRespuesta>
        );
        break;
    }

    return teclado;
  };

  generateBody = () => {
    const { selectedGuia } = this.context;
    const { counter } = this.state;
    let ejercicios = selectedGuia.ejercicios;
    let ejerciciosLength = ejercicios.length;
    let body;
    // debugger;
    if (counter < ejerciciosLength) {
      body = this.generateEjercicio();
    } else {
      body = this.generateResultados();
    }
    return body;
  };

  generateResultados = () => {
    const { currentUser, tiemposRespuesta } = this.props;
    const { errores, erroresPorEjercicio, horaComienzo } = this.state;
    const { selectedGuia } = this.context;
    let ejercicios = selectedGuia.ejercicios;

    let frame = (
      <Resultados
        currentUser={currentUser}
        errores={errores}
        tiempos={tiemposRespuesta}
        erroresPorEjercicio={erroresPorEjercicio}
        ejercicios={ejercicios}
        horaComienzo={horaComienzo}
        guia={selectedGuia}
        getUserData={() => console.log("hell oworld")}
      ></Resultados>
    );
    return frame;
  };

  ayuda = () => {
    const {
      procedimientoGrid,
      targetProcedimientoGrid,
      respuesta,
    } = this.state;

    console.log("targetProcedimientoGrid");
    console.log(targetProcedimientoGrid);

    console.log("procedimientoGrid");
    console.log(procedimientoGrid);

    console.log("respuesta");
    console.log(respuesta);
  };

  generateEjercicio = () => {
    const { width, respuestaIndex, errores, counter } = this.state;
    const { gridContainer, gridItemContainer } = this.getStyles();
    const { tiempo } = this.props;
    let body;

    if (width > 500) {
      body = (
        <div>
          {this.generateSwitch()}
          <div style={gridContainer}>
            <Grid fluid>
              <Row>
                <Col xs={3} md={3}>
                  <h2>{tiempo}</h2>
                  <h2>Ej. # {counter + 1}</h2>
                </Col>
                <Col xs={6} md={6}>
                  {this.generateGrid()}
                </Col>
                <Col xs={3} md={3}>
                  {this.generateTeclado()}
                </Col>
              </Row>
            </Grid>
            <Button
              style={{ marginBottom: 50, marginTop: 20 }}
              onClick={() => this.switchScreen("/usuario")}
            >
              Regresar
            </Button>
            {/* <Button style={{ marginBottom: 50 }} onClick={() => this.next()}>
              Siguiente
            </Button> */}
            <Button
              style={{
                marginBottom: 50,
                backgroundColor: "#d9534f",
                color: "white",
              }}
              onClick={() => this.ayuda()}
            >
              Ayuda
            </Button>
          </div>
        </div>
      );
    } else {
      body = (
        <div>
          {this.generateSwitch()}
          <div style={gridContainer}>
            <Grid>
              <Row>
                <div style={gridItemContainer}>
                  <h2>{tiempo}</h2>
                  <h2>Ej. # {counter + 1}</h2>
                </div>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  {this.generateGrid()}
                </Col>
              </Row>
              <Row>
                <div style={gridItemContainer}>{this.generateTeclado()}</div>
              </Row>
            </Grid>
            <Button
              style={{ marginBottom: 50 }}
              onClick={() => this.switchScreen("/usuario")}
            >
              Regresar
            </Button>
            {/* <Button style={{ marginBottom: 50 }} onClick={() => this.next()}>
              Siguiente
            </Button> */}
          </div>
        </div>
      );
    }

    return body;
  };

  generateSwitch = () => {
    const { selectedGuia } = this.context;
    const { subnivel } = selectedGuia;
    const { sentidoVertical, counter, respuestaIndex } = this.state;
    const { switchContainer } = this.getStyles();

    let ejercicio = selectedGuia.ejercicios[counter];

    //returns the length of the shortest string in the array
    let shortestTerminoLength = Math.min(...ejercicio.columnas).toString()
      .length;

    // console.log('respuestaIndex: ');
    // console.log(respuestaIndex);

    // console.log('ejercicio: ');
    // console.log(ejercicio);

    // console.log('shortest term length');
    // console.log(shortestTerminoLength);

    let body;

    if (shortestTerminoLength > 1 || subnivel == "basico") {
      body = (
        <div style={{ marginTop: 20 }}>
          <div style={switchContainer}>
            <h2>
              <span>Direccion: </span>
            </h2>
          </div>
          <h2>{sentidoVertical ? "vertical" : "horizontal"}</h2>
        </div>
      );
    } else {
      body = (
        <div style={{ marginTop: 20 }}>
          <div style={switchContainer}>
            <h2>
              <span>Direccion: </span>
              <Switch
                onChange={this.handleSentidoChange}
                checked={sentidoVertical}
                className="react-switch"
              />
            </h2>
          </div>
          <h2>{sentidoVertical ? "vertical" : "horizontal"}</h2>
        </div>
      );
    }

    return body;
  };

  render() {
    const { container } = this.getStyles();
    return (
      <div style={container}>
        {this.generateBody()}
        {/* <Button style={{ marginTop: 20 }} onClick={() => this.next()}>Siguiente</Button> */}
      </div>
    );
  }
}

const styles = {
  container: {
    height: "100vh",
  } as React.CSSProperties,
  gridContainer: {
    marginTop: 100,
  } as React.CSSProperties,
  ejercicioBasicoContainer: {
    color: "#017894",
    backgroundColor: "#FFFFE0",
    width: 400,
    fontSize: 42,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: 20,
    borderRadius: 20,
  } as React.CSSProperties,
  ejercicioContainer: {
    color: "#017894",
    backgroundColor: "#FFFFE0",
    width: 400,
    fontSize: 42,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    borderRadius: 20,
    padding: 20,
  } as React.CSSProperties,
  gridItemContainer: {
    margin: 0,
  } as React.CSSProperties,
  switchContainer: {
    textAlign: "center",
  } as React.CSSProperties,
  inputBox: {
    width: 50,
    color: "#017894",
    minWidth: 50,
  },
  colStyle: {
    width: 50,
  },
};

const stylesResponsive = {
  container: {
    height: "105vh",
  } as React.CSSProperties,
  ejercicioBasicoContainer: {
    color: "#017894",
    backgroundColor: "#FFFFE0",
    // width: 400,
    fontSize: 32,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: 20,
    borderRadius: 20,
  } as React.CSSProperties,
  ejercicioContainer: {
    color: "#017894",
    backgroundColor: "#FFFFE0",
    fontSize: 32,
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    borderRadius: 20,
    // width: "38vh",
    padding: 20,
  } as React.CSSProperties,
  gridContainer: {
    marginTop: 0,
  } as React.CSSProperties,
  gridItemContainer: {
    margin: "auto",
  } as React.CSSProperties,
  switchContainer: {
    textAlign: "left",
  } as React.CSSProperties,
  inputBox: {
    width: 60,
    color: "#017894",
    minWidth: 60,
  },
  colStyle: {
    width: 60,
  },
};

export default Multiplicacion;

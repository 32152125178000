import React, { Component } from "react";
import { User, defaultUser } from "../data-models/user";
import api from "../api/AxiosAPI";
import Auth from "../api/Auth";
import wiserContext from "../providers/wiserContext";
import LoadingOverlay from "react-loading-overlay-ts";
import Select from "react-select";
import { Asignatura } from "../data-models/asignatura";
import { Asignacion } from "../data-models/asignacion";
import { Grid, Row, Col } from "react-flexbox-grid";
import { Nivel } from "../data-models/nivel";
import { Subnivel } from "../data-models/subnivel";
import {
  GuiaGrupoPorCodigo,
  Guia,
  defaultGuia,
  defaultGuiaGrupoPorCodigo,
} from "../data-models/guia";
import Scheduler from "./Scheduler/Scheduler";
import "../styles/shortGrid.css";

interface IProps {}

interface IState {
  meta: string;
  asignaturas: Asignatura[];
  niveles: Nivel[];
  subniveles: Subnivel[];
  selectedAsignatura: string;
  selectedNivel: string;
  selectedSubnivel: string;
  value: string;
  asignaciones: Asignacion[];
  selectedUsuario: User;
  loading: boolean;
  usuarios: User[];
  subtituloAsignatura: string;
  subtituloNivel: string;
  subtituloSubnivel: string;
  subtituloOperador: string;
  codigoGuia: string;
  operador: string;
  guiasAgrupadas: GuiaGrupoPorCodigo[];
  guias: Guia[];
  dia1: Date;
  dia2: Date;
  dia3: Date;
  dia4: Date;
  dia5: Date;
  dia6: Date;
  dia7: Date;
  dia8: Date;
  dia9: Date;
  dia10: Date;
  dia11: Date;
  showEjercicios: boolean;
}

class Asignaciones extends Component<IProps, IState> {
  static contextType = wiserContext;
  constructor(props: IProps) {
    super(props);

    const today = new Date();
    const day2 = new Date(today);
    const day3 = new Date(today);
    const day4 = new Date(today);
    const day5 = new Date(today);
    const day6 = new Date(today);
    const day7 = new Date(today);
    const day8 = new Date(today);
    const day9 = new Date(today);
    const day10 = new Date(today);
    const day11 = new Date(today);

    day2.setDate(today.getDate() + 1);
    day3.setDate(today.getDate() + 2);
    day4.setDate(today.getDate() + 3);
    day5.setDate(today.getDate() + 4);
    day6.setDate(today.getDate() + 5);
    day7.setDate(today.getDate() + 6);
    day8.setDate(today.getDate() + 7);
    day9.setDate(today.getDate() + 8);
    day10.setDate(today.getDate() + 9);
    day11.setDate(today.getDate() + 10);

    today.setHours(0, 0, 0, 0);
    day2.setHours(0, 0, 0, 0);
    day3.setHours(0, 0, 0, 0);
    day4.setHours(0, 0, 0, 0);
    day5.setHours(0, 0, 0, 0);
    day6.setHours(0, 0, 0, 0);
    day7.setHours(0, 0, 0, 0);
    day8.setHours(0, 0, 0, 0);
    day9.setHours(0, 0, 0, 0);
    day10.setHours(0, 0, 0, 0);
    day11.setHours(0, 0, 0, 0);

    this.state = {
      meta: "",
      asignaturas: [],
      niveles: [],
      subniveles: [],
      selectedAsignatura: "",
      selectedNivel: "",
      selectedSubnivel: "",
      value: "",
      asignaciones: [],
      selectedUsuario: defaultUser,
      loading: false,
      usuarios: [],
      subtituloAsignatura: "M",
      subtituloNivel: "A",
      subtituloOperador: "S",
      subtituloSubnivel: "B",
      codigoGuia: "MASB",
      operador: "suma",
      guiasAgrupadas: [],
      guias: [],
      dia1: today,
      dia2: day2,
      dia3: day3,
      dia4: day4,
      dia5: day5,
      dia6: day6,
      dia7: day7,
      dia8: day8,
      dia9: day9,
      dia10: day10,
      dia11: day11,
      showEjercicios: false,
    };
  }

  componentDidMount() {
    this.getUsuarios();
  }

  previousWeek = () => {
    const { dia1 } = this.state;

    const lastWeek = new Date(
      dia1.getFullYear(),
      dia1.getMonth(),
      dia1.getDate() - 7
    );

    this.setDates(lastWeek);
  };

  nextWeek = () => {
    const { dia1 } = this.state;

    const lastWeek = new Date(
      dia1.getFullYear(),
      dia1.getMonth(),
      dia1.getDate() + 7
    );

    this.setDates(lastWeek);
  };

  setDates = (startDay: Date) => {
    console.log("previous week");

    const day2 = new Date(startDay);
    const day3 = new Date(startDay);
    const day4 = new Date(startDay);
    const day5 = new Date(startDay);
    const day6 = new Date(startDay);
    const day7 = new Date(startDay);
    const day8 = new Date(startDay);
    const day9 = new Date(startDay);
    const day10 = new Date(startDay);
    const day11 = new Date(startDay);

    day2.setDate(startDay.getDate() + 1);
    day3.setDate(startDay.getDate() + 2);
    day4.setDate(startDay.getDate() + 3);
    day5.setDate(startDay.getDate() + 4);
    day6.setDate(startDay.getDate() + 5);
    day7.setDate(startDay.getDate() + 6);
    day8.setDate(startDay.getDate() + 7);
    day9.setDate(startDay.getDate() + 8);
    day10.setDate(startDay.getDate() + 9);
    day11.setDate(startDay.getDate() + 10);

    startDay.setHours(0, 0, 0, 0);
    day2.setHours(0, 0, 0, 0);
    day3.setHours(0, 0, 0, 0);
    day4.setHours(0, 0, 0, 0);
    day5.setHours(0, 0, 0, 0);
    day6.setHours(0, 0, 0, 0);
    day7.setHours(0, 0, 0, 0);
    day8.setHours(0, 0, 0, 0);
    day9.setHours(0, 0, 0, 0);
    day10.setHours(0, 0, 0, 0);
    day11.setHours(0, 0, 0, 0);

    this.setState({
      dia1: startDay,
      dia2: day2,
      dia3: day3,
      dia4: day4,
      dia5: day5,
      dia6: day6,
      dia7: day7,
      dia8: day8,
      dia9: day9,
      dia10: day10,
    });
  };

  getUsuarios = () => {
    this.setState({ loading: true });
    api({
      method: "get",
      url: "/usuarios/FilterUsersByGrupo?grupo=usuario_activo",
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        console.log("usuarios");
        console.log(response);
        this.setState({ loading: false });
        if (response.data[0]) {
          this.setState({ usuarios: response.data });
        }
        this.getAllAsignaturas();
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  getAllAsignaturas = () => {
    let fetchAsignaturas: Asignatura[];
    fetchAsignaturas = [];
    this.setState({ loading: true });
    api({
      method: "get",
      url: "asignaturas/GetAllAsignaturas",
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        this.setState({ loading: false });
        for (var i in response.data) {
          let asignatura = response.data[i];
          fetchAsignaturas.push({
            id: asignatura.id,
            nombre: asignatura.nombre,
            descripcion: asignatura.descripcion,
          });
        }
        this.setState({ asignaturas: fetchAsignaturas });
        this.setState({ selectedAsignatura: fetchAsignaturas[0].nombre });
        this.getAllNiveles();
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  getAllNiveles() {
    let fetchNiveles: Nivel[];
    fetchNiveles = [];
    this.setState({ loading: true });
    api({
      method: "get",
      url: "niveles/GetAllNiveles",
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        this.setState({ loading: false });
        for (var i in response.data) {
          let nivel = response.data[i];
          fetchNiveles.push({
            id: nivel.id,
            nombre: nivel.nombre,
            descripcion: nivel.descripcion,
          });
        }
        this.setState({
          niveles: fetchNiveles,
        });
        this.setState({ selectedNivel: fetchNiveles[0].nombre });

        this.getAllSubniveles();
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  }

  getAllSubniveles() {
    let fetchSubniveles: Subnivel[];
    fetchSubniveles = [];
    this.setState({ loading: true });
    api({
      method: "get",
      url: "subniveles/GetAllSubniveles",
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        this.setState({ loading: false });
        for (var i in response.data) {
          let subnivel = response.data[i];
          fetchSubniveles.push({
            id: subnivel.id,
            nombre: subnivel.nombre,
            descripcion: subnivel.descripcion,
          });
        }
        this.setState({
          subniveles: fetchSubniveles,
        });
        this.setState({ selectedSubnivel: fetchSubniveles[0].nombre });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  }

  groupGuias = (guias: Guia[]) => {
    let agrupadas = this.groupByCodigo(guias);
  };

  groupByCodigo = (guias: Guia[]) => {
    let codigos: string[] = [];
    let agrupadas: GuiaGrupoPorCodigo[] = [];

    for (let a in guias) {
      let guia = guias[a];
      let codigo = guia.codigo;
      codigos.push(codigo);
    }

    let uniqueCodigos = codigos.filter((element, index) => {
      return codigos.indexOf(element) === index;
    });

    for (let a in uniqueCodigos) {
      let codigo = uniqueCodigos[a];

      var guiasFiltradasPorCodigo: Guia[] = guias.filter(
        (el) => el.codigo === codigo
      );

      if (guiasFiltradasPorCodigo.length > 0) {
        let newGrupo: GuiaGrupoPorCodigo = {
          id: new Date().getTime().toString(),
          codigo: codigo,
          titulo: guiasFiltradasPorCodigo[0].titulo,
          operador: guiasFiltradasPorCodigo[0].operador,
          asignatura: guiasFiltradasPorCodigo[0].asignatura,
          nivel: guiasFiltradasPorCodigo[0].nivel,
          subnivel: guiasFiltradasPorCodigo[0].subnivel,
          primerTerminoDesde: guiasFiltradasPorCodigo[0].primerTerminoDesde,
          primerTerminoHasta: guiasFiltradasPorCodigo[0].primerTerminoHasta,
          segundoTerminoDesde: guiasFiltradasPorCodigo[0].segundoTerminoDesde,
          segundoTerminoHasta: guiasFiltradasPorCodigo[0].segundoTerminoHasta,
          cantidadEjercicios: guiasFiltradasPorCodigo[0].cantidadEjercicios,
          guias: guiasFiltradasPorCodigo,
        };

        agrupadas.push(newGrupo);
      }
    }

    console.log("grupos");
    console.log(agrupadas);

    this.setState({ guiasAgrupadas: agrupadas });
  };

  getUserGuias = (selectedUsuario: User) => {
    let fetchGuias: Guia[] = [];
    this.setState({ loading: true });

    api({
      method: "get",
      url: "guias/FilterGuiasPendientes?usuario=" + selectedUsuario.email,
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        this.setState({ loading: false });
        for (var i in response.data) {
          let guia: Guia = response.data[i];
          fetchGuias.push(guia);
        }
        this.setState({
          meta: selectedUsuario.meta!,
          selectedUsuario: selectedUsuario,
        });

        this.groupGuias(fetchGuias);
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  selectUser = (e: any) => {
    const { usuarios } = this.state;
    if (e) {
      let id = e.value;
      if (usuarios.length > 0) {
        let selectedUsuario = usuarios.find(
          (element: User) => element.id === id
        );
        console.log("selectedUsuario");
        console.log(selectedUsuario);
        if (selectedUsuario) {
          this.getUserGuias(selectedUsuario);
        }
      }
    }
  };

  generateUserOptions = () => {
    const { usuarios } = this.state;
    let results: any = [];
    for (let i in usuarios) {
      results.push({
        value: usuarios[i].id,
        label: usuarios[i].nombre + " " + usuarios[i].apellido,
      });
    }
    return results;
  };

  saveUserData = (user: User) => {
    this.setState({ loading: true });
    api({
      method: "put",
      url: "/usuarios/SaveUser",
      data: user,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log("user saved successfully");
        alert("Cambios guardados exitosamente");
        console.log(response);
        this.getUsuarios();
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  saveGuia = (guia: Guia) => {
    this.setState({ loading: true });
    api({
      method: "post",
      url: "/guias/SaveGuia",
      data: guia,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log("guia saved successfully");
        // alert("Cambios guardados exitosamente");
        console.log(response);
        // this.getUsuarios();
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  deleteGuia = (id: string) => {
    this.setState({ loading: true });
    api({
      method: "delete",
      url: "/guias/DeleteGuia?id=" + id,
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        this.setState({ loading: false });
        console.log("Guia deleted successfully");
        alert("Guia eliminada exitosamente");
        console.log(response);
        // this.getUsuarios();
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  submit = () => {
    const { asignaciones, selectedUsuario, meta, guiasAgrupadas } = this.state;

    console.log("Guias Agrupadas");
    console.log(guiasAgrupadas);

    let guias: Guia[] = [];

    for (let i = 0; i < guiasAgrupadas.length; i++) {
      let grupo = guiasAgrupadas[i];
      let numGuias = grupo.guias.length;
      console.log("grupo");
      console.log(grupo);
      for (let a = 0; a < numGuias; a++) {
        let guia = grupo.guias[a];
        guias.push(guia);
      }
    }

    console.log("guias");
    console.log(guias);

    console.log("for loop savng guias");
    for (let i = 0; i < guias.length; i++) {
      let currentGuia = guias[i];
      if (currentGuia.id === "") {
        this.saveGuia(currentGuia);
      }

      if (i === guias.length - 1) {
        alert("Guias guardadas exitosamente");
      }
    }

    this.setState({ guiasAgrupadas: guiasAgrupadas });

    // console.log("getting guias");
    // this.getUserGuias(selectedUsuario);
  };

  generateScheduler = () => {
    console.log("generate scheduler");
    const {
      selectedUsuario,
      guias,
      guiasAgrupadas,
      asignaturas,
      niveles,
      subniveles,
      dia1,
      dia2,
      dia3,
      dia4,
      dia5,
      dia6,
      dia7,
      dia8,
      dia9,
      dia10,
    } = this.state;
    let scheduler;

    // debugger;
    if (selectedUsuario.id !== "") {
      scheduler = guiasAgrupadas.map((guia, indexGuia) => (
        <Scheduler
          key={indexGuia}
          deleteGuia={this.deleteGuia}
          selectedUsuario={selectedUsuario}
          guiasAgrupadas={guiasAgrupadas}
          asignaturas={asignaturas}
          niveles={niveles}
          subniveles={subniveles}
          updateGuias={this.updateGuias}
          dia1={dia1}
          dia2={dia2}
          dia3={dia3}
          dia4={dia4}
          dia5={dia5}
          dia6={dia6}
          dia7={dia7}
          dia8={dia8}
          dia9={dia9}
          dia10={dia10}
          indexGuia={indexGuia}
        ></Scheduler>
      ));
    }
    return scheduler;
  };

  getDayLabel = (day: Date) => {
    const DIAS = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    let dayLabel = DIAS[day.getDay()];
    return dayLabel + " " + day.getDate();
  };

  getDateMonthFromTimestamp = (dateTimestamp: number) => {
    var newDate = new Date(dateTimestamp);
    newDate.setHours(0, 0, 0, 0);
    let date = newDate.getDate().toString();
    let month = newDate.getMonth().toString();
    let resultado = date + month;
    return resultado;
  };

  getDateMonthFromTimestampLabel = (dateTimestamp: number) => {
    var newDate = new Date(dateTimestamp);
    newDate.setHours(0, 0, 0, 0);
    let date = newDate.getDate().toString();
    let month = (newDate.getMonth() + 1).toString();
    let resultado = date + " " + month;
    return resultado;
  };

  updateGuias = (guias: GuiaGrupoPorCodigo[]) => {
    console.log("update guias");
    console.log(guias);
    this.setState({ guiasAgrupadas: guias });
  };

  showEjercicios = () => {
    const { showEjercicios } = this.state;
    let newShow = !showEjercicios;
    this.setState({ showEjercicios: newShow });
  };

  addGuia = () => {
    console.log("add guia");
    const {
      guias,
      codigoGuia,
      operador,
      selectedAsignatura,
      selectedNivel,
      selectedSubnivel,
      selectedUsuario,
      guiasAgrupadas,
    } = this.state;

    let now = Date.now().toString();
    if (selectedUsuario.id !== "") {
      let newGrupo: GuiaGrupoPorCodigo = {
        id: now,
        codigo: now + "-" + codigoGuia,
        titulo: codigoGuia,
        operador: operador,
        asignatura: selectedAsignatura,
        nivel: selectedNivel,
        subnivel: selectedSubnivel,
        primerTerminoDesde: "0",
        primerTerminoHasta: "0",
        segundoTerminoDesde: "0",
        segundoTerminoHasta: "0",
        cantidadEjercicios: "0",
        guias: [],
      };

      let guiasPorCodigo = guiasAgrupadas;
      guiasPorCodigo.push(newGrupo);
      this.setState({ guiasAgrupadas: guiasPorCodigo });

      // let userGuias = guias;
      // userGuias.push(basicGuia);
      // this.setState({ guias: userGuias });
    }
  };

  renderEjercicios = () => {
    const { guiasAgrupadas, showEjercicios } = this.state;
    let element;
    if (showEjercicios) {
      element = guiasAgrupadas.map((grupo, indexGuia) => (
        <Grid key={"grid-" + indexGuia}>
          <Row key={"guia-" + indexGuia}>
            <Col>{grupo.codigo}</Col>
          </Row>
          {grupo.guias.map((entrenamiento, indexEntrenamiento) => (
            <div key={"entrenamiento-div-" + indexEntrenamiento}>
              <Row key={"entrenamiento-" + indexEntrenamiento}>
                <Col>
                  Asignada{" "}
                  {this.getDateMonthFromTimestampLabel(
                    new Date(entrenamiento.fechaProgramada).getTime()
                  )}
                </Col>
              </Row>
              {entrenamiento.ejercicios.map((ejercicio, ejercicioIndex) => (
                <Row key={"entrenamiento-" + ejercicioIndex}>
                  <Col xs={2} md={2}>
                    Ejercicio {ejercicio.id}:
                  </Col>
                  <Col xs={1} md={1}>
                    {ejercicio.columnas[0]}
                  </Col>
                  <Col xs={1} md={1}>
                    {ejercicio.operador}
                  </Col>
                  <Col xs={1} md={1}>
                    {ejercicio.columnas[1]}
                  </Col>
                  <Col xs={1} md={1}>
                    =
                  </Col>
                </Row>
              ))}
            </div>
          ))}
        </Grid>
      ));
    }

    return element;
  };

  render() {
    const { formContainer } = styles;
    const {
      meta,
      selectedUsuario,
      loading,
      asignaturas,
      niveles,
      subniveles,
      guiasAgrupadas,
      dia1,
      dia2,
      dia3,
      dia4,
      dia5,
      dia6,
      dia7,
      dia8,
      dia9,
      dia10,
      dia11,
    } = this.state;

    let scheduler = this.generateScheduler();
    return (
      <LoadingOverlay active={loading} spinner text="Cargando...">
        <form className="ui form">
          <div className="five fields">
            <div className="field">
              <label>Usuario</label>
              <Select
                onChange={(e) => this.selectUser(e)}
                className="basic-single"
                classNamePrefix="select"
                // defaultValue={{ value: usuarios[0].id, label: usuarios[0].nombre }}
                isDisabled={false}
                placeholder={"usuario"}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={this.generateUserOptions()}
              />
            </div>
            <div className="field">
              <label>Meta</label>
              <input
                onChange={(e) => this.setState({ meta: e.target.value })}
                type="text"
                value={meta}
                placeholder="Meta"
              ></input>
            </div>
            <div className="field">
              <label>Semana Anterior</label>
              <input
                className="ui primary button"
                type="button"
                onClick={() => this.previousWeek()}
                value={"<--"}
              />
            </div>
            <div className="field">
              <label>Fecha</label>
              <div>
                {dia1.getDate() +
                  "/" +
                  (dia1.getMonth() + 1) +
                  "/" +
                  dia1.getFullYear()}
              </div>
            </div>
            <div className="field">
              <label>Semana Siguiente</label>
              <input
                className="ui primary button"
                type="button"
                onClick={() => this.nextWeek()}
                value={"-->"}
              />
            </div>
          </div>
        </form>

        <div>
          <div>
            <form className="ui form">
              <Grid style={{ marginLeft: 0, marginRight: 0 }}>
                <Row>
                  <Col xs={1} md={1}>
                    Asignatura
                  </Col>
                  <Col xs={1} md={1}>
                    Nivel
                  </Col>
                  <Col xs={1} md={1}>
                    Operador
                  </Col>
                  <Col xs={1} md={1}>
                    Subnivel
                  </Col>
                  <Col xs={1} md={1}>
                    Ejercicios
                  </Col>
                  <Col xs={1} md={1}>
                    Rango 1 0
                  </Col>
                  <Col xs={1} md={1}>
                    Rango 1 1
                  </Col>
                  <Col xs={1} md={1}>
                    Rango 2 0
                  </Col>
                  <Col xs={1} md={1}>
                    Rango 2 1
                  </Col>
                  <Col xs={1} md={1}>
                    {this.getDayLabel(dia1)}
                  </Col>
                  <Col xs={1} md={1}>
                    {this.getDayLabel(dia2)}
                  </Col>
                  <Col xs={1} md={1}>
                    {this.getDayLabel(dia3)}
                  </Col>
                  <Col xs={1} md={1}>
                    {this.getDayLabel(dia4)}
                  </Col>
                  <Col xs={1} md={1}>
                    {this.getDayLabel(dia5)}
                  </Col>
                  <Col xs={1} md={1}>
                    {this.getDayLabel(dia6)}
                  </Col>
                  <Col xs={1} md={1}>
                    {this.getDayLabel(dia7)}
                  </Col>
                  <Col xs={1} md={1}>
                    {this.getDayLabel(dia8)}
                  </Col>
                  <Col xs={1} md={1}>
                    {this.getDayLabel(dia9)}
                  </Col>
                  <Col xs={1} md={1}>
                    {this.getDayLabel(dia10)}
                  </Col>
                </Row>
              </Grid>

              {scheduler}

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <input
                  className="ui primary button"
                  type="button"
                  onClick={() => this.addGuia()}
                  value="Agregar"
                />
              </div>

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <input
                  className="ui primary button"
                  type="button"
                  onClick={() => this.showEjercicios()}
                  value="Ver Ejercicios"
                />
              </div>

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <input
                  className="ui primary button"
                  type="button"
                  onClick={() => this.submit()}
                  value="Guardar"
                />
              </div>

              {this.renderEjercicios()}
            </form>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

const styles = {
  container: {
    margin: 20,
  },
  formContainer: {
    marginTop: 20,
  },
};

export default Asignaciones;

import React, { useState, useEffect } from "react";
import api from "../../api/AxiosAPI";
import Auth from "../../api/Auth";
import { User } from "../../data-models/user";
import { Button } from "semantic-ui-react";
import { Grupo } from "../../data-models/grupo";
import { Unidad } from "../../data-models/unidad";
import { Nivel } from "../../data-models/nivel";
import { Subnivel } from "../../data-models/subnivel";
import { paises } from "../../constants/paises";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { alpha } from "@material-ui/core/styles";
import LoadingOverlay from "react-loading-overlay-ts";

function UsuarioDetalles(props: {
  user: User;
  switchScreen: any;
  saveUserData: any;
}) {
  const { user } = props;
  const { formContainer } = styles;
  const [nombre, setNombre] = useState(user.nombre);
  const [apellido, setApellido] = useState(user.apellido);
  const [pedagogoAsignado, setPedagogoAsignado] = useState(
    user.pedagogoAsignado
  );
  const [email, setEmail] = useState(user.email);
  const [telefono, setTelefono] = useState(user.telefono);
  const [status, setStatus] = useState(user.status);
  const [fechaInscripcion, setFechaInscripcion] = useState(
    new Date(user.fechaInscripcion)
  );
  const [fechaNacimiento, setFechaNacimiento] = useState(
    new Date(user.fechaNacimiento)
  );
  const [gradoInicial, setGradoInicial] = useState(user.gradoInicial);
  const [nivel, setNivel] = useState(user.nivel);
  const [subnivel, setSubnivel] = useState(user.subnivel);
  const [grupo, setGrupo] = useState(user.grupo);
  const [representante, setRepresentante] = useState(user.representante);
  const [unidad, setUnidad] = useState(user.unidad);
  const [pais, setPais] = useState(user.pais);
  const [editEnabled, setEditEnabled] = useState(true);
  const [showExtraFields, setShowExtraFields] = useState(false);
  const [pedagogos, setPedagogos] = useState([]);
  const [representantes, setRepresentantes] = useState([]);
  const [meta, setMeta] = useState(user.meta);

  const [grupos, setGrupos] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [niveles, setNiveles] = useState([]);
  const [subniveles, setSubniveles] = useState([]);

  const [loading, setLoading] = useState(false);

  console.log("user");
  console.log(user);

  useEffect(() => {
    selectGrupo(user.grupo);
    getPedagogos();
    getRepresentantes();
  }, []);

  const getPedagogos = () => {
    setLoading(true);
    api({
      method: "get",
      url: "/usuarios/FilterUsersByGrupo?grupo=pedagogo",
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        console.log("pedagogos");
        console.log(response);
        setLoading(false);
        if (response.data[0]) {
          setPedagogos(response.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  const getRepresentantes = () => {
    setLoading(true);
    api({
      method: "get",
      url: "/usuarios/FilterUsersByGrupo?grupo=representante",
      headers: { Authorization: Auth.getToken() },
    })
      .then((response) => {
        setLoading(false);
        console.log("representantes");
        console.log(response);
        if (response.data[0]) {
          setRepresentantes(response.data);
        }
        getAllGrupos();
      })
      .catch((error) => {
        setLoading(false);
        console.error("ERROR");
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  const getAllGrupos = () => {
    let fetchGrupos: Grupo[];
    fetchGrupos = [];
    setLoading(true);
    api({
      method: "get",
      url: "grupos/GetAllGrupos",
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        setLoading(false);
        for (var i in response.data) {
          let grupo = response.data[i];
          fetchGrupos.push({
            id: grupo.id,
            nombre: grupo.nombre,
            descripcion: grupo.descripcion,
          });
        }
        setGrupos(fetchGrupos);
        getAllNiveles();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  const getAllNiveles = () => {
    let fetchNiveles: Nivel[];
    fetchNiveles = [];
    setLoading(true);
    api({
      method: "get",
      url: "niveles/GetAllNiveles",
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        setLoading(true);
        // console.log('Niveles');
        // console.log(response.data);
        for (var i in response.data) {
          let nivel = response.data[i];
          fetchNiveles.push({
            id: nivel.id,
            nombre: nivel.nombre,
            descripcion: nivel.descripcion,
          });
        }
        setNiveles(fetchNiveles);
        getAllSubniveles();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  const getAllSubniveles = () => {
    // console.log('getting subniveles');
    let fetchSubniveles: Subnivel[];
    fetchSubniveles = [];
    setLoading(true);
    api({
      method: "get",
      url: "subniveles/GetAllSubniveles",
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        setLoading(false);
        // console.log('subniveles');
        // console.log(response.data);
        for (var i in response.data) {
          let subnivel = response.data[i];
          fetchSubniveles.push({
            id: subnivel.id,
            nombre: subnivel.nombre,
            descripcion: subnivel.descripcion,
          });
        }
        setSubniveles(fetchSubniveles);
        getAllUnidades();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response) {
          if (error.response.status == 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  const getAllUnidades = () => {
    let fetchUnidades: Unidad[];
    fetchUnidades = [];
    setLoading(true);
    api({
      method: "get",
      url: "unidades/GetAllUnidades",
      headers: {
        Authorization: Auth.getToken(),
      },
    })
      .then((response) => {
        // console.log('Unidades');
        // console.log(response.data);
        setLoading(false);
        for (var i in response.data) {
          let unidad = response.data[i];
          fetchUnidades.push({
            id: unidad.id,
            nombre: unidad.nombre,
            descripcion: unidad.descripcion,
          });
        }
        setUnidades(fetchUnidades);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert("Sesion ha expirado. Favor salir y volver a entrar");
          }
        } else {
          console.log("error, you messed up");
        }
      });
  };

  const submit = () => {
    let user: User;
    console.log("grupo");
    console.log(grupo);
    if (grupo === "usuario_activo" || grupo === "usuario_pasivo") {
      let fechaInscripcionTimestamp = fechaInscripcion!.getTime();
      let fechaNacimientoTimestamp = fechaNacimiento!.getTime();

      user = {
        id: props.user.id,
        nombre: nombre,
        apellido: apellido,
        email: email,
        fechaInscripcion: fechaInscripcionTimestamp,
        fechaNacimiento: fechaNacimientoTimestamp,
        gradoInicial: gradoInicial,
        grupo: grupo,
        nivel: nivel,
        subnivel: subnivel,
        pais: pais,
        pedagogoAsignado: pedagogoAsignado,
        representante: representante,
        status: status,
        telefono: telefono,
        unidad: unidad,
        meta: meta,
        guias: props.user.guias,
      };
    } else {
      user = {
        nombre: nombre,
        apellido: apellido,
        email: email,
        grupo: grupo,
        pais: pais,
        status: status,
        telefono: telefono,
      };
    }

    console.log("user");
    console.log(user);
    props.saveUserData(user);
  };

  const updateEditEnabled = () => {
    setEditEnabled(!editEnabled);
  };

  const selectGrupo = (selection: string) => {
    setGrupo(selection);

    switch (selection) {
      case "usuario_activo":
        setShowExtraFields(true);
        break;
      case "usuario_pasivo":
        setShowExtraFields(true);
        break;
      default:
        setShowExtraFields(false);
        break;
    }
  };

  const generateExtraFields = () => {
    let extraFields;
    if (!showExtraFields) {
      return extraFields;
    }

    extraFields = (
      <div>
        <div className="five fields">
          <div className="field">
            <label>Fecha de inscripción</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disableFuture
                openTo="year"
                format="dd/MM/yyyy"
                views={["year", "month", "date"]}
                value={fechaInscripcion}
                onChange={(e) => setFechaInscripcion(e)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="field">
            <label>Fecha de nacimiento</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disableFuture
                openTo="year"
                format="dd/MM/yyyy"
                views={["year", "month", "date"]}
                value={fechaNacimiento}
                onChange={(e) => setFechaNacimiento(e)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="field">
            <label>Grado escolar inicial</label>
            <select
              value={gradoInicial}
              disabled={editEnabled}
              onChange={(e) => setGradoInicial(e.target.value)}
              className="ui dropdown"
            >
              <option key={"primero"} value={"primero"}>
                Primero
              </option>
              <option key={"segundo"} value={"segundo"}>
                Segundo
              </option>
              <option key={"tercero"} value={"tercero"}>
                Tercero
              </option>
              <option key={"cuarto"} value={"cuarto"}>
                Cuarto
              </option>
              <option key={"quinto"} value={"quinto"}>
                Quinto
              </option>
              <option key={"sexto"} value={"sexto"}>
                Sexto
              </option>
              <option key={"septimo"} value={"septimo"}>
                Septimo
              </option>
              <option key={"octavo"} value={"octavo"}>
                Octavo
              </option>
              <option key={"noveno"} value={"noveno"}>
                Noveno
              </option>
              <option key={"decimo"} value={"decimo"}>
                Décimo
              </option>
              <option key={"onceavo"} value={"onceavo"}>
                Onceavo
              </option>
            </select>
          </div>
          <div className="field">
            <label>Nivel</label>
            <select
              value={nivel}
              disabled={editEnabled}
              onChange={(e) => setNivel(e.target.value)}
              className="ui dropdown"
            >
              {niveles.map((nivel) => (
                <option key={nivel.id} value={nivel.nombre}>
                  {nivel.descripcion}
                </option>
              ))}
            </select>
          </div>
          <div className="field">
            <label>Subnivel</label>
            <select
              value={subnivel}
              disabled={editEnabled}
              onChange={(e) => setSubnivel(e.target.value)}
              className="ui dropdown"
            >
              {subniveles.map((snv) => (
                <option key={snv.id} value={snv.nombre}>
                  {snv.descripcion}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="four fields">
          <div className="field">
            <label>Representante</label>
            <select
              value={representante}
              disabled={editEnabled}
              onChange={(e) => setRepresentante(e.target.value)}
              className="ui dropdown"
            >
              {representantes.map((representante) => (
                <option
                  key={representante.id}
                  value={representante.nombre + " " + representante.apellido}
                >
                  {representante.nombre + " " + representante.apellido}
                </option>
              ))}
            </select>
          </div>
          <div className="field">
            <label>Unidad</label>
            <select
              value={unidad}
              disabled={editEnabled}
              onChange={(e) => setUnidad(e.target.value)}
              className="ui dropdown"
            >
              {unidades.map((unidad) => (
                <option key={unidad.id} value={unidad.nombre}>
                  {unidad.descripcion}
                </option>
              ))}
            </select>
          </div>
          <div className="field">
            <label>Pedagogo asignado</label>
            <select
              value={pedagogoAsignado}
              disabled={editEnabled}
              onChange={(e) => setPedagogoAsignado(e.target.value)}
              className="ui dropdown"
            >
              {pedagogos.map((pedagogo) => (
                <option
                  key={pedagogo.id}
                  value={pedagogo.nombre + " " + pedagogo.apellido}
                >
                  {pedagogo.nombre + " " + pedagogo.apellido}
                </option>
              ))}
            </select>
          </div>
          <div className="field">
            <label>Meta</label>
            <input
              disabled={editEnabled}
              onChange={(e) => setMeta(e.target.value)}
              type="text"
              value={meta}
              placeholder="Meta"
            ></input>
          </div>
        </div>
      </div>
    );
    return extraFields;
  };

  return (
    <LoadingOverlay active={loading} spinner text="Cargando...">
      <div>
        <Button
          onClick={() => props.switchScreen("usuarios")}
          className="ui primary button"
        >
          Regresar
        </Button>
        <div style={formContainer}>
          <form className="ui form">
            <div className="three fields">
              <div className="field">
                <label>Nombre</label>
                <input
                  disabled={editEnabled}
                  onChange={(e) => setNombre(e.target.value)}
                  type="text"
                  value={nombre}
                  placeholder="Primer Nombre"
                ></input>
              </div>
              <div className="field">
                <label>Apellido</label>
                <input
                  disabled={editEnabled}
                  onChange={(e) => setApellido(e.target.value)}
                  type="text"
                  value={apellido}
                  placeholder="Apellido"
                ></input>
              </div>
              <div className="field">
                <label>Pais</label>
                <select
                  value={pais}
                  disabled={editEnabled}
                  onChange={(e) => setPais(e.target.value)}
                  className="ui dropdown"
                >
                  {paises.map((pais) => (
                    <option key={pais.code} value={pais.code}>
                      {pais.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="four fields">
              <div className="field">
                <label>Email</label>
                <input
                  disabled={editEnabled}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  value={email}
                  placeholder="john@doe.com"
                ></input>
              </div>
              <div className="field">
                <label>Teléfono</label>
                <input
                  disabled={editEnabled}
                  onChange={(e) => setTelefono(e.target.value)}
                  type="text"
                  value={telefono}
                  placeholder="22222222"
                ></input>
              </div>
              <div className="field">
                <label>Grupo</label>
                <select
                  value={grupo}
                  disabled={editEnabled}
                  onChange={(e) => selectGrupo(e.target.value)}
                  className="ui dropdown"
                >
                  {grupos.map((grupo) => (
                    <option key={grupo.id} value={grupo.nombre}>
                      {grupo.descripcion}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {generateExtraFields()}
            <input
              className="ui button"
              type="button"
              onClick={() => submit()}
              value="Guardar"
            />
          </form>
        </div>
        <Button onClick={() => updateEditEnabled()}>Modificar</Button>
      </div>
    </LoadingOverlay>
  );
}

const styles = {
  container: {
    margin: 20,
  },
  formContainer: {
    marginTop: 20,
  },
};

export default UsuarioDetalles;

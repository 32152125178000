import { Ejercicio } from '../data-models/ejercicio';

export const generateProcedimientoGridDivision = (ej: Ejercicio) => {
    let dividendo = ej.columnas[0];
    let divisor = ej.columnas[1];
    let solucion = ej.solucion;
    let dividendoLength = dividendo.toString().split("").length;
    let solucionLength = solucion.toString().split("").length;
    let procedimientoGrid: string[][] = [];
    let numberOfRows = (solucionLength * 2) - 1;
    for (let i = 0; i < numberOfRows; i++) {
        let row: string[] = [];

        if (i === 0) {
            let cociente = solucion.toString().split("");
            let firstDigit = parseInt(cociente[0]);
            let limit = (firstDigit * divisor).toString().length;

            for (let a = 0; a < limit; a++) {
                row.push('');
            }
        } else {
            //keyword, dividendo length must change
            for (let a = 0; a < dividendoLength; a++) {
                row.push('');
            }
        }

        procedimientoGrid.push(row);
    }
    // console.log('procedimientoGrid');
    // console.log(procedimientoGrid);
    return procedimientoGrid;
}

export const generateTargetProcedimientoGridDivision = (ej: Ejercicio) => {
    let dividendo = ej.columnas[0];
    let divisor = ej.columnas[1];
    let cociente = ej.solucion;
    let dividendoLength = dividendo.toString().split("").length;
    let dividendoSplit = dividendo.toString().split("");
    let cocienteLength = cociente.toString().split("").length;
    let cocienteSplit = cociente.toString().split("");
    let targetProcedimientoGrid: string[][] = [];
    let numberOfRows = (cocienteLength * 2) - 1;
    let dividendoSubstr = '';
    let digitIndex = 0;
    let mul;
    let dif;
    for (let i = 0; i < numberOfRows; i++) {
        let row: string[] = [];

        // if row is even, multiply, else, substract
        if ((i % 2) === 0) {
            // row value is equal to divisor multiplied by the 
            // digit of the quotient with index = (i/2) where i is the row index
            let term1 = cocienteSplit[i / 2];
            let term2 = divisor;
            mul = parseInt(term1) * term2;
            let mulSplit = mul.toString().split("");
            for (let a = 0; a < mulSplit.length; a++) {
                row.push(mulSplit[a]);
            }
        } else {
            // row value is equal to the difference of these 2 numbers:
            // num1 is the first N digits of the dividendo such that its 
            // remainder in relation to the divisor is >= 1

            // num2 is the divisor multiplied by the digit of the cociente
            // with index = (i+1) / 2, where i is the row index (starting at 0)
            // index will always be a positive int since this only applies for even rows


            // let currentRow = i;
            // debugger;
            for (let b = 0; b < dividendoLength; b++) {
                dividendoSubstr = dividendoSubstr + dividendoSplit[b];
                let dividendoSubstrInt = parseInt(dividendoSubstr);
                if ((dividendoSubstrInt / divisor) >= 1) {

                    digitIndex = ((i + 1) / 2);
                    let num2String = cocienteSplit[digitIndex]

                    if (num2String === "0") {
                        num2String = cocienteSplit[digitIndex - 1]
                    }

                    // let num2Int = divisor * parseInt(num2String);

                    // dif = dividendoSubstrInt - num2Int;
                    dif = dividendoSubstrInt - mul;
                    let difSplit = dif.toString().split("");

                    if (dividendoSplit[b + 1]) {
                        //esto es lo mismo que bajar el siguiente digito del dividendo
                        difSplit.push(dividendoSplit[b + 1]);
                    }

                    for (let d = 0; d < difSplit.length; d++) {
                        row.push(difSplit[d]);
                    }

                    break;
                }
            }
        }

        targetProcedimientoGrid.push(row);
    }
    // console.log('target procedimiento grid division');
    // console.log(targetProcedimientoGrid);
    return targetProcedimientoGrid;
}
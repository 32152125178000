import React, { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react'

type Props = {
    num: number,
    submit: any,
}

const Tecla = (
    props: Props
) => {
    const { container, tecla } = styles;
    const { num, submit } = props;
    return (
        <div style={container}>
            <button style={tecla} onClick={() => submit(num.toString())}>{props.num}</button>
        </div>
    );
};

const styles = {
    container: {
        marginTop: 20,
        marginLeft: 5,
        marginRight: 5,
        width: 50,
        height: 50,
        fontSize: 36
    } as React.CSSProperties,
    tecla: {
        backgroundColor: '#f7ba02',
        color: '#017894',
        cursor: 'pointer',
        height: 50,
        width: 50,
        // paddingTop: 15,
        fontWeight: 'bold',
        borderRadius: 5,
    } as React.CSSProperties
}

export default Tecla;
import React, { Component } from 'react';
import api from '../api/AxiosAPI';
import Auth from '../api/Auth';
import { Menu, Button } from 'semantic-ui-react'
import { history } from './history';
import { User } from '../data-models/user';
import { Grupo } from '../data-models/grupo';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AgregarItem from './AgregarItem';
import LoadingOverlay from 'react-loading-overlay-ts';

interface IProps {
}

interface IState {
    status_message: string,
    selectedScreen: string,
    showAddItem: boolean,
    grupos: Grupo[],
    newGrupoDescripcion: string,
    loading: boolean
}

class Grupos extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            status_message: '',
            selectedScreen: '',
            showAddItem: false,
            grupos: [],
            newGrupoDescripcion: '',
            loading: false
        };
    }

    componentDidMount() {
        this.getAllGrupos();
    }

    getAllGrupos() {
        console.log('getting grupos');
        let fetchGrupos: Grupo[];
        fetchGrupos = [];
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'grupos/GetAllGrupos',
            headers: {
                Authorization: Auth.getToken(),
            }
        }).then((response) => {
            this.setState({ loading: false });
            console.log('response data');
            console.log(response.data);
            for (var i in response.data) {
                let grupo = response.data[i];
                fetchGrupos.push({
                    id: grupo.id,
                    nombre: grupo.nombre,
                    descripcion: grupo.descripcion,
                })
            }
            this.setState({
                grupos: fetchGrupos,
                status_message: 'Data about all grupos received successfully.'
            })
        }).catch((error) => {
            this.setState({ loading: false });
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    };

    addGrupo = (grupo: Grupo) => {
        const { nombre, descripcion } = grupo;
        console.log('grupo por agregar');
        console.log(grupo);
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'grupos/SaveGrupo?nombre=' + nombre,
            data: { descripcion: descripcion },
            headers: { Authorization: Auth.getToken() }
        }).then((response) => {
            this.setState({ loading: false });
            console.log('grupo saved successfully')
            this.setState({ status_message: 'Data about the user saved successfully.' });
            this.getAllGrupos();
            this.setState({ showAddItem: false });
        }).catch((error) => {
            this.setState({ loading: false });
            console.log(error);
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    }

    addNewGrupo = () => {
        const { newGrupoDescripcion } = this.state;
        let newGrupo: Grupo;
        let newNombre = this.generateNombre(newGrupoDescripcion);
        newGrupo = {
            nombre: newNombre,
            descripcion: newGrupoDescripcion
        }
        this.addGrupo(newGrupo);
    }

    generateNombre = (descripcion: string) => {
        let nombre = descripcion.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        nombre = nombre.toLowerCase().replace(/ /g, "_");
        return nombre;
    }

    deleteGrupo(grupo: Grupo) {
        const { id, nombre, descripcion } = grupo;
        this.setState({ loading: true });
        api({
            method: 'delete',
            url: '/grupos/DeleteGrupo?nombre=' + nombre,
            headers: { Authorization: Auth.getToken() }
        }).then((response) => {
            this.setState({ loading: false });
            this.setState({
                status_message: 'Data about the grupo deleted successfully.'
            });
            this.getAllGrupos();
        }).catch((error) => {
            this.setState({ loading: false });
            if (error.response) {
                if (error.response.status == 401) {
                    alert('Sesion ha expirado. Favor salir y volver a entrar');
                }
            } else {
                console.log('error, you messed up')
            }
        });
    }

    generateAgregarItem = () => {
        const { showAddItem } = this.state;
        let a;
        if (showAddItem) {
            a = (
                <Grid style={{ marginTop: 20 }} fluid>
                    <Row>
                        <Col xs={6} md={3}>
                            <form className="ui form">
                                <div className="one fields">
                                    <div className="field">
                                        <input
                                            onChange={e => this.setState({ newGrupoDescripcion: e.target.value })}
                                            type="text"
                                            placeholder="descripcion">
                                        </input>
                                    </div>
                                </div>
                            </form>
                        </Col>
                        <Col xs={6} md={3}>
                            <Button className="ui primary button" onClick={() => this.addNewGrupo()}>Guardar</Button>
                        </Col>
                    </Row>
                </Grid>
            );
        }

        return a;
    }

    render() {
        const { grupos, loading } = this.state;
        const agregarItem = this.generateAgregarItem();
        return (
            <LoadingOverlay
                active={loading}
                spinner
                text='Cargando...'
            >
                <div>
                    <Button onClick={() => this.setState({ showAddItem: !this.state.showAddItem })} className="ui primary button">
                        Agregar grupo
                    </Button>
                    {agregarItem}
                    <Grid style={{ marginTop: 20 }} fluid>
                        {grupos.map(grupo => (
                            <Row key={grupo.id}>
                                <Col xs={6} md={7}>
                                    {grupo.descripcion}
                                </Col>
                                <Col xs={6} md={3}>
                                    <Button className="ui red" onClick={() => this.deleteGrupo(grupo)}>Eliminar</Button>
                                </Col>
                            </Row>
                        ))}
                    </Grid>
                </div>
            </LoadingOverlay>


        );
    }
}

export default Grupos;